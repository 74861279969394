import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { projectFirestore } from "./firebase";
import { DATADB } from "./datapaths";
import useFirestoreDoc from "./useFirestoreDoc";
import { flatten, unflatten} from "flat"
import axios from "axios"

// ========================================================
// helper function to sort object members
// ========================================================
export function sortObject(unsortedObj){
    Object.keys(unsortedObj).sort().reduce(
        (obj, key) => { 
          obj[key] = unsortedObj[key]; 
          return obj;
        }, 
        {}
      );
    return unsortedObj; // now sorted
}

/**
 * Checks if value is empty. Deep-checks arrays and objects
 * Note: isEmpty([]) == true, isEmpty({}) == true, isEmpty([{0:false},"",0]) == true, isEmpty({0:1}) == false
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value){
    var isEmptyObject = function(a) {
        if (typeof a.length === 'undefined') { // it's an Object, not an Array
        var hasNonempty = Object.keys(a).some(function nonEmpty(element){
            return !isEmpty(a[element]);
        });
        return hasNonempty ? false : isEmptyObject(Object.keys(a));
        }

        return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
        return !isEmpty(element); // at least one element should be non-empty
        });
    };
    return (
        value == false
        || typeof value === 'undefined'
        || value == null
        || (typeof value === 'object' && isEmptyObject(value))
    );
}

// ========================================================
// generates list of the real names of group members
// and sets, userData on click
// ========================================================
export const UserSelector = () => {

    const [user, setUser] = useState('');
    const [menuItems, setMenuItem] = useState({});
    const {userData, setUserData} = useAuth();
    
    const handleChange = (e) => {
      setUser(e.target.value);
      setUserData({...userData, guid:e.target.value}); // forces new render of gamelist
    }
  
    const {doc, loading} = useFirestoreDoc( 'usergroups', firebase.auth().currentUser.uid) ;
    useEffect( async ()=>{
      if(!isEmpty(doc)){
        let newMenu = {};
        await Promise.all(doc.users.map( async (u)=>{
          const docRef = await projectFirestore.collection(DATADB+'/users').doc(u).get();
          const uDoc = docRef.data();
          newMenu[u]=uDoc.firstName+" "+uDoc.lastName;
        }));
        const orderedNewMenu = sortObject(newMenu);
        setMenuItem({...orderedNewMenu});
        setUser(firebase.auth().currentUser.uid); // sets the initial value after page refersh
      }
  
    },[doc]);
    
    // https://stackoverflow.com/questions/66943324/mui-select-component-with-custom-children-item
    
    if(!userData || !userData.guid) {return null};
  
    return (
        <div>
          { loading && <p>Loading...</p>}
          { !loading &&
            <Box sx={{ minHeight: 100, minWidth: 520 }}>
              <FormControl fullWidth>
                <InputLabel>Valitse ryhmän jäsen: </InputLabel>
                <Select
                  labelId="select-user-label"
                  id="select-user-label"
                  value={user}
                  label="Valitse ryhmän jäsen:  "
                  onChange={handleChange}
                  defaultValue=""
                  style={{ backgroundColor: "white" }}
                >
                  { Object.keys(menuItems).map( ( key )=><MenuItem dummy={doc} key={key} value={key}>{menuItems[key]}</MenuItem> ) } 
                  </Select>
              </FormControl>
            </Box>
        }
        </div>                 
      )
  }

// https://github.com/hughsk/flat
export const flattenObject = (target, opts={}) => {
  return flatten(target, opts);
}

export const unflattenObject = (target, opts={}) => {
  const myOpts={...opts, object:true};
  return unflatten(target, myOpts);
}

// ===================================================
// will allow objects in state
// trick: flattens objects and applies
//        changes to members that differ
// ===================================================
export const useObject = (initialValue={})=>{

// if you want to use diff(obj1, obj2)
//function removeEmpty(obj) {
//  return Object.entries(obj)
//    .filter(([_, v]) => v != null)
//    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
//}    

  const [ flatObject, setFlatObject ] = useState(flattenObject(initialValue));
  let obj = unflattenObject(flatObject);

  const setObject = (obj) => {
    const newFlatObject = flattenObject(obj);
    setFlatObject({...flatObject, ...newFlatObject});
  }

  return [obj, setObject ];

}

// ===================================================
// will compute the difference of two deep objects
// https://codepen.io/cferdinandi/pen/oVvKoe
// ===================================================
export const diff = function (obj1, obj2) {

  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      return obj1;
  }

  //
  // Variables
  //

  var diffs = {};
  var key;


  //
  // Methods
  //

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  var arraysMatch = function (arr1, arr2) {

      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false;

      // Check if all items exist and are in the same order
      for (var i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) return false;
      }

      // Otherwise, return true
      return true;

  };

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  var compare = function (item1, item2, key) {

      // Get the object type
      var type1 = Object.prototype.toString.call(item1);
      var type2 = Object.prototype.toString.call(item2);

      // If type2 is undefined it has been removed
      if (type2 === '[object Undefined]') {
          diffs[key] = null;
          return;
      }

      // If items are different types
      if (type1 !== type2) {
          diffs[key] = item2;
          return;
      }

      // If an object, compare recursively
      if (type1 === '[object Object]') {
          var objDiff = diff(item1, item2);
          if (Object.keys(objDiff).length > 0) {
              diffs[key] = objDiff;
          }
          return;
      }

      // If an array, compare
      if (type1 === '[object Array]') {
          if (!arraysMatch(item1, item2)) {
              diffs[key] = item2;
          }
          return;
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (type1 === '[object Function]') {
          if (item1.toString() !== item2.toString()) {
              diffs[key] = item2;
          }
      } else {
          if (item1 !== item2 ) {
              diffs[key] = item2;
          }
      }

  };


  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
      if (obj1.hasOwnProperty(key)) {
          compare(obj1[key], obj2[key], key);
      }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
      if (obj2.hasOwnProperty(key)) {
          if (!obj1[key] && obj1[key] !== obj2[key] ) {
              diffs[key] = obj2[key];
          }
      }
  }

  // Return the object of differences
  return diffs;

};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

// =======================================================
// https://developers.google.com/recaptcha/docs/v3
// =======================================================
// 
// Use like this:
//
// onClick={async (e)=>{
//     e.preventDefault();
//     evaluateRecaptcha(
//     handleSubmit, 
//     (score) => setUserProfileError('Virheilmoitus: lomakkeen tiedot eivät kelpaa (reCaptcha score: '+score+').'));
//   }}

export async function evaluateRecaptcha( cbSuccess = ()=>{}, cbFail = ()=>{} ) {
    grecaptcha.ready( async () => {
      const recaptchaToken = await grecaptcha.execute(process.env.REACT_APP_APPCHECK_KEY, {action: 'signup'});
      const baseURL = process.env.REACT_APP_RECAPTACHA_URL;
      const result = await axios.get(baseURL,{ params: { 'response': recaptchaToken } });
      const {success, score} = result.data
      console.log("reCAPTCHA evaluated:", {success, score})

      if(success){
        cbSuccess();
      }else{
        cbFail(score)
      }
    })
} 

// Cross-Origin Resource Sharing (CORS) get arbitrary URL
// if you need to use from different url, you must whitelist hosts in proxyWithCorsAnywhere!
//
// NOTE: do not use http:// or https://  in targetUrl
// 
export async function corsGet(targetUrl='/ahaavision.com/index.php'){
    // https://stackoverflow.com/questions/29670703/how-to-use-cors-anywhere-to-reverse-proxy-and-add-cors-headers
    // throuhh proxy like so
    //     https://cors-anywhere.herokuapp.com/http://example.com  //+'/'+ targetUrl;
    // 
    const corsBaseUrl = process.env.REACT_APP_PROXY_WITH_CORS_ANYWHERE
    const fullUrl     = corsBaseUrl + targetUrl 

    console.log(" fullUrl: ", fullUrl);

    //delete axios.defaults.headers.common.Authorization
    const config = {
      method: 'GET',
      url: fullUrl,
    }

    //  we need this to initiate cCORS "Access-Control-Request-Headers": "Special-Request-Header", unsafe by Chrome!
    const axiosresult = await axios( config );
    return axiosresult;
    
}

export function toggleFullScreen() {
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  }
  else {
    cancelFullScreen.call(doc);
  }
}

export function requestFullScreen() {
  var doc = window.document;
  var docEl = doc.documentElement;
  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  requestFullScreen.call(docEl);
}

export function cancelFullScreen() {
  var doc = window.document;
  var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  if(document.fullscreenElement){
    cancelFullScreen.call(doc);
  }
}

// store log message through cloud function
export function SendServerMessage({message= "-- default server message --", category= "general-message-category"}) {
  const sendMessageToServer = async (message) => {
      try {
          const response = await axios.post("https://us-central1-itupeli.cloudfunctions.net/storeMessage", {
              message: message,
              category: category
          });

          const data = response.data;

          if (data.success) {
              console.log("Message stored with ID:", data.id);
          } else {
              console.error("Error:", data.error);
          }
      } catch (error) {
          console.error("There was an error:", error);
      }
  }
  sendMessageToServer(message);
}
