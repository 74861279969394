import React, { useState, useEffect } from 'react'
import firebase from "../../config/firebase";
import { useRecoilState } from 'recoil';
import { saveUserProfileState, authInfoState, uiSetup } from '../../config/userProfile';
import { Button, Container, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../config/AuthProvider';

const EmailVerification = () =>  {
  
  const [time, setTime] = useState(0);
  const [message, setMessage] = useState("");
  const [authInfo, setAuthInfo] = useRecoilState(authInfoState);
  const navigate = useNavigate();
  const {forceAuthReload} = useAuth();

  useEffect( () => {
    let step = 0;
    const interval = setInterval( async () => {
      setTime(step++);
      firebase.auth().currentUser.reload();
      if(firebase.auth().currentUser.emailVerified){
        forceAuthReload(firebase.auth().currentUser.uid);
        navigate("/sessionlist");
      }; 
    }, 1000);
    return ()=>{clearInterval(interval)}
  },[]);

  const sendAgain = (e)=>{ 
    e.preventDefault();
    firebase.auth().currentUser.sendEmailVerification();-
    setMessage("Viesti on nyt lähetetty uudelleen ylhäällä näkyvään osoitteeseen.");
    setTimeout(()=>setMessage(""),5000);
  }

  return uiSetup("EmailVerification") && (
    <form >
      <Container style={{height:"80px"}}><br /></Container>
      <Container fixed={true} maxWidth="xs">
        <Paper>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" style={{width:"80%"}}>
                <b>Odotan sähköpostiosoitteesi oikeellisuuden vahvistamista <br /> ( {time} s ): <h3>{authInfo.email}</h3></b>
                <p>Vahvista osoitteesi klikkaamalla saamassasi vahvistusviestissä olevaa linkkiä.</p>
                <p>Viesti menee suurella todennäköisyydellä sähköpostiohjelmasi roskapostikansioon, muistathan tarkistaa viestin perilletulon myös sieltä! 
                    Jos annoit vahingossa väärän osoitteen, luo uusi tili oikealla osoitteella.</p>
                <p>Viesti saapuu perille tyypillisesti alle minuutissa. Voit tarvittaessa lähettää viestin uudelleen klikkaamalla alla olevaa painiketta.</p>
              </Grid>
              <Grid item align="center" style={{width:"80%"}}>
                <Button 
                  type="submit"
                  size="medium"
                  variant="contained" 
                  color="primary" 
                  onClick={sendAgain} >
                  Lähetä vahvistusviesti uudelleen
                </Button>
              </Grid>
              <Grid item align="center" style={{width:"80%"}}>
                <p><b>{message}</b></p>
                <p>Ellei sähköpostiosoitteen vahvistaminen kaikesta huolimatta onnistu, soita <br /><br />Markku Lahtinen <br />p. 040 - 4140 618.<br /></p>
                </Grid>
            </Grid>
          </Paper>
      </Container>
    </form>      
  );

}

export default EmailVerification
