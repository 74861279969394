import { Button, IconButton, Stack, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from 'mui-rte';
import { useCallback, useEffect, useRef, useState } from "react";
import EmailEditor from 'react-email-editor'
import SaveIcon from '@mui/icons-material/Save';
import TemplateDialog from "./TemplateDialog";
import { DOCPATH } from '../../config/datapaths';
import firebase from '../../config/firebase'
import CodeIcon from '@mui/icons-material/Code';

// https://github.com/niuware/mui-rte
// https://github.com/unlayer/react-email-editor
// https://docs.unlayer.com/docs
// https://issueexplorer.com/issue/unlayer/react-email-editor/100

// HTML EDITOR
// https://onlinehtmleditor.dev/

export const Editor = ({design, setDesign, handleChange, handleSaveEmail, handleSaveEmailTemplate}) => {

   const emailEditorRef = useRef(null);
   const [name, setName] = useState('');
   let toggle=false;
   
   const onLoadEditor = useCallback(() => {
      const timer = setInterval(function() {
         if ( emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
             if(design){
               emailEditorRef.current.editor.loadDesign(design);
            }
            emailEditorRef.current.addEventListener('design:updated', () => emailEditorRef.current.exportHtml(handleChange) );
            clearInterval(timer);
         }
      }, 500);
    }, [ emailEditorRef]);
  
   const onReady = () => {
      // editor is ready
      //console.log('EmailEditor onReady');
   };
   
   const handleNameChange = (e) => {
      setName(e.target.value)
   }
   const handleTemplateChange = (value) => {

      setName(value)

      const db=firebase.firestore();
      db.collection( DOCPATH('emailtemplateslibrary') ).doc(value).get()
      .then( (snapshot) => {
              if(snapshot.exists){
                  const data=snapshot.data();
                  setDesign(data.design);
                  toggle=!toggle;
                  emailEditorRef.current.loadDesign(data.design);
              } 
          });
   }
   
   return  (
      <div>
         <Stack direction="row">
            <Button 
               sx={{margin: "7px", alignSelf: 'flex-start'}}
               size="small" 
               variant="contained" 
               startIcon={<SaveIcon />}
               onClick={(e)=> emailEditorRef.current.exportHtml((data)=>handleSaveEmail(data))}
            >
               Tallenna viestipohja
            </Button> 
            <Button 
               sx={{margin: "7px", alignSelf: 'flex-start'}}
               size="small" 
               variant="contained" 
               startIcon={<SaveIcon />}
               onClick={(e)=> emailEditorRef.current.exportHtml((data)=>{
                                 if(name.length<5) return;
                                 handleSaveEmailTemplate(data,name);
                                 setName("")
                              })}
            >
               Tallenna viestipohja nimellä
            </Button> 
            <TextField type="text" 
               sx={{minWidth: "300px", margin: "7px", alignSelf: 'flex-end'}}
               id='emailtemplate' 
               label="Viestipohjan nimi"  
               variant="outlined" 
               onChange={handleNameChange} 
               value={name}
               error={name.length < 5 && name.length > 1}
               helperText={name.length < 5 && name.length > 1 ? 'Nimi on liian lyhyt' : ' '}                              
            />
            <TemplateDialog collection={DOCPATH('emailtemplateslibrary')} handleTemplateChange={handleTemplateChange}/>
            <IconButton 
               color="primary" 
               component="span"                
               sx={{margin: "7px", alignSelf: 'flex-start'}}
               size="large"
               onClick={()=>{emailEditorRef.current.exportHtml((data)=>{
                     console.log('');
                     console.log('=======================================================================');
                     console.log('');
                     console.log(JSON.stringify(data.design,null,2) );
                     console.log('');
                     console.log('=======================================================================');
                     console.log('');
                     console.log(data.html );
                     console.log('');
                     console.log('=======================================================================');
                     console.log('');

                  })         
         
               }}
             >
              <CodeIcon/>
            </IconButton>

         </Stack>         
         <EmailEditor 
            toggle={toggle}
            design={design}
            ref={emailEditorRef} 
            onLoad={onLoadEditor} 
            onReady={onReady} 
         />
      </div>
   )

}
