import React from 'react'
import { sendEmail } from './helpersEmail'
import defaultEmailTemplate from './sendBasicEmailTemplate.js'

export default function sendBasicEmail({
        to="markku.lahtinen@ahaavision.com", 
        greeting= "Hei,",
        subject='FriCo lähetti sinulle viestin', 
        html='<p>FriCo lähetti sinulle viestin. Tämä on tyhjä vakioviesti.</p>', 
        link= 'http://www.frico.app/',
        linkText= 'www.frico.app',
        htmlTemplate=defaultEmailTemplate,
        attachments=[],
    }) {
        // 
        // <a href="http://www.example.com">In tempus felis blandit</a>

        let anchor='';
        if(link){
            anchor = '<a href="'+link+'">'+linkText+'</a>';
        }
        let htmlContent='';
        htmlContent = htmlTemplate.replace("[[greeting]]",greeting);
        htmlContent = htmlContent.replace("[[body]]",html);
        htmlContent = htmlContent.replace("[[link]]",anchor);
        const text=html;
        sendEmail({to, subject, text, html:htmlContent, attachments })
}
