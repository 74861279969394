import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from "../../config/firebase";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import moment from 'moment';

// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from "react-hook-form";
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import BasicInfo from './Tabs/BasicInfo';
import Billing from './Tabs/Billing';
import Premium from './Tabs/Premium';
import Settings from './Tabs/Settings';

import { Alert, Button, FormControl, Grid, IconButton } from '@mui/material';
import { throttleFn } from '../watch/Watch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const  schema = yup.object().shape({
    firstName:           yup.string().min(2).max(100).required(),
    lastName:            yup.string().min(2).max(100).required(),
    email:               yup.string().email().required(),
    mobile:              yup.string(),
    // mobile:              yup.string().nullable().matches(/^([0-9-\s\+]){6,20}$/, {
    //                       message: "Puhelinnumero ei kelpaa",
    //                       excludeEmptyString: false,
  });

  const userDefaults={
        premimium: false,
        mobile: "",
        mobileVerified: false,
        userImage:'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FArtboard%201.png?alt=media&token=9921c248-11bf-4188-aee5-39d68b1859ba',
        userTitle:'',
        userDescription:'',
        userWebsite:'www.frico.app',
        premiumEnds:moment().add(1, 'months').valueOf(),
        taxId:'',
        billingName:'',
        billingEmail:'',
        billingAddress:'',
        billingVATNumber:'',
        billingMethod:'email',
        billingNetPayInfo:'',
        billingInfo:'',
  }


export default function EditUserTabs() {

  const SAVE_THROTTLE = 2000; // limit saving 
  
  const [tabValue, setTabValue] = useState(0);
  const [fSaving, setFSaving] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  
  const navigate = useNavigate()
  const db=firebase.firestore();
  useEffect( ()=>{
    const docRef = doc(db, "/itu/testing-ahaa/users/", firebase.auth().currentUser.uid);
    getDoc(docRef).then((doc)=> {
        const data = doc.data();
        setUserDetails({
            ...userDefaults, 
            billingName: data.firstName+" "+data.lastName,
            billingEmail: data.email||'',
            billingMobile: data.mobile||'',
            ...data
        });
        reset({...userDefaults, ...data});
    })
  },[]);

  // force save under unmount
  
  var {
      reset,
      register,
      control,
      handleSubmit,
      formState,
      trigger,
      setValue,
    } = useForm({ 
                    mode: "onChange",
                    resolver: yupResolver(schema),
                    shouldFocusError: true,
                    criteriaMode: "all",
                    reValidateMode: "onChange"
                  });

  const {errors, isDirty, isSubmitting, isSubmitSuccessful, touchedFields, submitCount, isValid} = formState;
  
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  

  const handleSave = useCallback( throttleFn( (data) => {

        setFSaving(true);
        setTimeout(()=>{setFSaving(false)},2000)
        delete data.email;
        delete data.uid;
        delete data.group;
        delete data.emailVerified;
        delete data.gid;
        delete data.group;
        delete data.guid;
        delete data.role;
        data.initials=data.firstName.charAt(0)+data.lastName.charAt(0)

        setUserDetails(data);
        const docRef = doc(db, "/itu/testing-ahaa/users/", firebase.auth().currentUser.uid);
        updateDoc(docRef, data);
  },SAVE_THROTTLE),[]); // needs to be recalculated once we have frico.sessionSaveData!

  const handleError = (err) => {
    console.error("FriCo: Error in Tab form",err);
  }

  if(!firebase.auth().currentUser) return null;  
  return (
    <>
        <Box sx={{ width: '100%',paddingLeft:"50px",paddingRight:"50px" }}>
            <Box display="flex" justifyContent="center"  sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs 
                    value={tabValue} 
                    onChange={handleChange} 
                    aria-label="basic tabs example"  
                    variant="scrollable" 
                    scrollButtons="auto" 
                    sx={{ alignItems: "center"}}
                    >
                    <Tab label="Käyttäjä" {...a11yProps(0)} />
                    <Tab label="Peliaika" {...a11yProps(1)} />
                    <Tab label="Laskutustiedot" {...a11yProps(2)} />
                    <Tab label="Asetukset" {...a11yProps(3)} />
                </Tabs>
            </Box>

            <form onChange={ handleSubmit(handleSave, handleError)}>
                <TabPanel value={tabValue} index={0} sx={{width:"100%"}}>
                    <BasicInfo userDetails={userDetails} control={control} errors={errors} formState={formState} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Premium userDetails={userDetails} control={control} formState={formState} handleSubmit={handleSubmit(handleSave, handleError) } setValue={setValue}  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Billing userDetails={userDetails} control={control} formState={formState} handleSubmit={handleSubmit(handleSave, handleError)}/>                
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                <Settings userDetails={userDetails} control={control} formState={formState} handleSubmit={handleSubmit(handleSave, handleError)}/>
                </TabPanel>
                <Grid container justifyContent="left" sx={{paddingLeft:"25px"}}>
                    <Box>
                        <Button 
                            sx={{marginRight:"15px"}}
                            variant="contained"
                            size="small"
                            onClick={()=>{
                                    navigate('/sessionlist')
                                    handleSubmit(handleSave, handleError)
                                } } 
                            >
                            <ArrowBackIcon sx={{marginRight:"10px"}}/> Palaa
                        </Button> 

                    </Box>
                    <Box>
                        { !isValid && <Alert severity="error">Lomakkeen välilehdellä on virheitä, ei voida tallentaa</Alert>}
                        { isSubmitSuccessful && fSaving && <Alert severity="success">Lomake tallentui onnistuneesti</Alert>}
                        { isDirty && !isSubmitSuccessful && <Alert severity="error">Lomakkeen tallennus ei onnistu</Alert>}
                    </Box>                        
                </Grid>
            </form>
        </Box>
    </>
  );
}


// =====================================================================================
//  Tab helpers
// =====================================================================================

//import { StateMachineProvider, createStore, useStateMachine } from "little-state-machine";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  


