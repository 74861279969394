import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'; 
import BookMapper from "./BookMapper"
import BookNumbers from './BookNumbers';
import { Typography } from '@mui/material';
import { useWindowDimensions } from '../../config/helpers';
import { useSpring, animated } from '@react-spring/web'
import Fade from '@mui/material/Fade';

import library from '../../assets/books/library-02x1920.jpg'

import { useNavigate } from 'react-router-dom';
import { FricoContext } from '../../config/FricoProvider';

// https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
export default function Library() {

    const frico = useContext(FricoContext);
    const navigate = useNavigate();
    useEffect(()=>{
        !frico.session && navigate('/');
    },[]);

    const [walkin, setWalkin] =useState(false)
    const [hideVideo, setHideVideo] =useState(true)
    const [currentBook, setCurrentBook]=useState(0);
    const [animationReady, setAnimationReady ]=useState(false);
    const {width, height} = useWindowDimensions();    

    const springPropsLibrary = useSpring({
        config:{mass:1, tension:30, friction:20},
        opacity: walkin?0.6:1 ,
        scale: walkin?3:1 ,
        marginLeft: walkin?"60%":"0%" ,
        top: walkin?"-20%":"0%" ,
        onRest: ()=>{ setAnimationReady(true) }
      })

    //compute the vertical position from where the books seem to appear
    const yPx = width*9/16*0.55+'px';

    const springPropsBooks = useSpring({
        config:{mass:2, tension:10, friction:8,},
        opacity: walkin?1:0 ,
        scale: walkin?1.1:0.1 ,
        top: walkin?"0px": yPx ,
        left: walkin?"0%":"-20%" ,
    })

    const useStyles = makeStyles( (theme) => {
        return {
            gameRoot: {
                backgroundColor: "#f9f9f9",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundSize: "cover",
                overflow: "hidden",
                width:"100vw",
                height:"100vh", 
                overflow:"hidden", 
                position:"absolute"
            },
        }
    })
    const classes = useStyles()

    if(!frico.session) {
        return null;
    }

    if(frico.session.gameState["cardsLibrary"] === undefined) {
        return null;
    }

    frico.session.gameState.started = true;
    frico.sessionSave();

    // 1. show library view
    // 2. show books, that will open on click
    return (
        frico.session && (
        <Box className={classes.gameRoot} >

            <div style={{aspectRatio:1.777, width:"100vw", overflow:"hidden",}}>
                {!animationReady 
                    && <animated.img  
                        src={library}  
                        style={{ 
                            width:"100vw", 
                            cursor:"pointer",
                            zIndex:12,
                            ...springPropsLibrary, 
                        }}  
                        onClick={()=>{
                            setHideVideo(false);
                            setWalkin(true)
                        }}
                    />
                }
                {<animated.div style={{  display:hideVideo?"none":"block", width:"100vw",  position:"absolute",  opacity:0,  ...springPropsBooks, }} >
                    {   
                        // earlier books was an array, now it is an OBJECT!
                        
                        Object.keys(frico.session.gameState.cardsLibrary.books).map((key, index)=> {
                            const book = frico.session.gameState.cardsLibrary.books[key];
                            return BookPlayer({ book, currentBook, setCurrentBook, animationReady })
                        })

                        // frico.session.gameState.cardsLibrary.books.map(book=> {
                        //     return BookPlayer({ book, currentBook, setCurrentBook, animationReady })
                        // }) 

                    }
                </animated.div>}
            </div>
        </Box>
        )
    )
}


const BookPlayer = ({book, currentBook,setCurrentBook, hideMe, animationReady}) => {

    // React video player
    // https://www.npmjs.com/package/react-player
    const [playing, setPlaying] = useState(false)
    const [waitForQuestion, setWaitForQuestion] = useState(true)
    const [showQuestion, setShowQuestion] = useState(false)
    const [visibility, setVisibility] = useState("hidden")
    const [showNumbers, setShowNumbers] = useState(false);
    
    const frico = useContext(FricoContext);     

    const [openedBooks, setOpenedBooks] = useState({ "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, })
    const {width} = useWindowDimensions();    
    const visible = book.id==currentBook;

    const handleProgress = ({playedSeconds, played, loadedSeconds, loaded}) => {
        if(played>0.5 && waitForQuestion && book.id){
            setPlaying(false)
            setShowQuestion(true)
            setWaitForQuestion(false)            
        }
    }

    const handleClick = (area) =>{
        // area name: book number as shown by labels    
        setCurrentBook(area.name);
        setOpenedBooks({...openedBooks,[frico.session.gameState.cardsLibrary.books[area.name].place]:true})
        frico.session.gameState.cardsLibrary.openedBooks = {...openedBooks,[frico.session.gameState.cardsLibrary.books[area.name].place]:true};
        frico.sessionSave();
        setShowNumbers(false)
    }

    useEffect(()=>{
        if(visible){
            setVisibility("visible")
            setPlaying(true);
            setWaitForQuestion(true)
        } else {
            setVisibility("hidden")            
            setPlaying(false)
            setWaitForQuestion(false)
        }
    },[visible,currentBook]);

    useEffect(()=>{
        if(frico.session && frico.session.gameState["cardsLibrary"] !== undefined) {
            setOpenedBooks({...frico.session.gameState.cardsLibrary.openedBooks});
          } 
    },[frico.session]);


    if(hideMe) return null;
    return (
        <div key={book.id} _id="reactplayer" style={{visibility}}>
            { animationReady && currentBook==0 && <BookMapper width={width} handleClick={handleClick} setShowNumbers={setShowNumbers} hideMe={hideMe}/> }            
            { currentBook==0 && <BookNumbers openedBooks={openedBooks} showNumbers={showNumbers}/> }            
            { !playing && <PrintQuestion question={book.question} showQuestion={showQuestion} task={book.task}/> }
            <div  style={{overflow:"hidden", position:"absolute",}}>
                <ReactPlayer 
                    onClick={()=>setPlaying(true)}
                    config={{
                        file: { 
                        attributes: { 
                            preload: 'auto' 
                        } 
                        } 
                    }}
                    muted={true}
                    volume={0}
                    url={book.book} 
                    loop={!book.id}
                    controls={false} 
                    width="100vw" 
                    height="100%"
                    progressInterval = {200}
                    onProgress ={handleProgress}
                    playing={playing}
                    onPlay={() => { 
                                        setPlaying(true)
                                        setShowQuestion(false)
                                    }}
                    onPause={() => setPlaying(false)}
                    onEnded={()=> {
                        setCurrentBook(0);
                        setPlaying(true);
                        setWaitForQuestion(true)
                    }}
                />   
            </div>
        </div>
    )
}


function PrintQuestion({question, showQuestion, task}){

    const { width } = useWindowDimensions();    
    const cssHeight = width*9/16*0.15+"px";

    // https://stackoverflow.com/questions/63585992/how-to-render-raw-html-content-to-react-pass-via-props
    return (<Fade timeout={1000} in={showQuestion} appear={true}>
                <Typography 
                    variant="h1" 
                    component="div" 
                    sx={{ 
                        opacity: 0.0, 
                        flexGrow: 1,
                        marginLeft:"15vw",
                        width:"60vw", 
                        height: "60vh",
                        textAlign: "center",
                        marginTop: cssHeight,
                        fontFamily: "Roboto, Helvetica, sans-serif",
                        fontWeight: "bold",
                        position: "absolute", 
                        fontSize: (4- ((question.length<50)?0:question.length-50)*0.007 ) +"vw", 
                        zIndex: 15,
                        pointerEvents: "none", 
                    }}
                    style={{
                        backgroundImage: "url("+task+")", 
                        backgroundSize:"contain", 
                        backgroundPosition: "center top",
                        backgroundRepeat: "no-repeat",
                    }}
                    align="center">
                    <div dangerouslySetInnerHTML={ { __html: question}} />
                </Typography>
            </Fade>            
    )
}

