import { useState, useEffect } from 'react';
import { projectStorage, projectFirestore } from './firebase';
import { DATAROOT } from './datapaths';
import { v4 as uuidv4 } from 'uuid';
import { getUserFileDetails } from './userProfile';
import newDoc from './newDoc';

const useStorage = ({file, docType}) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    // references
    const fileName = file.name;
    const storageName = DATAROOT()+'/'+uuidv4()+'__'+fileName;
    const storageRef = projectStorage.ref(storageName);
    const collectionRef = projectFirestore.collection(DATAROOT()+'/images');
    
    storageRef.put(file).on('state_changed', (snap) => {
      // this can be executed 4..7 times, say
      let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
      setProgress(percentage);
    }, (err) => {
      setError(err);
    }, 
      // we get here when ready
      async () => {
        const url = await storageRef.getDownloadURL();
        newDoc("images",{data:{... await getUserFileDetails(), fileName, url, storageName}, docType })
        setUrl(url);
    });
  }, [file]);

  return { progress, url, error };
}

export default useStorage;