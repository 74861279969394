import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageCard from '../imageCard/imageCard';
import { unflattenObject } from '../../config/helpers';

//setState(Object.assign(state,  {form: unflattenObject(objUpdate)} ));

export default function SettingsArray( {state, setState, cardArray} ) {
    const [open, setOpen] = React.useState(false);
  
    const handleChange = (objUpdate) => {
      setState(Object.assign(state,  {form: unflattenObject(objUpdate)} ));
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <React.Fragment>
        <Tooltip title="näkymän lisäasetukset" placement="top" arrow >
          <IconButton variant="outlined" onClick={handleClickOpen} >
            <SettingsIcon  sx={{ transform: "scale(1.3)" }} />
          </IconButton>
        </Tooltip>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Näkymän lisäasetukset:</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Valitse korttien lukumäärä</FormLabel>
              <RadioGroup
                row 
                aria-label="image count" 
                name={"imageCount"} 
                value={state.form[cardArray]["imageCount"]||8} 
                onChange={(e)=>handleChange({[cardArray+"."+e.target.name]:e.target.value})}
              >
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="7" control={<Radio />} label="7" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
              <FormLabel component="legend" sx={{paddingTop: "20px", paddingBottom: "15px"}} >Valitse näkymän taustakuva:</FormLabel>

              <ImageCard 
                  img={state.form[cardArray]["bgImage"]}
                  name={"bgImage"}
                  notext={true}
                  docType={"view"}
                  handleChange={(url)=>handleChange({[cardArray+".bgImage"]:url})}
              />

              <FormLabel component="legend" sx={{paddingTop: "20px", paddingBottom: "15px"}} >Valitse korttien tausta:</FormLabel>

              <ImageCard 
                  img={state.form[cardArray]["cardBackground"]}
                  name={"cardBackground"}
                  notext={true}
                  docType={'cardBackground'}                  
                  handleChange={(url)=>handleChange({[cardArray+".cardBackground"]:url})}
              />

            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button size="medium" variant="contained" color="primary" onClick={handleClose} sx={{marginRight: "50px", maginBottom: "50px"}} >Valmis</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }