import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

export default function GoogleReCaptchaLegal() {
    return (
        <Box>
                <Typography variant='body2' gutterBottom  style={{color:"#aaaaaa",fontSize:"80%"}} >
                    This site is protected by reCAPTCHA and the Google
                    <Link href="https://policies.google.com/privacy" color="secondary.light"> Privacy Policy </Link> and 
                    <Link href="https://policies.google.com/terms" color="secondary.light"> Terms of Service </Link> apply.
                </Typography>                    
        </Box>
    )
}


