import React, { useEffect } from 'react';
import useStorage from '../../../../config/useStorage';
import { motion } from 'framer-motion';

const ProgressBar = ({ file, setFile, docType }) => {
  const { progress, url } = useStorage({file, docType});
  
  useEffect(() => {
    if (url) {
      setFile(null);
    }
  }, [url, setFile]);

  return (
      <motion.div 
        style={{
          width: "100%", 
          height: "10px", 
          paddingTop: "10px", 
          backgroundColor: "green", 
          display: "inline-block"
        }} 
        className="progress-bar"
        initial={{ width: "0%" }}
        animate={{ width: progress + '%' }}
      ></motion.div>
  );
} 

export default ProgressBar;