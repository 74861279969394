import { React, useState } from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
//https://react-icons.github.io/react-icons/icons?name=fi
import ImagePicker from './imagePicker/imagePicker'
import ImageText from './imageText'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Backdrop, CardActionArea, CardActions, CardMedia,  CircularProgress,  Grid, Tooltip } from '@mui/material';
import { Edit, PhotoCamera, TextFormat } from '@mui/icons-material';
import ImageEditor from './imagePicker/comps/ImageEditor';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import axios from 'axios';

export default function ImageCard( props ) {

  const { handleChange, img, text, docType="default", notext=false, xyRatio='56.25%'} = props;

  // state of image dialogue${img}
  // <PrintState authInfo={idState} />
  const initialIdState ={ 
    open: false,
    textOpen: false, 
    selectedImg: null, 
  };
  const [idState, setIdState] = useState(initialIdState)
  const [show, toggle] = useState(false);
  
  const [missing, setMissing] = useState(true);
  const [iReady, setIReady] = useState(false);
  const missingImage = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fmissing-image.jpg?alt=media&token=2bcdc05b-c4d6-4107-ac7d-bc6ca898fccd";
  const checkImage = (img) => {
    axios
        .get(img)
        .then(() => {
          setMissing(false);
          setTimeout(()=>setIReady(true),2000);
          return img;
        })
        .catch(() => {
          setMissing(true);
          setIReady(true);
          // handleChange(missingImage); <<<<<<<<<<<<<<< IMAGE-ERROR
          return missingImage;
    });
  
  }

  checkImage(img);      

  // xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px

  // checkout 
  // https://blog.logrocket.com/building-inline-editable-ui-in-react/
  return ( 
    <Grid item  md={6} sx={{minWidth:"400px", minHeight:"225px", position:"relative" }}>
      {
        !iReady ? <MyBackdrop /> :
        (
        <>
          <Card >
              <ImagePicker 
                idState={idState} 
                setIdState={setIdState} 
                handleChange={(eImg)=>handleChange(eImg,text)} 
                docType={docType} 
                img={checkImage(img)} 
              />
              {!notext && 
                <ImageText   
                  idState={idState} 
                  setIdState={setIdState} 
                  text={text}                
                  handleChange={(eText)=>handleChange(img,eText)}             
                />}
            <CardActionArea onClick={()=>{
                    setIdState({...idState, open:true})
                }
              }>
              <div style={{ position: "relative" }}>
                {
                  xyRatio=='100%' ? 
                  <CardMedia sx={{ height: "100px", width: "100px", }} image={missing?missingImage:img} />
                    :
                    <CardMedia sx={{ height: "100px", paddingTop: xyRatio, }} image={missing?missingImage:img} />
                }
                
                <Typography sx={{position: "absolute", color: "black",top: 30,left: "50%",transform: "translateX(-50%)"}}>
                  {text}
                </Typography>
              </div>
            </CardActionArea>
            <CardActions disableSpacing>
                { !notext &&
                  <Tooltip title="muokkaa tekstiä" placement="top" arrow>
                    <IconButton aria-label="edit" onClick={()=>setIdState({...idState, textOpen:true})}>
                        <TextFormat  />
                    </IconButton>
                </Tooltip>
                }
                <Tooltip title="Valitse uusi kuva" placement="top" arrow >
                    <Button size="small" variant="contained" endIcon={<PhotoLibraryIcon />} onClick={()=>{setIdState({...idState, open:true})}} sx={{marginLeft: "auto"}}>
                            Vaihda kuva
                    </Button>
                </Tooltip>
                <Tooltip title="muokkaa kuvaa" placement="top" arrow >
                    <Button size="small" variant="contained" endIcon={<Edit />} onClick={()=>toggle(true)} sx={{marginLeft: "20px"}}>
                            Muokkaa kuvaa
                    </Button>
                </Tooltip>
              </CardActions>
            </Card>
            <ImageEditor show={show} img={img} toggle={toggle} docType={docType} handleChange={handleChange}/>
        </>
      )}
   </Grid>
  
  );
}

export function MyBackdrop() {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute", }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
