import { Container, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FricoTimePicker from './FricoTimePicker';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { useUnmount } from 'react-use';
import { unflattenObject, useObject } from '../../config/helpers'
import firebase from '../../config/firebase'
import { DATAROOT, DOCPATH } from '../../config/datapaths';
import { Editor } from '../editor/Editor';
import defaultSession from './defaultSession';
import { getDefaultTemplate } from './emailTemplates/getDefaultTemplate';

export default function EditSession(props) {

    const collection = DATAROOT()+'/sessions';
    const {sessionid} = useParams();
    const [formState, setFormState ] = useObject({
        form: defaultSession,
        ready: false,
    });
    const [design, setDesign ] = useState(getDefaultTemplate().template)
    const [designReady, setDesignReady ] = useState(false)
    
    // ================================================
    // reads stuff in from DB
    // ================================================
    useEffect(() => {

        const db=firebase.firestore();

        db.collection( collection ).doc(sessionid).get()
        .then( (snapshot) => {
            let newForm = {...formState.form};
            setFormState({
                form : Object.assign(newForm, snapshot.data()),
                ready: true,
            });
        });

        db.collection( DOCPATH('emailtemplates') ).doc(sessionid).get()
        .then( (snapshot) => {
                if(snapshot.exists){
                    const data=snapshot.data();
                    setDesign(data.design);
                } 
                setDesignReady(true)
            });


    }, []);

    // ================================================
    // save state on unmount
    // https://github.com/streamich/react-use
    // ================================================
    useUnmount(() => {
           const { form } = formState;
           const db=firebase.firestore();
           db.collection(DATAROOT()+'/sessions').doc(sessionid).update(form)
           .catch((error) => {
               console.error("Error saving form document: ", error);
           });
       });
    
    const handleSaveEmail = (data) => {
        const db=firebase.firestore();
        db.collection(DOCPATH('emailtemplates')).doc(sessionid).set({html:data.html,design:data.design })
        .catch((error) => {
            console.error("Error saving form document: ", error);
        });
    };

    const handleSaveEmailTemplate = (data,name) => {
        const db=firebase.firestore();
        db.collection(DOCPATH('emailtemplateslibrary')).doc(name).set({html:data.html,design:data.design, name })
        .catch((error) => {
            console.error("Error saving form document: ", error);
        });
    };

    // writes data to database
    const handleChange = (data) => {
        const db=firebase.firestore();
        db.collection(DOCPATH('emailtemplates')).doc(sessionid).set({html:data.html,design:data.design })
        .catch((error) => {
            console.error("Error saving form document: ", error);
        });
    };

    const [startsAt, setStartsAt] = React.useState( moment().add(1, 'days').hours(9).startOf('hour'));
    const [endsAt, setEndsAt] = React.useState(new Date());
    const [maxParticipants, setmaxParticipants] = React.useState(new Date());

    const propsPicker = {startsAt, setStartsAt,endsAt, setEndsAt};

    return  (
    <div>
        <Grid container spacing={5} direction="column" sx={{paddingLeft:"15px"}} >
            <Grid item xs={12} >
                <Typography justifyContent="center" variant="h6">Valitse pelituokion ajankohta:</Typography>
                <FricoTimePicker {...propsPicker} />
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h6">Kutsu pelaajalle (viestipohja):</Typography>
            </Grid>
        </Grid>        
        <Container sx={{width: "1040px", height:"740px", padding:"15px"}}>
            { designReady && <Editor  
                design={design} 
                setDesign={setDesign} 
                handleChange={handleChange} 
                handleSaveEmail={handleSaveEmail} 
                handleSaveEmailTemplate={handleSaveEmailTemplate} 
            />}
        </Container>
    </div>    
    )
}


