import { getDatabase } from "firebase/database";
import firebase from "./firebase";

export const DATADB              = '/itu/testing-ahaa'

export const ADMINCOLLECTIONS    = ['users','usergroups','log_billing','log_system'];

export const DATATMP             = '/tmp'

// ======= Filepaths =========

// FIRESTORE
//   /itu
//   /itu/testing-ahaa
//   /itu/testing-ahaa/userdata         : data root for each user,  collections indexed by user.uid
//   /itu/testing-ahaa/usergroups       : document for each user,   contains list of all users that belong to the seme groups as user himself, indexed by user.uid
//   /itu/testing-ahaa/users            : document for each user,   contains all personal data of a user, indexed by user.uid
//   /tmp                               : temp data for users with an account, but no email verification at this stage
//

// this is used mainly by useFirestoreDoc, useFirestoreCol, newDoc
export const DATAROOT =  function (){
    // by default: dataroot is a dummy user
    let dataFolder = 'defaultUser';
    // typically: dataroot is uid
    if(firebase.auth().currentUser){ 
      dataFolder = firebase.auth().currentUser.uid 
    }
    return DATADB+'/userdata/'+dataFolder;
  } 

export const DOCPATH =  function (collection){
    if(!firebase.auth().currentUser){ 
      return null;
    } 
    if(ADMINCOLLECTIONS.includes(collection)){
      return DATADB+'/'+collection;  
    }
    return DATADB+'/userdata/'+firebase.auth().currentUser.uid +'/'+collection;
} 



//          STORAGE
//
//
//


//          REALTIME DATABASE
//
//
export const RDB = getDatabase();


