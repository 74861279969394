import { useRecoilState } from "recoil";
import { useAuth } from "./AuthProvider";
import { authInfoState } from "./userProfile";

export default function PrintState(props){
    const {userData:authInfo} = useAuth();
    if(props.state) {
        return <div><pre>{JSON.stringify(props.state, null, 2) }</pre></div>;
    } else {
        return <div><pre>{JSON.stringify(authInfo, null, 2) }</pre></div>;
    }
}