import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import MoreIcon from '@mui/icons-material/More';
import { IconButton, Box, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Tooltip } from '@mui/material';
import { FiEdit } from 'react-icons/fi';
import LinkIcon from '@mui/icons-material/Link';

import SimpleDialogSessionShare from './SimpleDialogSessionShare';
import { FricoContext } from '../../config/FricoProvider';
import { useNavigate } from 'react-router';
import SimpleDialogSessionLink from './SimpleDialogSessionLink';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));  


export default function SessionActions({session, urlJoinGame, anchorJoinGame, expanded, handleExpandClick}) {

    const frico = useContext(FricoContext);    
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };      

    // https://github.com/nygardk/react-share#readme
    // remember ending slash for LinkedIn???
    const shareUrl="https://play.frico.app/Ilmoittaudu-mukaan-peliin/6UUSIX/";
    const shareQuote="Ole edelläkävijä - ole mukana muutoksessa";  //FB, email body
    const shareTitle="Ole edelläkävijä - ole mukana muutoksessa";  //WhatsApp, email subject, Twitter
    // LinkedIn shows "og:title"

    const handlePlayGame = () => {
        // game state reset!
        // requestFullScreen();
        frico.setSessionId(session.id)
        navigate('/gamestart/'+session.id);
    }

    //<Tooltip title="Muut toiminnot" placement="top" arrow>                                            
    //    <IconButton size="small" variant="contained" >
    //        <MoreHorizRoundedIcon />
    //    </IconButton>
    //</Tooltip>                    


    //console.log(session);
    return (<>
            <Stack direction="row" spacing={1} sx={{paddingTop: "0px",paddingBottom: "10px"}} > 

              <Box>
                  <Tooltip title="Muokkaa pelin asetuksia" placement="top" arrow >                        
                      <Button size="small" variant="contained" endIcon={<FiEdit />} onClick={()=>{
                                  navigate('/editsessiontabs/'+session.id);
                                  frico.setSessionId(session.id);
                              }
                          }>
                          Muokkaa
                      </Button>
                  </Tooltip>
                </Box>

                <Box>
                    <Tooltip title="Avaa web-sivu ilmoittautumisille. Voit kopioida sivun linkin ja käyttää sitä tiedottamisessa tai markkinoinnissa." placement="top" arrow>                                            
                        <Button 
                            size="small" 
                            variant="contained" 
                            endIcon={<StarBorderIcon />} 
                            onClick={()=>{
                                window.open(session.seoCanonicalUrl)
                              }}
                            >
                            Ilmoittautumissivu
                        </Button>
                    </Tooltip>                    
                </Box>


                {
                  false && <Box>                    
                  <SimpleDialogSessionShare   shareUrl={session.seoCanonicalUrl}     
                                                shareQuote={session.seoDescription}
                                                shareTitle={session.seoTitle}
                    />
                </Box>
                }

                <Box>
                    <Tooltip title="Avaa pelinäkymä, jonka voit jakaa esimerkiksi Teams yhteydellä osallistujille." placement="top" arrow>                                            
                        <Button size="small" variant="contained" endIcon={<PlayCircleIcon />} onClick={()=>handlePlayGame()}>
                            Pelaa
                        </Button>
                    </Tooltip>                    
                </Box>

                {
                  false && <Box>
                      <SimpleDialogSessionLink session={session} urlJoinGame={urlJoinGame} anchorJoinGame={anchorJoinGame}/>
                  </Box>
                }

                <Box>
                    <Tooltip title="Avaa web-sivu palautteelle. Voit kopioida sivun linkin ja jakaa sen vaikka Teams chatissä tai sähköpostitse osallistujille." placement="top" arrow>                                            
                        <Button size="small" variant="contained" endIcon={<StarBorderIcon />} onClick={()=>window.open("/feedback/"+session.enrollId)}>
                            Palautesivu
                        </Button>
                    </Tooltip>                    
                </Box>
                <Tooltip title="Katso ilmoittautuneet ja palaute" placement="top" arrow>                                            
                  <Box sx={{fontSize:"150%",}}>
                    
                      <ExpandMore style={{marginTop:"-10px"}}
                        expand={expanded}
                        onClick={handleExpandClick}
                      >
                        <ExpandMoreIcon sx={{fontSize:"150%",}} />
                      </ExpandMore>                  

                  </Box>
                </Tooltip>                    

                {
                  // not in use yet

                  // <Box>
                  //     <IconButton 
                  //         size="small" 
                  //         variant="contained" 
                  //         onClick={handleClick}
                  //         >
                  //          <MenuIcon  />
                  //     </IconButton >
                  // </Box>
                }
            </Stack>
            {
              // not in use yet
              //<ICMenu open={open} anchorEl={anchorEl} handleClose={handleClose} enrollId={session.enrollId}/>
            }
        </>
    )
}


export const ICMenu = ({open, anchorEl, handleClose, enrollId}) => {
    const navigate = useNavigate();
    return (
  
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
              onClick={()=>{
                window.open("/feedback/"+enrollId)
                }
            }
        >
          <StarBorderIcon sx={{marginRight: "20px"}} />
              Näytä palautelomake
        </MenuItem>
    </Menu>
  
    )}
  