import React, { useContext, useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { CardActionArea, Collapse, Container, Paper, Rating, Stack, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks'
import { useNavigate } from 'react-router';

import Divider from '@mui/material/Divider';
import SessionActions from './SessionActions'

import firebase from '../../config/firebase'
import { doc,  deleteDoc } from "firebase/firestore"; 

import Players from './Players';
import axios from 'axios';
import { MyAvatar } from '../dashboardLayout/AccountMenu';
import ClearIcon from '@mui/icons-material/Clear';


const useStyles = makeStyles((theme) => createStyles({
        root: {
            width: "600px",
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    })
  );
  
  
export default function SessionCard({session, deleteSession, duplicateSession, userData}) {

    const classes = useStyles();
    const popupState = usePopupState({ variant: 'popover', popupId: 'cardActionMenu' })
    const navigate = useNavigate();

    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
      };    

    //const { doc:game, loading:loadingGame } = useFirestoreDoc("games", session.gameId);

    const handleDelete = (id) => {
        deleteSession(id);
        const db=firebase.firestore();
        const enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll', session.enrollId);
        deleteDoc(enrollRef);
        popupState.close();
    }

    const protocol = process.env.REACT_APP_BASEURL.includes('localhost')?"http://":"https://";
    const urlJoinGame = protocol+process.env.REACT_APP_BASEURL+"/watch/"+ session.id+"/"+"default-player"; // playerid = "default-player";
    const anchorJoinGame = '<a href="'+urlJoinGame+'">Osallistu FriCo-peliin</a>';

    const timestamp = session.startsAt;
    const date = new Date(timestamp);
    const modified = new Date(session.modified);
    const startString = date.toLocaleString("fi", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    const modifiedString = modified.toLocaleString("fi", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    const diff = humanDiff(session.endsAt,session.startsAt);

    const [missing, setMissing] = useState(true);
    const [iReady, setIReady] = useState(false);
    const missingImage = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fmissing-image.jpg?alt=media&token=2bcdc05b-c4d6-4107-ac7d-bc6ca898fccd";
    const checkImage = (img) => {
      axios
          .get(img)
          .then(() => {
            setMissing(false);
            setIReady(true);
            return img;
          })
          .catch(() => {
            setMissing(true);
            setIReady(true);
            return missingImage;
      });
    
    }
    checkImage(session.seoImage);

    if(!iReady) return null;

    return (
        <Card className={classes.root} elevation={5} sx={{marginLeft:"auto",marginRight:"auto"}}>
            <CardHeader sx={{backgroundColor:"#e3f2fd"}}
                avatar={ <MyAvatar /> }
                action={
                    //https://jcoreio.github.io/material-ui-popup-state/
                    <div>
                        <Tooltip title="Poista" placement="top" arrow >                                            
                            <IconButton {...bindTrigger(popupState)} >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>                    
                        <Menu
                            {...bindMenu(popupState)} 
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            >
                            <MenuItem onClick={()=>handleDelete(session.id)} >Poista peli</MenuItem>
                        </Menu>
                    </div>                    
                }
                title={<Typography variant="h7">{session.name}</Typography>}
            />
            <Tooltip title="Muokkaa pelin asetuksia" placement="top" arrow >
                <CardActionArea component="div" onClick={()=>{
                            navigate('/editsessiontabs/'+session.id)
                        }}>
                    <CardMedia
                            className={classes.media}
                            image={missing?missingImage:session.seoImage}
                        />
                    <CardContent component="div">
                        <Typography variant="body1" gutterBottom={false} color="textSecondary" >{"Ajankohta "+startString+", kesto "+diff}</Typography>
                        <Typography variant="body2" color="textSecondary" >{"Muokattu viimeksi "+modifiedString}</Typography>
                    </CardContent>
                </CardActionArea>
            </Tooltip>
            <Container style={{paddingLeft:"16px",paddingRight:"16px" }}>

                <SessionActions 
                    session={session}                     
                    anchorJoinGame={anchorJoinGame} 
                    urlJoinGame={urlJoinGame} 
                    expanded={expanded}
                    handleExpandClick={handleExpandClick}
                    />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Container>
                        <Players session={session} />
                        <FeedbackSummary session={session} />
                    </Container>
                </Collapse>
                <Divider sx={{paddingTop:"15px",paddingBottom:"5px"}}/>
            </Container>
        </Card>
    )
}

export function humanDiff (t1, t2) {
    const diff = Math.max(t1,t2) - Math.min(t1,t2) 
    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN
    const hrs = Math.floor(diff/HRS)
    const min = Math.floor((diff%HRS)/MIN).toLocaleString('en-us', {minimumIntegerDigits: 2})
    const sec = Math.floor((diff%MIN)/SEC).toLocaleString('en-us', {minimumIntegerDigits: 2})
    const ms = Math.floor(diff % SEC).toLocaleString('en-us', {minimumIntegerDigits: 4, useGrouping: false})
    // return `${hrs}:${min}:${sec}.${ms}`
    return `${hrs} h ${min} min`
}

export function FeedbackSummary({session}) {
  
  if(!session.feedbacks) return <Typography sx={{paddingTop:"10px", paddingBottom:"10px"}}><b>Palautteita ei vielä ole.</b></Typography>;
  const fbs = session.feedbacks;
  let fbValuesGamemaster=[];
  let fbValuesContent=[];
  let fbValuesOverall=[];
  let fbValuesOpenSummary='';

  Object.keys(fbs).forEach((fb) => {
        console.log("fbs[fb].fbData",fbs)
        fbValuesGamemaster.push(fbs[fb].fbdata.feedbackGamemaster);
        fbValuesContent.push(fbs[fb].fbdata.feedbackContent);
        fbValuesOverall.push(fbs[fb].fbdata.feedbackOverall);
        fbValuesOpenSummary = fbValuesOpenSummary.trim() + (fbs[fb].fbdata.feedbackOpen?" / ":'')+ (fbs[fb].fbdata.feedbackOpen?fbs[fb].fbdata.feedbackOpen.trim():'');
    })
  
    console.log("Summary",fbValuesGamemaster );
  if(fbValuesGamemaster.length==0 || fbValuesContent.length==0 || fbValuesOverall.length==0) 
    return <Typography sx={{paddingTop:"10px", paddingBottom:"10px"}}><b>Palautteita ei vielä ole.</b></Typography>;

  const gamemasterAve = fbValuesGamemaster.reduce((a, b) => a + b, 0) / fbValuesGamemaster.length;
  const contentAve = fbValuesContent.reduce((a, b) => a + b, 0) / fbValuesContent.length;
  const overallAve = fbValuesOverall.reduce((a, b) => a + b, 0) / fbValuesOverall.length;
  const N = fbValuesOverall.length;


  return (
      <Stack spacing={0} sx={{width:"100%"}}>
        <Divider sx={{paddingTop:"15px",paddingBottom:"5px"}}/>
        <Typography variant="normal" sx={{paddingTop:"20px", paddingBottom:"20px"}}><b>Palauteyhteenveto (N = {N})</b></Typography>
        <Stack direction="row">
            <Typography 
                variant="normal" 
                sx={{width:"50%", textAlign:"right", paddingRight:"20px"}}>
                    <b>Pelinohjaaja</b>
            </Typography>
            <Rating size="large" value={gamemasterAve} precision={0.5} readOnly={true} />
        </Stack>
        <Stack direction="row">
            <Typography 
                variant="normal" 
                sx={{width:"50%", textAlign:"right", paddingRight:"20px"}}>
                    <b>Osallistujien aktiivisuus</b>
            </Typography>
            <Rating size="large" value={contentAve} precision={0.5} readOnly={true} />
        </Stack>
        <Stack direction="row">
            <Typography 
                variant="normal" 
                sx={{width:"50%", textAlign:"right", paddingRight:"20px"}}>
                    <b>Kokonaisuus</b>
            </Typography>
            <Rating size="large" value={overallAve} precision={0.5} readOnly={true} />
        </Stack>
        <Typography variant="normal" sx={{paddingTop:"20px"}}><b>Kertynyt sanallinen palaute:</b></Typography>
        <Typography variant="normal" sx={{paddingTop:"10px"}}>{fbValuesOpenSummary}</Typography>
    </Stack>
  )
}
