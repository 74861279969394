import React, {useState} from 'react'
import Slider from 'react-input-slider';

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";

function FricoSlider({avatar, sliderValue, handleSliderChangeRDB, handleSliderChangeView, scaleFactor=1, gamelang}) {
    // <Slider axis="xy" x={state.x} y={state.y} onChange={setState} />
    return (
        <div>
            <Slider
                axis="x"
                x={sliderValue}
                onChange={({ x }) => {
                                        handleSliderChangeRDB(x);
                                        handleSliderChangeView(x);
                                      }}
                styles={{
                    track: {
                      width: "80%",
                      backgroundColor: '#9dbbcf',
                      height:"4px",
                      margin: "auto"
                    },
                    active: {
                      backgroundColor: '#3498db',
                      height:"6px"
                    },
                    thumb: {
                      width: "calc("+scaleFactor+"*5em)",
                      height: "calc("+scaleFactor+"*5em)",
                      backgroundImage: "url("+avatar+")",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      filter: svgFilter,
                      borderRadius: "0%",
                    },
                  }}
            />
        </div>
    )
}

export default FricoSlider