import React from 'react'
import firebase from "../../config/firebase";
import { useNavigate } from 'react-router';
import { Button, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { useAuth } from '../../config/AuthProvider';
import AccountMenu from './AccountMenu'
const SignedInLinks = () => {
  const {userData, loading, currentUser} = useAuth();
  // const navigate = useNavigate();

  // Avatar sx={{ bgcolor: '#F7931E' }} >{ userData.initials }</Avatar>

  //  <Grid item>
  //    <Button 
  //      size="medium"
  //      variant="contained" 
  //      color="primary" 
  //      onClick={()=>{
  //          firebase.auth().signOut();
  //          navigate('/')
  //        }
  //      }>Kirjaudu ulos
  //    </Button>
  //  </Grid>

  // sx={{backgroundColor: "#e3f2fd"}}
  return (
      <Grid container  alignItems="center" justifyContent="flex-end" >
        <Grid item>
          {userData && (<AccountMenu userData={userData} currentUser={currentUser}/>) }
        </Grid>
      </Grid>
  )
}

export default SignedInLinks