import { Box, Grid, TextField, Typography } from '@mui/material';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React from 'react'
import { Controller } from 'react-hook-form';
import { triggerInputChange } from './BasicInfo';
import TextEditor from './TextEditor/TextEditor';

export default function Enroll( { control, formState, handleSubmit}) {

  return (

    <Grid container alignContent={'left'} spacing={2}  >
    
    <Grid item xs={12}>
      <Typography variant="h5" align="left" sx={{fontWeight:"bold", color:"222222", backgroundColor:"#CCCCCC", paddingLeft:"20px"}}>
        Tämä teksti näkyy pelin ilmoittautumissivulla:
      </Typography>
    </Grid>
    
    <Grid item xl={12}>
      <Controller 
            name='enrollText' 
            defaultValue=""
            control={control} 
            render={({ field })=> (
              <>
                <Box sx={{width:"100%"}}>
                  <TextEditor 
                    defaultValue={field.value} 
                    onChange={
                      (html)=>{
                          field.onChange(html);
                        }
                      }
                    onBlur={(event, editorState) => {
                           console.log(" enrollText ",draftToHtml(convertToRaw(editorState.getCurrentContent())));
                           triggerInputChange(field.name, draftToHtml(convertToRaw(editorState.getCurrentContent())))
                        }}
                    
                    />
                </Box>
                <TextField  style={{visibility:"hidden", height:0, padding:0, margin:0}} id={"enrollText"} margin="dense" />
              </>
            )}
      />
      </Grid>

    </Grid>             

  )

}
