import { useTheme } from '@emotion/react';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system';
import React, { useContext, useState, useEffect } from 'react'
import { ref, onValue, update, remove } from "firebase/database";
import { RDB } from '../../config/datapaths'
import { FricoContext } from '../../config/FricoProvider';
import ArticleIcon from '@mui/icons-material/Article';

import { useNavigate } from 'react-router-dom';

import { Document, Packer, Paragraph, TextRun, ImageRun,SectionType, HeadingLevel, AlignmentType, ExternalHyperlink } from "docx";
import { saveAs } from "file-saver";

import gardenScene_01 from "../../assets/scenes/garden/garden-01.jpg";
import gardenScene_02 from "../../assets/scenes/garden/garden-02.jpg";
import gardenScene_03 from "../../assets/scenes/garden/garden-03.jpg";
import gardenScene_04 from "../../assets/scenes/garden/garden-04.jpg";
import gardenScene_05 from "../../assets/scenes/garden/garden-05.jpg";
import gardenScene_06 from "../../assets/scenes/garden/garden-06.jpg";

const garden = [gardenScene_01, gardenScene_02, gardenScene_03, gardenScene_04, gardenScene_05, gardenScene_06, ];

// consider
// https://github.com/nitin42/redocx/issues/29
// https://github.com/nitin42/redocx
// https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
export default function Garden() {

    const frico = useContext(FricoContext);  
    const navigate = useNavigate();
    useEffect(()=>{
        !frico.session && navigate('/');
    },[]);

    const [scene, setScene] = useState(0);

    // =========================================================================================
    // make session state Textbox and return to WaitSession on unmound
    // =========================================================================================
    const sessionRef    = ref(RDB, 'tmp/sessions/'+frico.sessionId);
    const usersRef      = ref(RDB, 'tmp/sessions/'+frico.sessionId+'/users');
    useEffect(()=>{
        update(sessionRef, {
            watchComponent: "Textbox",
        })
        return ()=> update(sessionRef, { watchComponent: "WaitSession", })
    },[]);
    // =========================================================================================
        
    // =========================================================================================
    // anonymous users: Players from RDB 
    // =========================================================================================

    const [players, setPlayers] = useState(null);
    useEffect(()=>{    
        return onValue(usersRef, (snapshot) => { // oneliner to retunt the unsubscriber
            const players=snapshot.val();
            setPlayers(players);
        });
    },[]);
    let commentsCombined={};
    if(players){
        Object.entries(players).map(([key, value])=>{
            commentsCombined={...commentsCombined, ...value.textboxValues}
        });
    }
    let toArrayCommentsCombined = Object.values(commentsCombined);
    let sortedByTimestamp = [...toArrayCommentsCombined].sort((a, b) => b.timestamp - a.timestamp) // sorted array
    // =========================================================================================

    if(frico.session ) {
        frico.session.gameState.started = true;
        console.log("Saving....", sortedByTimestamp);
        frico.session.gameState.userComments1 = sortedByTimestamp;
        frico.sessionSave();
    } 

    if(!frico.session ) return null;
    let boxTitle= frico.session.gameState.text_ObservationInCastle || "Havaintojani linnassa:";

    return (
        <BackgroudImages imageArray={garden} selectedImage={scene}                 
            onClick={()=>{  
                    if(scene<garden.length-1){
                    setScene(scene+1)
                }
            }}
            style={{
                paddingLeft:"20px",
                paddingTop:"70px",
            }}
        >
            <Box style={{position: "absolute", zIndex:10, pointerEvents: "none", cursor:"pointer" }}>
                <Typography 
                    variant="h4" 
                    align="left" 
                    style={{ 
                        backgroundColor:"rgba(255,255,255,0.7)",
                        marginBottom: "10px",
                        paddingLeft: "10px",
                        maxWidth:"600px",
                        width:"40vw", 
                        pointerEvents: "none"
                        
                    }} 
                > 
                    {boxTitle} 
                </Typography>

                <Box 
                    width="40vw" 
                    sx={{ maxWidth:"600px",height:"70vh", backgroundColor:"rgba(255,255,255,0.3)", pointerEvents: "none"}} 
                    style={{overflowY:"auto",}} 
                >
                    { sortedByTimestamp.map((value,key) =><TextboxValuesViewer key={key} {...value}/>  )   }
                </Box>
                <Button 
                    size="medium"
                    variant="contained" 
                    color="primary" 
                    align="left"
                    style={{marginTop:"10px", pointerEvents: "auto" }}
                    onClick={()=>processDocx(sortedByTimestamp, frico.session.gameState.wordTitle, frico.session.gameState.wordIntro)}
                >
                    <ArticleIcon sx={{marginRight:"10px"}}/> {frico.session.gameState.wordAction?frico.session.gameState.wordAction:"Luo Word-dokumentti"}
                </Button>
            </Box>                
        </BackgroudImages>        
    )
}

function TextboxValuesViewer({avatar, text}) {
    return (
        <Card sx={{ display: 'flex', backgroundColor:"rgba(255,255,255,0.7)", margin: "5px", }}>
            <CardMedia
                component="img"
                sx={{ marginLeft: "10px", width: "50px", objectFit:"contain"}}
                image={avatar}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" align="left" variant="h5">
                        {text}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    )
}

function BackgroudImages( {imageArray, selectedImage, children, style, onClick}){
    console.log("window.FriCoGarden",window.FriCoGarden)
    return(
        <div>
            <div style={{ position: "absolute",  width: "100vw",  height: "100vh", cursor:"pointer" }} onClick={onClick}>
                <img 
                    style={{
                        position:"absolute", 
                        width: "100vw",  
                        height: "100vh", 
                        objectFit: "cover",
                    }}
                    src={garden[selectedImage]}
                />
            </div>
            <div style={{...style,  width: "100vw",  height: "100vh"}}>
                {children}
            </div>
        </div>
    )

}

import Logo from "../../assets/logos/logo-verticalx1080.png"
async function processDocx(
                        comments, 
                        wordTitle = "Ammatillinen vuorovaikutus – ensipainos", 
                        wordIntro = "Yhteisellä matkalla Tieteentekijöiden linnassa tehtyjä huomioita ammatillisesta vuorovaikutuksesta:"
                    ){

    const img = await fetch(Logo);
    const imgBlob = await img.blob();

    const image = new Image();
    image.src = Logo;
    image.onload = () => createDocx({comments, imgBlob, width:image.width, height:image.height, wordTitle, wordIntro});   
}

function createDocx({comments, imgBlob, width, height, wordTitle, wordIntro}) {

    // A4: w:w="11906" w:h="16838" (210 mm x 297 mm)
    const mm2dxa = mm => 11906/210*mm;

    const newBullet = (text) => {
        return new Paragraph({
            spacing: {
                before: mm2dxa(5),
            },
            bullet: {
                level: 0 // How deep you want the bullet to be. Maximum level is 9
            },
            children: [                    
                new TextRun({
                    text,
                    bold: false,
                    font: "Calibri",
                    allCaps: false,
                    color: "000000",                            
                    size: 32, // this is half points
                }),
            ]
        })
    }


    const commentsArray = [];
    comments.map(comment => commentsArray.push(newBullet(comment.text)))

    const doc = new Document({
        creator: "www.frico.app",
        description: wordTitle,
        title: wordTitle,
        background: {
            color: "C45911",
            color: "FFFFFF",
        },

        // ==================== PAGE 1 ===================================        
        sections: [{
            properties: {
                type: SectionType.CONTINUOUS,
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    spacing: {
                        before: mm2dxa(40),
                    },
                    children: [
                        new ImageRun ({
                            data : imgBlob,
                            transformation: {
                                width:width/5,
                                height:height/5,
                            },                            
                        }),
                    ],
                    
                }),                
                new Paragraph({
                    spacing: {
                        before: mm2dxa(30),
                    },
                    children: [
                        new TextRun({
                            text: wordTitle,
                            bold: true,
                            font: "Calibri",
                            allCaps: false,
                            color: "264C73",                            
                            size: 56, // this is half points
                        }),
                    ],
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,               
                 }),
                 new Paragraph({
                    spacing: {
                        before: mm2dxa(30),
                    },
                    children: [
                        new ExternalHyperlink({
                            children: [
                                new TextRun({
                                    text: "www.frico.app",
                                    bold: false,
                                    font: "Calibri",
                                    allCaps: false,
                                    size: 40, // this is half points
                                    color: "1A334D",
                                    characterSpacing: 50,
                                }),
                            ],
                            link: "https://www.frico.app",
                        }),
                    ],
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,               
                 }),

                 // ==================== PAGE 2 ===================================
                 new Paragraph({
                    pageBreakBefore: true,
                    spacing: {
                        before: mm2dxa(30),
                        after: mm2dxa(10),                        
                    },
                    children: [
                        new TextRun({
                            text: wordIntro,
                            bold: true,
                            font: "Calibri",
                            allCaps: false,
                            color: "264C73",                            
                            size: 40, // this is half points
                        }),
                        
                    ],
                }),
                ...commentsArray
            ],
        }],
    });

    Packer.toBlob(doc).then((blob) => {
        // saveAs from FileSaver will download the file
        saveAs(blob, wordTitle+".docx");
    });
}