import { createContext, useEffect, useState } from "react";
import { collection, doc, query, where, onSnapshot, updateDoc } from "firebase/firestore";
import { fs } from "./firebase";
import { DATAROOT } from "./datapaths";

import { createBrowserHistory } from "history";

export const FricoContext = createContext({});

export const FricoProvider = (props) => {

  const history = createBrowserHistory();
  const sessionUrls = ["gamestart","lobby","library","garden","pdfplayer","gmslider","editsessiontabs"];

  let sessionIdFromPath = '';
  // gets the first element on path, [0] is an empty string, because url starts with /
  if (sessionUrls.includes(window.location.pathname.split("/")[1])) {
    // gets the last element on path
    sessionIdFromPath = history.location.pathname.split("/").pop();
  }

  // ==============================================
  // Session context
  // ==============================================
  const [sessionId, setSessionId] = useState(sessionIdFromPath);
  const [session, setSession] = useState({});
  const [sessionReady, setSessionReady] = useState(false);
  useEffect(()=>{
    if(!sessionId) { setSessionReady(false); return; } 
    setSessionReady(false);
    return onSnapshot( doc(fs, DATAROOT()+'/sessions', sessionId), (doc) => {
                        const session = doc.data();
                        setSession(session); 
                        setSessionReady(true); 
                    });
  },[sessionId]);
  // ==============================================

  // ==============================================

  const [fricoContext, setFricoContext] = useState({});
  useEffect(()=>{
        setFricoContext({
                sessionId, 
                setSessionId,
                sessionReady,
                sessionSave,
                sessionSaveData,
                session: sessionReady && {...session },
          })
  },[sessionReady]);

  const sessionSave = () =>{
    const sessionRef = doc(fs, DATAROOT()+'/sessions', sessionId);
    delete session.players;
    delete session.feedbacks;
    updateDoc(sessionRef, session);

  }
  const sessionSaveData = (data) =>{
    const sessionRef = doc(fs, DATAROOT()+'/sessions', sessionId);
    updateDoc(sessionRef, data);

  }

  return (
    <FricoContext.Provider value={fricoContext}>
      {props.children}
    </FricoContext.Provider>
  );


}








