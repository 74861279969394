import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import firebase from "../../config/firebase";
import { useNavigate } from 'react-router';
import { red } from '@mui/material/colors';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { doc, getDoc } from 'firebase/firestore';
import { Stack } from '@mui/material';

const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FArtboard%201.png?alt=media&token=9921c248-11bf-4188-aee5-39d68b1859ba"

export default function AccountMenu({userData, currentUser}) {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // <AccountCircleIcon sx={{ fontSize: "40px" }}/> HelpOutlineIcon display: inline-block
  return ( userData && (
    <Stack direction="row" spacing={2}  justifyContent="center" alignItems="center" sx={{ marginRight:"30px" }} >
      <Box >
        <Tooltip title={<div >FriCo-helpdesk</div>}>
          <IconButton onClick={()=>navigate('/help')} >
            <HelpOutlineIcon sx={{height:'35px', width:'35px'}} /> <Typography variant="h6">Tuki</Typography>
          </IconButton> 
        </Tooltip>
      </Box>
      <Box >
        <Tooltip title={<div ><b>Tilin<br/>asetukset:</b><br/>{userData.firstName} {userData.lastName}</div>}>
          <IconButton 
            onClick={()=>navigate('/editusertabs')}
          >
            <MyAvatar height='50px' width='50px'/>
          </IconButton> 
        </Tooltip>
      </Box>
      <Box >
        <Tooltip title={<div >kirjaudu ulos</div>}>
          <IconButton 
            onClick={handleClick}
          >
            <LogoutIcon  sx={{height:'30px', width:'30px'}}/>
          </IconButton> 
        </Tooltip>
        <FMenu anchorEl={anchorEl} open={open} handleClose={handleClose}/>
      </Box>
    </Stack>
  )
  );
}

export function MyAvatar({height='60px', width='60px', uid=firebase.auth().currentUser.uid}) {
  const [src,setSrc]=React.useState('');
  const db=firebase.firestore();
  const docRef = doc(db, "/itu/testing-ahaa/users/", uid)
  getDoc(docRef).then((mdoc)=>{
    const user = mdoc.data();
    setSrc(user.userImage || defaultAvatar);
  })
  if(!src) return null;
  return <Avatar src={src}  sx={{ height, width }}  />

}


export const FMenu = ({open, anchorEl, handleClose}) => {
  const navigate = useNavigate();
  return (

    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
          onClick={()=>{
              firebase.auth().signOut();
              navigate('/')
              }
          }
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Kirjaudu ulos
      </MenuItem>
  </Menu>

  )}
