import React, { useContext, useState } from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { Delete, FileCopy, PlayArrow, } from '@mui/icons-material/';
import { Box, CardActionArea, Container, Grid, Paper, Stack, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router';
import useFirestoreDoc from '../../config/useFirestoreDoc';

import Divider from '@mui/material/Divider';
import SessionActions from './SessionActions'

import firebase from '../../config/firebase'
import { doc, setDoc, getDoc, deleteDoc, updateDoc } from "firebase/firestore"; 

import ClearIcon from '@mui/icons-material/Clear';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks'

export default function Players({session}) {

    //const { doc:game, loading:loadingGame } = useFirestoreDoc("games", session.gameId);
    const handleDelete = (id) => {
        deleteSession(id);
        const db=firebase.firestore();
        const enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll', session.enrollId);
        deleteDoc(enrollRef);
        popupState.close();
    }

    const nameArray = [];
    console.log("session",Object.entries(session.players));
    Object.entries(session.players).forEach(([key, value]) => {
        console.log("value",value.email)
        nameArray.push(value)
    });
    if(!session.players||!nameArray.length) return <Typography sx={{paddingTop:"10px", paddingBottom:"10px"}}><b>Ilmoittautuneita ei vielä ole.</b></Typography>;
    // Object.entries(session.players).forEach(([key, value]) => <Typography key={key}>"{key}"</Typography>   )
    return (
        <div>
            <Typography sx={{paddingTop:"10px", paddingBottom:"10px"}}><b>Osallistujat</b></Typography>
            <Stack spacing={2}>
                {nameArray.map((value)=><PlayerCard key={value.playerId} player={value} session={session} />)}
            </Stack>                
        </div>
    )
}

export function PlayerCard({player, session}) {
  const db=firebase.firestore();
  return (
    <Card >
        <Stack direction="row" sx={{ marginBottom: "-15px", paddingBottom: "0px"}}>
            <Box sx={{ width: "70%"}}>
                <CardContent sx={{ flex: '1 0 auto',padding: "5px"}}>
                <Typography component="div" variant="h7"  >
                    {player.email}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" component="div" >
                    {player.firstname} {player.lastname}, p. {player.mobile}
                </Typography>
                </CardContent>
            </Box>
            <Stack  direction="row" sx={{ width: "30%", paddingRight: "10px", }}>
                <Box sx={{marginLeft:"auto" }}>
                    {player.emailVerified?
                        (
                            <Tooltip title={"Osoite on vahvistettu"} placement="top" arrow >                                            
                                <IconButton color="success" disableRipple={true} sx={{ "&:hover": { cursor: "default" }}} >
                                    <MarkEmailReadIcon />
                                </IconButton>
                            </Tooltip>                    
                        )
                        :
                        (
                            <Tooltip title={"Osoitetta ei ole vahvistettu"} placement="top" arrow >                                            
                                <IconButton color="warning" disableRipple={true} sx={{ "&:hover": { cursor: "default" }}}>
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>                    
                        )

                    }
                </Box>
                <Box>
                    <DeleteButton 
                                    buttonAction={()=>{
                                        const players={...session.players};
                                        const sessionRef = doc(db, '/itu/testing-ahaa/userdata/'+firebase.auth().currentUser.uid+'/sessions/'+session.id);
                                        delete players[player.playerId];
                                        updateDoc(sessionRef,{players} );
                                    }} 
                                    buttonText="Poista pelaaja"
                                    />
                </Box>
            </Stack>
        </Stack>
    </Card>
  )
}


export function DeleteButton({buttonAction,buttonText}) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'cardActionMenu' })    
  return (
    <div>
        <Tooltip title={buttonText} placement="top" arrow >                                            
            <IconButton {...bindTrigger(popupState)}  >
                <ClearIcon />
            </IconButton>
        </Tooltip>                    
        <Menu
            {...bindMenu(popupState)} 
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
            <MenuItem onClick={()=>{
                                    buttonAction();
                                    popupState.close();
                                    }} >
                {buttonText}
            </MenuItem>
        </Menu>
    </div>                    
)
}
