import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import styles from './styles.module.css'
import { useWindowDimensions } from '../../config/helpers'
import FricoCard from './card/FricoCard'

import cardFront from "../../assets/cards/card-text.svg"
import cardFrontFiftyFifty from "../../assets/cards/card-FiftyFifty.svg"


// https://codesandbox.io/s/github/pmndrs/use-gesture/tree/v10/demo/src/sandboxes/cards-stack?file=/src/App.tsx
//
// DOCs
// https://github.com/pmndrs/use-gesture
// https://react-spring.io/basics
// 
// https://www.creativebloq.com/how-to/react-spring
// https://blog.logrocket.com/animations-with-react-spring/

// card numbering appearing on card back
const cards = [ 1,2,3,4,5,6,7,8 ]
const cardTexts0=[
    'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
    '<b>Kysyminen vuorovaikutustilanteissa:</b> <br>'+ 
    '• osoittaa kiinnostusta <br>' +
    '• lisää ymmärrystä<br>' +
    '• mitä muita hyötyjä olet havainnut?',
    'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
    '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
    'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
    'B) laittautumista vuorovaikutustilanteeseen <br>' +
    'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
    'D) pyytää keskustelukumppania katsomaan peiliin',
    '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
    'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
    'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
    '<b>Vuorovaikutustilanteen ohjaaminen on </b><br>' +
    'A) minielokuvan tekemistä <br>' +
    'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
    'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
    'D) tapa saada oma ajatus läpi' ,
]

const imageFronts = [cardFront, cardFront, cardFront, cardFrontFiftyFifty, cardFront, cardFront, cardFront, cardFrontFiftyFifty];

export function CardDeck({frico}) {
    const [gone] = useState(() => new Set())        // The set flags all the cards that are flicked out
    const {width, height}=useWindowDimensions();    // we'll make the whole stuff responsive

    var cardTexts = [];

    // frico.session.gameState.cardsLobby:
    // frico.session.gameState.cardsLobby.imageCount:         8,
    // read questions from session for Frico data version 2
    if(frico.session.gameState.version==2){
        for (let i = 0; i < frico.session.gameState.cardsLobby.imageCount; i++) {
            cardTexts[i] = frico.session.gameState.cardsLobby[i+1].text;
            imageFronts[i] = frico.session.gameState.cardsLobby[i+1].img;
        }
    } else {
        cardTexts = cardTexts0;
    }

    // this defines the position from which cards seem to apper
    const from = (i) => ({ x: 0+"vw", rot: 0, scale: 1, y: -200+"vh" })
    
    // this defines the position where the cards will land (two rows)
    const rotStep = 5;
    const nCards = cards.length;
    const topRow = parseInt(nCards/2+0.6);
    
    // some fine tuning for different number of cards
    const N = cards.length;
    let fiddle = 0;
    fiddle = N<7 && N>4 ? 20 : N<5 ? 30:0;

    const to = (i) => ({
      x: ((width/20 +  (i<topRow?i:i-topRow) * width/100*20)/width*100+"vw"),
      y: ((height/20 + (i<topRow?0:1) * width/7)/height*100+fiddle+"vh"),
      scale: 1.,
      // rot: 10-Math.random() * 20,
      rot: -topRow/2*(rotStep-1)+ (i<topRow?i:i-topRow)*rotStep,
      delay: 3000+i * 100,
    })
        
    // This is being used down there in the view, it interpolates rotation and scale into a css transform
    const trans = (r, s, x, y) => `perspective(1500px) rotateX(0deg) rotateY(${r / 100}deg) rotateZ(${r}deg) scale(${s})`;
    //const trans = (r, s, x, y) => `perspective(1500px) rotateX(0deg) rotateY(${r / 100}deg) rotateZ(${r}deg) scale(${s})`;

    //Create a bunch of springs using the helpers above
    const [props, api] = useSprings( cards.length, i => ( { ...to(i), from: from(i), } ) ) ;

    const [zArray,setZArray] = useState([0,0,0,0,0,0,0,0])

    // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
    return (
        <>
        {props.map(({ x, y, rot, scale }, i) =>  (
                    
                    <animated.div className={styles.deck} key={i} style={{ x, y,  zIndex: zArray[i]}} _id="cardOuterDiv">
                     {
                        /* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */
                        // <FricoCard  cardNumber={cards[i]} imageText={cardTexts[i]} imageFront={imageFronts[i]} zArray={zArray} setZArray={setZArray} home={to} />
                        // console.log("Card number ", i)
                    }
                    <animated.div
                        style={{
                          transform: interpolate([rot, scale, x, y], trans),
                        }}
                    >
                        <FricoCard  cardNumber={cards[i]} imageText={cardTexts[i]} imageFront={imageFronts[i]} zArray={zArray} setZArray={setZArray} home={to} />
                    </animated.div>      
                </animated.div>
                )
            )}
        </>
    )
}


