import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// dialogue
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";

import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import { Box, Paper, Tooltip, Typography } from '@mui/material';

// https://github.com/nygardk/react-share#readme
// remember ending slash for LinkedIn???
// LinkedIn shows "og:title"
export default function SimpleDialogSessionShare(props) {

    const { shareUrl, shareQuote, shareTitle } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      console.log(">>>> opening");
      setOpen(true);
    };
  
    const handleClose = () => {
      console.log(">>>> closing");
      setOpen(false);
    };
  
    return (
      <div>
        <Tooltip title="Valitse tapa, jolla haluat kutsua pelaajat osallistumaan peliin" placement="top" arrow>                                            
          <Button size="small" variant="contained" endIcon={<ShareTwoToneIcon />} onClick={handleClickOpen}>
            Kutsu peliin
          </Button>
        </Tooltip>
        <SimpleDialog
          open={open}
          handleClose={handleClose}
          shareUrl={shareUrl}
          shareQuote={shareQuote}
          shareTitle={shareTitle}
        />
      </div>
    );
  }

const SimpleDialog = (props) => {

    const { open, handleClose, shareUrl, shareQuote, shareTitle} = props;
    const iconSize=28;  

    return (
      <Dialog open={open} onClick={handleClose}>
        <DialogTitle>
          Miten haluat kutsua pelaajat?
          <Typography>
            Jos haluat itsellesi pelkän ilmoittautumislinkin, valitse <br/> "luo sähköpostikutsu" ja kopioi siinä oleva weblinkki.
          </Typography>
        </DialogTitle>
        <List sx={{ pt: 0 }}>

         <ListItem onClick={handleClose} button={true}>
              <EmailShareButton
                  url={shareUrl}
                  subject={shareTitle}
                  body={shareQuote+"\n\n"}
                  separator=""                        
              >
                  <Box sx={{padding:"5px", width:"300px"}}>
                      <Stack direction="row" spacing={2}>
                          <EmailIcon size={iconSize} round />
                          <Typography>luo sähköpostikutsu</Typography>
                      </Stack>
                  </Box>
              </EmailShareButton>
          </ListItem>

          <ListItem onClick={handleClose} button={true}>
              <Box sx={{padding:"5px", width:"300px"}}>
                      <FacebookShareButton
                          url={shareUrl}
                          quote={shareQuote}
                      >
                          <Stack direction="row" spacing={2}>
                              <FacebookIcon size={iconSize} round />            
                              <Typography>luo uusi FB julkaisu</Typography>
                          </Stack>
                      </FacebookShareButton>
              </Box>
          </ListItem>
          
          <ListItem onClick={handleClose} button={true}>
              <Box sx={{padding:"5px", width:"300px"}}>
                      <LinkedinShareButton url={shareUrl} >
                          <Stack direction="row" spacing={2}>
                              <LinkedinIcon size={iconSize} round />            
                              <Typography>luo uusi LinkedIn julkaisu</Typography>
                          </Stack>
                      </LinkedinShareButton>
              </Box>
          </ListItem>

          <ListItem onClick={handleClose} button={true}>
              <Box sx={{padding:"5px", width:"300px"}}>
                  <WhatsappShareButton
                      url={shareUrl}
                      title={shareTitle}
                      separator=" "
                      windowWidth="800"
                      windowHeight="800"
                  >
                          <Stack direction="row" spacing={2}>
                              <WhatsappIcon size={iconSize} round />            
                              <Typography>luo uusi WhatsApp viesti</Typography>
                          </Stack>
                  </WhatsappShareButton>
              </Box>
          </ListItem>

          <ListItem onClick={handleClose} button={true}>
              <Box sx={{padding:"5px", width:"300px"}}>
                  <TwitterShareButton
                          url={shareUrl}
                          title={shareTitle+"\n"}
                          separator=""
                      >
                          <Stack direction="row" spacing={2}>
                              <TwitterIcon size={iconSize} round />
                              <Typography>luo uusi Twitter julkaisu</Typography>
                          </Stack>
                      </TwitterShareButton>
              </Box>
          </ListItem>

        </List>
      </Dialog>
    );
  }
  
  

