import { Box, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import 'moment/locale/fi';

// https://www.npmjs.com/package/@mui/lab

export default function Some( { control, formState, seoCanonicalUrl}) {
    const { errors, isDirty, isSubmitting, touchedFields, submitCount } = formState;
    //console.log(formState)
  return (
    <Grid container alignContent={'center'} spacing={2}  >

    <Grid item xs={12}>
        <Typography variant="h5" align="left" sx={{fontWeight:"bold", color:"222222", backgroundColor:"#CCCCCC", paddingLeft:"20px"}}>
          Kun jaat ilmoittautumislinkin somessa, nämä tekstit näkyvät julkaisussa:
        </Typography>
        <Typography  align="left" sx={{fontWeight:"bold", color:"222222", backgroundColor:"#CCCCCC", paddingLeft:"20px"}}>
          Myös perustiedot-välilehdellä oleva kuvaa tulee osaksi julkaisua.
        </Typography>
    </Grid>

    <Grid item xs={12}  >
        <Controller 
          name="seoTitle" 
          defaultValue=""
          control={control} 
          render={({ field })=> (
                <TextField
                  required
                  {...field}
                  label="Somejulkaisun otsikkotekstit"
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message:""}
                  margin="dense"
                  fullWidth
              />
            )}
          />
    </Grid>

    <Grid item xs={12}  >
        <Controller 
          name="seoDescription" 
          defaultValue=""
          control={control} 
          render={({ field })=> (
                <TextField
                  multiline={true}
                  minRows={3}
                  maxRows={15}
                  required
                  {...field}
                  label="Somejulkaisun tekstikappale"
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message:""}
                  margin="dense"
                  fullWidth
              />
            )}
          />
    </Grid>

    <Grid item xs={12}  >
            <Typography textAlign="center" sx={{width:"500px", margin:"auto"}} >
              Alla on FriCo-pelin somelinkki, jonka voit jakaa esimerkiksi Facebook tai LinkedIn julkaisuissa. 
              Julkaisuun tulevat automaattisesti juuri tämän FriCo-pelin tiedot ja klikkaamalla julkaisua lukija päätyy pelin ilmoittautumissivulle.
              Voit itse kirjoittaa julkaisuun haluamasi saatesanat. Valitse linkin teksti hiirellä ja kopioi se leikepöydälle ja liitä 
              sen jälkeen luomaasi julkaisuun.
              <br/><br/>
            </Typography>
            <Typography textAlign="center">
                <b>{seoCanonicalUrl}</b>
                <br/><br/>
            </Typography>

    </Grid>


  </Grid>             
  )
}

