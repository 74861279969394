import React, { useState, useEffect, useCallback } from 'react'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import './../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import toolbar from './toolbar'

//import { styContainer, styWrapper, styToolbar, styEditor } from './style'

const getInitialState = (defaultValue) => {
  if (defaultValue) {
    const blocksFromHtml = htmlToDraft(defaultValue)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    return EditorState.createWithContent(contentState)
  } else {
    return EditorState.createEmpty()
  }
}

// https://stackoverflow.com/questions/68889802/react-draft-wysiwyg-default-value-style-doesnt-load

const TextEditor = ({ defaultValue, onChange, onBlur }) => {
  const [editorState, setEditorState] = useState()
  const [defaultValueState, setdefaultValueState] = useState()

  useEffect(() => {
    if (defaultValue) {
      const initialState = getInitialState(defaultValue)
      onEditorDefaultStateChange(initialState)
    }
  }, [onEditorDefaultStateChange, defaultValue])

  const onEditorDefaultStateChange = useCallback(
    (editorState) => {
      setdefaultValueState(editorState)
      return onChange(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    },
    [onChange]
  )

  const onEditorStateChange = useCallback(
    (editorState) => {
      setEditorState(editorState)
      return onChange(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    },
    [onChange]
  )

  // className={styContainer}
  return (
    <div >
      <Editor
        editorStyle={{ border: "1px solid #606060", paddingLeft:"10px" }}
        stripPastedStyles={true}
        editorState={editorState ? editorState : defaultValueState}
        toolbar={toolbar}
        onEditorStateChange={onEditorStateChange}
        onBlur={onBlur}
      />
    </div>
  )
}

// wrapperClassName={styWrapper}
// toolbarClassName={styToolbar}
// editorClassName={styEditor}


TextEditor.propTypes = {}

TextEditor.defaultProps = {}

export default TextEditor