import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from './img/FriCo-logo.svg'; 
// https://www.npmjs.com/package/simple-react-validator
import SimpleReactValidator from "simple-react-validator"
// https://material-ui.com/api/modal/
import { Button, Container, Grid, Link, Modal, Paper, Typography } from '@mui/material';
import firebase from "../../config/firebase"
import { saveUserProfileStateTemp, authInfoState, uiSetup } from '../../config/userProfile'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link as RouterLink } from 'react-router-dom'
import { useCallback } from 'react';

import { evaluateRecaptcha } from './../../config/helpers'
import GoogleReCaptchaLegal from '../../config/GoogleReCaptchaLegal';

// https://openbase.com/js/react-google-recaptcha-v3/documentation
// https://www.npmjs.com/package/react-google-recaptcha-v3
// https://github.com/t49tran/react-google-recaptcha-v3

const SignUp = (props) => {

  const initialLoginState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    captchaError: false,
    disableSubmit: true,
    modalOpen: false,
  }

  const navigate = useNavigate();
  const [userProfileError, setUserProfileError] = useState('');
  const [loginState, setLoginState] = useState(initialLoginState);
    
  const simpleValidator = useRef(new SimpleReactValidator());

  const handleSubmit = async () => {
    console.log("Handling")
    if(formRef.current.isFormValid()){
      // 1. create new user
      // 2. send email verification
      // 3. save details to firestore temp document
      firebase.auth().createUserWithEmailAndPassword( loginState.email, loginState.password )
      .then( (resp) => {
        saveUserProfileStateTemp(resp.user,loginState);
        firebase.auth().currentUser.sendEmailVerification()
        navigate("/emailverification");
      })
      .catch((err)=>{
        setUserProfileError(String(err));
      });
    } else {
      setUserProfileError('Virheilmoitus: lomakkeen tiedot eivät kelpaa.');
    }
  }


  // =======================================================

  const formRef = useRef(null);

  const submitIsDisabled = () => {
    return false && !(loginState.email && loginState.password && loginState.firstName  && loginState.lastName && !loginState.captchaError);
  }
  
  const handleModalClose = () =>{
    setLoginState({...loginState, modalOpen : false});
  }

  const blur = (e) => {
    simpleValidator.current.showMessageFor(e.target.id);
  }

  const handleChange = (e) => {
    setLoginState({
      ...loginState, 
      [e.target.id]: e.target.value
    })
  }


  // const handleVerify = (token) => {
  // don't do repeated checks with expired tokens
  //   if(loginState.disableSubmit === true){
  //     return;
  //   }
  //   const baseURL = "https://us-central1-itupeli.cloudfunctions.net/ituCheckRecaptcha";
  //   axios.get(baseURL,{ params: { 'response': token } }).then((response)=>reportResponse(response) );
  //}
  
  // form validations
  // https://github.com/NewOldMax/react-material-ui-form-validator
  // https://github.com/NewOldMax/react-form-validator-core
  // password checks
  // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

  
  return uiSetup("SignUp") && (
    <div>
      <div >
        <Modal  open={loginState.modalOpen} onClose={handleModalClose}>
          <div className="center modal-size-signup">
            <h4 className='blue-text' >Lomakkeen tiedoissa on puutteita.</h4><br />
            <button className="btn z-depth-0" onClick={handleModalClose} >Sulje</button> </div>
        </Modal>
      </div>    
      
      <ValidatorForm onSubmit={handleSubmit} debounceTime={1000} onError={errors => console.log()} ref={formRef} >
        <Container fixed={true} maxWidth="xs" style={{paddingTop: "50px"}}>
          <Paper>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" style={{width:"85%"}}>
                <img src={logo} alt="itu logo"/>
              </Grid >
              <Grid item align="center" >
                <Typography variant="h5" >Luo uusi tili:</Typography>
              </Grid >
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="email" name="email" id='email' label="sähköpostiosoite" variant="outlined" fullWidth onChange={handleChange} value={loginState.email} 
                  validators={['required', 'isEmail']}
                  errorMessages={['sähköpostiosoite on pakollinen tieto', 'sähköpostiosoite ei kelpaa']}
                  />
              </Grid >
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="password" autoComplete="new-password" name='new-password' id='password' label="salasana" variant="outlined" fullWidth onChange={handleChange} value={loginState.password} 
                   validators={['required','matchRegexp:^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})' ]} 
                  //validators={['required','matchRegexp:(?=.{2,})' ]} 
                  errorMessages={['salasana on pakollinen tieto','minimi: 8 merkkiä kirjaimia ja numeroita']}
                  />
              </Grid >
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="given-name" id='firstName' label="etunimi" variant="outlined" fullWidth onChange={handleChange} value={loginState.firstName}
                    validators={['minStringLength:2']}
                    errorMessages={['minimipituus on kaksi merkkiä',]}
                    />
              </Grid >
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="family-name" id='lastName' label="sukunimi" variant="outlined" fullWidth onChange={handleChange} value={loginState.lastName}
                  validators={['minStringLength:2']}
                  errorMessages={['minimipituus on kaksi merkkiä']}
                    />
              </Grid >

              <Grid item align="center" >
                <Button 
                  type="submit"
                  size="medium"
                  variant="contained" 
                  color="primary" 
                  onClick={async (e)=>{
                    e.preventDefault();
                    evaluateRecaptcha(
                    handleSubmit, 
                    (score) => setUserProfileError('Virheilmoitus: lomakkeen tiedot eivät kelpaa (reCaptcha score: '+score+').'));
                  }}
                >
                Luo tili
                </Button>
              </Grid >
              <Grid item color="error" align="center" style={{ width: '80%' }}>
                    <Typography color="error" >
                      { loginState.captchaError ? <span>'Ole hyvä ja lataa sivu uudelleen.'</span> : '' }
                      { userProfileError ? <span>{userProfileError}<br /><br /></span> : '' }
                    </Typography>
              </Grid >
              <Grid item align="center" style={{ width: '80%' }}>
              <Typography  component="div"  >
                  <p>Joko sinulla on tili? <Link href="#" component={RouterLink} to="/signin"  >Kirjaudu sisään</Link></p> 
                  <p><Link href="https://www.ahaavision.com"  target="_blank" >www.ahaavision.com</Link></p>
              </ Typography>                  
              <GoogleReCaptchaLegal/>
              <br />
            </Grid >
            </Grid>
          </Paper>
        </Container>
      </ValidatorForm>
    </div>
  );
}
export default SignUp
