import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system';
import React, { useContext } from 'react'
import { CardDeck } from '../cardDeck/CardDeck';
import { FricoContext } from '../../config/FricoProvider';

import defaultLobbyImage from "./../../assets/bg-images/lobby-1920.jpg";
import defaultLobbyImage2 from "./../../assets/bg-images/lobby-1920-2.jpg";

// https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
export default function Lobby() {

    const frico = useContext(FricoContext);       
    const useStyles = makeStyles( (theme) => {
        return { 
            gameRoot: {
                spacing: 0,
                justify: 'space-around',
                backgroundColor: "#f9f9f9",
                overflow: "hidden"
            },
        }
    })
    const classes = useStyles()

    if(frico.session ) {
        frico.session.gameState.started = true;
        frico.sessionSave();
    } 

    if (!frico.session) return null;        

    return (<>
        <Box className={classes.gameRoot} style={{width:"100vw",height:"100vh", overflow:"hidden", position:"relative"}} >
            <img 
                style={{
                    position:"absolute", 
                    width: "100vw",  
                    height: "100vh", 
                    objectFit: "cover",
                }}
                src={defaultLobbyImage2}
            />

            <div className={"cardDeckDiv"} style={{overflow:"hidden", }}>
                <CardDeck  frico={frico} />
            </div>
        </Box>
                    
    </>)

}
