import { Alert, Box, Button, Divider, Snackbar, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import firebase from "../../../config/firebase";

import { logUserBilling } from '../../../config/logging';

const db=firebase.firestore();
  
function Premium({userDetails, control, errors, setValue, handleSubmit }) {
    const [open49, setOpen49]=useState(false);
    const [open490, setOpen490]=useState(false);
    const [snackOpen, setSnackOpen]=useState(false);
    
    useEffect(() => {
        moment.locale('fi')
        }, [])
        
    //Difference in number of days        
    const given   = moment(userDetails.premiumEnds).startOf('day');
    const current = moment().startOf('day');
    const daysLeft= moment.duration(given.diff(current)).asDays().toFixed(0);
    let daysString ='';
    if(daysLeft>1) daysString="peliaikaa on jäljellä "+daysLeft+" päivää";
    if(daysLeft==1) daysString="peliaikaa on jäljellä yksi päivä";
    if(daysLeft<1) daysString="peliaikasi on päättynyt";

    return (
        <Box sx={{width:"100%", }} >
            <Typography component="div" variant="h5" sx={{marginBottom:"20px"}}>
                Peliaikasi päättymispäivä:
            </Typography>
            <Typography component="div" variant="h5" sx={{marginBottom:"20px", textAlign:"center",}} >
                {moment(userDetails.premiumEnds).format("DD.MM.YYYY")} ({daysString})
            </Typography>

            <Typography component="div" variant="h5" sx={{marginBottom:"20px"}}>
                Osta lisää peliaikaa:
            </Typography>
            <Stack direction="row" sx={{width:"100%",textAlign:"center"}}>
                <Button 
                    sx={{marginRight:"15px"}}
                    variant="contained"
                    size="large"
                    onClick={()=>{
                            setOpen49(true)
                        } } 
                    >
                    49 €/kk + alv
                </Button> 
                <Button 
                    sx={{marginRight:"15px"}}
                    variant="contained"
                    size="large"
                    onClick={()=>{
                        setOpen490(true)
                        } } 
                    >
                    490 €/vuosi + alv
                </Button> 
                <Button 
                    sx={{marginRight:"15px"}}
                    variant="contained"
                    size="large"
                    onClick={()=>{
                            setValue("premiumEnds", moment().valueOf())
                            handleSubmit();
                        } } 
                    >
                    reset
                </Button> 


            </Stack>
            <Typography component="div" variant="h5" sx={{marginBottom:"20px",marginTop:"40px"}}>
                Laskutuskäytäntö
            </Typography>
            <Typography variant="normal" sx={{marginBottom:"20px"}}>
                Klikkaa valitsemaasi laskutusvaihtoehtoa, jolloin ohjelma pyytää sinua vielä vahvistamaan valintasi. Kirjoita avautuvaan tekstikenttään
                joko "maksan 49" tai "maksan 490" riippuen tekemästäsi laskutusvaihtoehdosta ja klikkaa OK. Tämän jälkeen saat pelialustan toiminnot heti käyttöösi. 
                Huomaathan, että verolliset laskutettavat hinnat ovat 60.76 €/kk ja 607.60 €/vuosi. Tilaus ei jatku automaattisesti. 
                Tietosi säilyvät järjestelmässä 12 kk maksetun peliajan päättymisen jälkeen.
            </Typography>
            <Divider sx={{marginBottom:"20px",marginTop:"20px"}}/>
            <Typography variant="normal" sx={{marginBottom:"20px"}}>
                Jos sinulla on vanhaa pelioikeutta jäljellä, jatkuu pelioikeutesi nykyinen pelioikeusjaksosi päättymispäivästä lukien kuukaudella / vuodella. 
                Jos sinulla ei ole tällä hetkellä pelioikeutta, alkaa ostamasi pelioikeusjakso ostohetkestä. 
            </Typography>
            <Divider sx={{marginBottom:"20px",marginTop:"20px"}}/>
            <Typography variant="normal" sx={{marginBottom:"20px"}}>
                Laskutamme kertyneet maksut neljästi vuodessa aina helmi-, touko-, elo- ja marraskuun alussa. Pidä laskutustiedot-välilehti
                ajan tasalla, niin saat laskut haluamassasi muodossa (email/verkkolasku). Laskutustasi voit seurata laskutustiedot-välilehdellä.
            </Typography>
            <Divider sx={{marginBottom:"20px",marginTop:"20px"}}/>
            <FormDialog 
                open={open49} 
                setOpen={setOpen49} 
                question="Ostan kuukauden lisää käyttöaikaa" 
                challenge="maksan 49" 
                success={()=>{
                        logUserBilling({euros:49});
                        setValue("premiumEnds", moment(userDetails.premiumEnds).add('month', 1).valueOf())
                        handleSubmit();
                        setSnackOpen(true);
                    }} 
                />
            <FormDialog 
                open={open490} 
                setOpen={setOpen490} 
                question="Ostan vuoden lisää käyttöaikaa" 
                challenge="maksan 490" 
                success={()=>{
                        logUserBilling({euros:490});
                        setValue("premiumEnds", moment(userDetails.premiumEnds).add('year', 1).valueOf())
                        handleSubmit();
                        setSnackOpen(true);
                    }} 
                />

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"center" }}
                autoHideDuration={8000}
                onClose={() => setSnackOpen(false)}
                open={snackOpen}
            >
                <Alert 
                    onClose={()=>setSnackOpen(false)} 
                    severity="success" 
                    variant="filled"
                    sx={{ width: '100%' }}>
                        Tilauksesi onnistui, peliaikaasi on jatkettu.
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Premium


export function FormDialog({open, setOpen, question, challenge, success}) {

  const [isOK, setIsOK]= useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{question}</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Vahvistaaksesi ostoksesi, kirjoita alla olevaan kenttään "{challenge}" ilman lainausmerkkejä:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={challenge}
            type="email"
            fullWidth
            variant="standard"
            onChange={(e)=>{
                if(e.target.value==challenge){
                    setIsOK(true)
                }else{
                    setIsOK(false)
                }}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Peruuta</Button>
          <Button onClick={()=>{
                    success();
                    handleClose();
                    }
              } disabled={!isOK}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

