import React, { useContext, useState, useEffect, useRef } from 'react'
import { FricoContext } from '../../config/FricoProvider';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from "react-spring";

import rockScene_00 from "../../assets/rocks/rocks-00.png";
import rockScene_01 from "../../assets/rocks/rocks-01.png";
import rockScene_02 from "../../assets/rocks/rocks-02.png";
import rockScene_03 from "../../assets/rocks/rocks-03.png";
import rockScene_04 from "../../assets/rocks/rocks-04.png";
import rockScene_05 from "../../assets/rocks/rocks-05.png";
import rockScene_06 from "../../assets/rocks/rocks-06.png";
import rockScene_07 from "../../assets/rocks/rocks-07.png";
import rockScene_08 from "../../assets/rocks/rocks-08.png";

import cardBg from "../../assets/cards/card-text.svg";

import { useWindowDimensions } from '../../config/helpers';
import { Box, Typography } from '@mui/material';

// consider
// https://github.com/nitin42/redocx/issues/29
// https://github.com/nitin42/redocx
// https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
export default function Rocks() {

    const frico = useContext(FricoContext);  
    const navigate = useNavigate();
    const {width, height} = useWindowDimensions(); 
    useEffect(()=>{
        !frico.session && navigate('/');
    },[]);

    if(frico.session ) {
        frico.session.gameState.started = true;
    } 
    if(!frico.session ) return null;
    return <Canvas frico={frico}/>
}

// numbers are the same as rock numbers, 00 = background image
// all images must be the SAME PIXEL SIZE !
const images = [
  { src: rockScene_01 },
  { src: rockScene_02 },
  { src: rockScene_03 },
  { src: rockScene_04 },
  { src: rockScene_05 },
  { src: rockScene_06 },
  { src: rockScene_07 },
  { src: rockScene_08 },
  { src: rockScene_00 },      
];

function Canvas({frico}) {

  const [cardVisible, setCardVisible] = useState(false);
  const [isAnimatingRock, setIsAnimatingRock] = useState(false);
  const [isAnimatingCard, setIsAnimatingCard] = useState(false);
  const [cardText, setCardText] = useState("")
  const [cardImage, setCardImage] = useState("")

  const canvasRefs = images.map(() => useRef(null));
  const canvasAnimationRefs = useRef([]);

  canvasAnimationRefs.current = images.map((image,index) => {
        const [springs, api] = useSpring(() => ({
                                                    from: { 
                                                            x: "0vw",
                                                            y: 0, 
                                                            opacity: 1,
                                                            transform: "scale(1)",
                                                          },
                                                    to: { y: 0, opacity: 1, },
                                                    config: { tension: 100, friction: 4},
                                                    onRest: {
                                                        opacity: () => {
                                                                            setIsAnimatingRock(false);
                                                                            // this will reset selected rock zIndex to its original value
                                                                            canvasRefs[index].current.style.zIndex = images.length-index;
                                                                            frico.session.gameState.rocks.openedRocks[index]=true;
                                                                            frico.sessionSave();
                                                                            //console.log(">>> opacity Animation stopped...");
                                                                        },
                                                        y: () => {
                                                                            // this will reset selected rock zIndex to its original value
                                                                            setCardVisible(true);
                                                                            setIsAnimatingCard(true);
                                                                            console.log(">>> X Animation stopped...");
                                                                        },

                                                      },
                                        
                                                }),            
                                                []
                                        )
        return {springs, api}
    }
  );

  const {width:scWidth, height:scHeight} = useWindowDimensions(); 
  const iWidthRef = useRef(1);
  const iHeightRef= useRef(1);
    
  useEffect(() => {
    canvasRefs.forEach((canvasRef, index) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = images[index].src;
      image.onload = () => {
        iWidthRef.current = image.width;
        iHeightRef.current = image.height;
        canvas.width = iWidthRef.current;
        canvas.height = iHeightRef.current;
        ctx.drawImage(image, 0, 0);
      };
    });
  }, [scWidth, scHeight]);

  //
  // image.length = 9
  // 
  // ROCK 01 zIndex 9; image array index = 0
  // ROCK 02 zIndex 8; image array index = 1
  // ROCK 03 zIndex 7; image array index = 2
  // ROCK 04 zIndex 6; image array index = 3
  // ROCK 05 zIndex 5; image array index = 4
  // ROCK 06 zIndex 4; image array index = 5
  // ROCK 07 zIndex 3; image array index = 6
  // ROCK 08 zIndex 2; image array index = 7
  // BG      zIndex 1; image array index = 8
  //
  
  // fiddle values to enlarged rock images
  //                1        2       3      4       5      6        7        8        9 (BG dummy value needed)
  const toYArray = [0.7,     0.6,    0.4,   0.2,    0.1,   -0.2,    -0.3,    -0.4,    0,];
  const toXArray = ["-20vw", "20vw", "0vw", "30vw", "0vw", "-15vw", "-20vw", "-10vw", "0vw",];
  
  const handleClick = (event) => {
    //console.log("Clic-handler ");
    const target = event.target;
    setCardVisible(false);

    if(target.nodeName != 'CANVAS') {
        console.error("Invalid element!");
    }

    const index=images.length - target.style.zIndex;
    const belowTarget = (index>-1 && index<8)?canvasRefs[index + 1].current:null;

    console.log("target.style.zIndex ", target.style.zIndex);
    console.log("images.length ", images.length);
    console.log("index: ", index);

    // get the correctly scaled coordinates to the canvas image, find out if transparent
    const x = event.nativeEvent.offsetX / scWidth * iWidthRef.current ;
    const y = event.nativeEvent.offsetY / scHeight * iHeightRef.current ;
    const pixel = target.getContext('2d').getImageData(x, y, 1, 1).data;
    
    if ( target.style.zIndex>1 && pixel[3] !== 0 ) {
        // Clicked on a non-transparent pixel above BG
        
        // do nothing if animation is ongoing....
        if(isAnimatingRock) return;

        // this will set the selected rock zIndex to value of 10, if front of everything
        canvasRefs[index].current.style.zIndex = 11;
        canvasRefs[index].current.style.pointerEvents = "none";
        
        // select the correct y-position fiddle
        const toY = -scHeight*toYArray[index];

        setIsAnimatingRock(true);
        setCardText(frico.session.gameState.rocks.texts[index])
        setCardImage(frico.session.gameState.rocks.images[index])

        console.log(">>> Animation started...");
        canvasAnimationRefs.current[index].api.start({
            to: [
                { x: toXArray[index], y:toY, transform: "scale(2)", opacity:1},
                { x: "200vw", y:toY,  opacity:1},
                { y:toY, opacity:0.99},
              ],
              config: key => {
                if (key === 'y') {
                  return {
                    friction: 4,
                  }
                }
                if (key === 'x') {
                    return {
                      friction: 40,
                      tension: 200,
                    }
                  }
                // make opacity animation quick
                if (key === 'opacity') {
                    return {
                      friction: 40,
                      tension: 2000,
                    }
                  }
                return {}
              },
          })

        console.log("Clicked on Rock: ", index+1);

    } else if ( target.style.zIndex>1 && pixel[3] === 0  && belowTarget && belowTarget.nodeName === 'CANVAS'){
        // Clicked on a transparent pixel above BG, we must propagate click downwards
        belowTarget.dispatchEvent(new MouseEvent('click', event));
    } else if (target.style.zIndex == 1 ){
        // Click event propagated onto BG
        console.log("Clicked bacground!");
    }
  }

  return (
    <>
      {images.map((image, index) => (
            <animated.canvas
            key={index} 
            ref={canvasRefs[index]} 
            style={{ 
                left: !frico.session.gameState.rocks.openedRocks[index]?"0px":"20000px",
                ...canvasAnimationRefs.current[index].springs,
                zIndex: images.length - index, 
                position: "fixed",  
                width: "100vw",  height: "100vh", 
                cursor:"pointer",
            }} 
            onClick={(e)=>{
                handleClick(e);
            }}
        />
            
      ))}
      <QCard 
            cardVisible={cardVisible} 
            setCardVisible={setCardVisible} 
            setIsAnimatingCard={setIsAnimatingCard} 
            isAnimatingCard={isAnimatingCard}
            isAnimatingRock={isAnimatingRock}
            cardText={cardText}
            cardImage={cardImage}
            />
    </>
  );
}

function QCard({cardVisible=false, setCardVisible, isAnimatingCard, setIsAnimatingCard, isAnimatingRock, cardText, cardImage}) {

    const AnimatedBox = animated(Box);
    const cardAnimationRef = useRef(null);

    const [springs, api] = useSpring(() => ({
            from: {
                    opacity: 1,
                    transform: "scale(0.1) rotate(180deg)",
                    x: "0vw", 
                  },
            to:   { 
                    opacity: 1,
                    transform: "scale(0.1)  rotate(180deg)",
                    x: "0vw", 
                  },
            config: { tension: 5, friction: 3},
            onRest: {
                x: () => {
                            // this will reset selected rock zIndex to its original value
                            setCardVisible(false);
                            //console.log("#### Card Animation stopped...");
                        },
        },

        }),            
        []
    )
    cardAnimationRef.current = {springs, api}
    //cardVisible && isAnimatingRock && console.log("#### Card Animation started...");
    cardVisible && isAnimatingRock && cardAnimationRef.current.api.start({
            from: {
                opacity: 1,
                transform: "scale(0.1)  rotate(180deg)",
                x: "0vw", 
            },

            to: { 
                    opacity:1,
                    transform: "scale(0.8) rotate(355deg)",                     
                    x: "0vw", 
            },

            config: key => {
                if (key === 'transform') {
                  return {
                    friction: 2,
                    tension: 5,
                  }
                }
                return {}
              },
          });

    const onCardClick = () => {
        cardAnimationRef.current.api.start({
            to:  { 
                    opacity:1,
                    transform: "scale(0.8) rotate(450deg)",                     
                    x: "200vw", 
                 },
                
            config: key => {
                if (key === 'x') {
                  return {
                    friction: 4,
                    tension: 5,
                  }
                }
                return {}
              },

          });
        console.log("Card clicked  ")
    }

    return (<AnimatedBox
                        style={{
                            ...cardAnimationRef.current.springs, 
                            visibility: cardVisible?"visible":"hidden",
                            position: "fixed",
                            zIndex: cardVisible?10:0,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            cursor: "pointer",
                            aspectRatio: "16 / 9",
                        }}
                >
                <Box
                    component="img"
                    style={{
                        position: "absolute",
                        height: '100%',
                        width: '100%',
                    }}
                    src={cardImage}
                    onClick={onCardClick}
                />

                <p 
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                        fontSize: (1.5- ((cardText.length<50)?0:Math.min((cardText.length-50)*0.007,0.9) ))*4 +"vw", 
                        lineHeight: 1.2,
                        position: "relative",
                        padding:"1vw",
                        paddingBottom:"2vw",
                        transformOrigin: "center center",
                        pointerEvents: "none",
                        }}
                        dangerouslySetInnerHTML={{__html: cardText+'<br><br><br>'}}

                    />

            </AnimatedBox>)
}