import React from 'react'
import { createStyles, makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { Delete, FileCopy, PlayArrow, } from '@mui/icons-material/';
import { CardActionArea, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks'
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import useFirestoreCol from '../../config/useFirestoreCol';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { newSession } from '../session/Session';

import { SvgIcon } from '@mui/material';
import { ReactComponent as TestRunIcon } from "../../assets/icons/TestRunIcon.svg"
import { ReactComponent as TestLinkIcon } from "../../assets/icons/TestLinkIcon.svg"

const useStyles = makeStyles((theme) => createStyles({
        root: {
            maxWidth: 545,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    })
  );
  

export default function GameCard({doc}) {

    const classes = useStyles();
    const popupState = usePopupState({ variant: 'popover', popupId: 'cardActionMenu' })
    const navigate = useNavigate();
    const { deleteDoc, duplicateDoc } = useFirestoreCol("games");

    const game = doc;

    const handleDelete = (id) => {
        deleteDoc(id);
        popupState.close();
    }

    const handleEdit = (id) => {
        navigate('/editgame/'+id);
    }

    const handleCopy = (id) => {
        duplicateDoc(id);
        popupState.close();
    }

    const handleNewSession = (id) => {
            const players = {'playerid1-tobedefined':'playerid1-tobedefined','playerid2-tobedefined':'playerid2-tobedefined','playerid3-tobedefined':'playerid3-tobedefined'};
            newSession({
                gameId:id,
                gameName: game.name,
                players: players
            });
            navigate('/sessionlist')
            popupState.close();
    }

    return (
        <Card className={classes.root} >
            <CardHeader
                avatar={
                <Avatar aria-label="avatar" sx={{ bgcolor: red[500] }}>
                    R
                </Avatar>
                }
                action={
                    //https://jcoreio.github.io/material-ui-popup-state/
                    <div>
                        <Tooltip title="Poista peli" placement="top" arrow >                                            
                            <IconButton {...bindTrigger(popupState)} >
                                <Delete />
                            </IconButton>
                        </Tooltip>                    
                        <Menu
                            {...bindMenu(popupState)} 
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            >
                            <MenuItem onClick={()=>handleDelete(doc.id)} >Poista peli</MenuItem>
                        </Menu>
                    </div>                    
                }
                title={game.name}
                subheader={game.created}
            />
            <CardActionArea onClick={()=>handleEdit(doc.id)}>
                <CardMedia
                    className={classes.media}
                    image={doc["startImage"]}
                    title={game.name}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">{game.description}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                <Tooltip title="Muokkaa peliä" placement="top" arrow>
                    <IconButton aria-label="edit" onClick={()=>handleEdit(doc.id)}>
                        <FiEdit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Luo kopio pelistä " placement="top" arrow>            
                    <IconButton aria-label="duplicate" onClick={()=>handleCopy(doc.id)}>
                        <FileCopy />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Luo uusi pelituokio" placement="top" arrow>                        
                    <IconButton aria-label="play game" onClick={()=>handleNewSession(doc.id)}>
                        <GroupAddIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Kokeile peliä ohjaajan näkymässä" placement="top" arrow>                        
                    <IconButton sx={{marginLeft: "auto"}}  aria-label="play game" onClick={()=>{}}>
                        <SvgIcon className={classes.mySvgStyle}>
                            <TestRunIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Lähetä osallistujan linkki itsellesi kokeiltavaksi" placement="top" arrow>                        
                        <IconButton aria-label="play game" onClick={()=>{}}>
                            <SvgIcon className={classes.mySvgStyle}>
                                <TestLinkIcon/>
                            </SvgIcon>
                        </IconButton>
                </Tooltip>
                <div sx={{display: "float", float:"right"}}>                    
                </div>


            </CardActions>
        </Card>
    )
}
