import React from 'react'
import FilerobotImageEditor from 'filerobot-image-editor';
import firebase from 'firebase/compat';
import { v4 as uuidv4 } from 'uuid';
import newDoc from '../../../../config/newDoc';
import { getUserFileDetails } from '../../../../config/userProfile';

export default function ImageEditor({show, img, toggle, docType, handleChange}) {
    return (
        <FilerobotImageEditor
            show={show}
            src={img}
            onClose={() => { toggle(false) }}
            onComplete={props => {
                //console.log(props);
            }}
            onBeforeComplete={props => {
                    // https://github.com/scaleflex/filerobot-image-editor
                    // https://stackoverflow.com/questions/37873808/how-can-i-save-canvas-as-image-to-firebase-storage
                    // canvas.toDataURL(type, encoderOptions);
                    // {
                    //  status: 'success',
                    //   imageMime: 'image/png', 
                    //   imageName: 'card-front-05.png', 
                    //   canvas: canvas#null_filerobot-image-edit-box-original.sc-fzoNJl.vSrKh.filerobot-original-canvas
                    //  }
                    //  var storageRef = firebase.storage().ref();
                    //  canvas.toBlob(function(blob){
                    //    var image = new Image();
                    //    image.src = blob;
                    //    var uploadTask = storageRef.child('images/' + "apple").put(blob);
                    //  }); 
                    //
                    // https://github.com/scaleflex/filerobot-image-editor/blob/master/src/assets/i18n/en.js
                    // 
                    // var storageRef = firebase.storage().ref(); 

                    var storageRef = firebase.storage().ref();
                    props.canvas.toBlob(async function(blob){
                                var image = new Image();
                                image.src = blob;
                                const fuuid=uuidv4();
                                const name = '/itu/testing-ahaa/userdata/'+firebase.auth().currentUser.uid + "/"+fuuid;
                                var uploadTask = storageRef.child(name).put(blob);
                                uploadTask.then(async ()=>{
                                        const url = await storageRef.child(name).getDownloadURL()                                   
                                        console.log("url",url);
                                        newDoc("images",{data:{... await getUserFileDetails(), fileName:fuuid, url, storageName:name}, docType })
                                        handleChange(url); // this will do what ever needed in upper level Comp, modify the Image on web Tab form, say
                                    })
                            }); 
                        //console.log(props);
                    return false; // disable download
                }
            }

            config={{ translations: 
            { en: { 
                'toolbar.download': 'Tallenna muutokset', 
                'header.image_editor_title': 'FriCo-vuorovaikutuspeli kuvaeditori', 
                },
            },
            'noCapitalStrs': true,
            'cropPresets':  [
                { name: 'original', value: 0 },
                { name: 'square', value: 1 },
                { name: '16 : 9', value: 1.7777 },
                { name: '4 : 3', value: 1.33333 },
            ],
            'resizePresets': [
                { name: 'big square', width: 600, height: 600, ratio: 1 },
                { name: 'middle square', width: 400, height: 400, ratio: 1 },
                { name: 'small size', width: 1200, height: 675, ratio: 1.7777 },
                { name: 'better quality', width: 1920, height: 1080, ratio: 1.7777 },
            ],
            replaceCloseWithBackButton: true,
            colorScheme: 'dark',
            }}
        />
     )
}
