import React from 'react'
import { makeStyles } from '@mui/styles';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar'
import { uiSetup } from '../../config/userProfile'; 
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import LeftDrawer from './LeftDrawer'
import { useAuth } from '../../config/AuthProvider';
import { Outlet } from 'react-router';

const drawerWidth = 240

const useStyles = makeStyles((theme) => {
  return {
    page: {
      paddingLeft: drawerWidth,
      paddingTop: "70px",
      width: '100%',
      padding: theme.spacing(3),
      backgroundColor:"#f0f4f7",
      backgroundColor:"#fffff",
      height:"100vh",
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    appBar: {
      width: `calc(100% -  drawerWidth)px)`,
      marginLeft: drawerWidth,
    },
    date: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    avatar: {
      marginLeft: theme.spacing(2)
    }
  }
})

export default function DashboardLayout({ children }) {

  const classes = useStyles()
  const {currentUser} = useAuth();

  // lightblue "#e3f2fd"
  return (
    <React.Fragment >
        
      <AppBar className={classes.appBar} position="fixed" elevation={1}   style={{ backgroundColor: "#FFFFFF" }} >
        <Toolbar>
            { uiSetup("SignedInLinks")  && <SignedInLinks /> }
            { uiSetup("SignedOutLinks") && <SignedOutLinks /> }
        </Toolbar>
      </AppBar>
      <LeftDrawer  drawerWidth={drawerWidth} /> 

      <div className={classes.page}>
          <Outlet />
      </div>
        
    </React.Fragment>
  )
}
