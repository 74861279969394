import { Box, Grid, TextField, Typography } from '@mui/material'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import ImageCard from '../../imageCard/imageCard';

import moment from "moment";
import 'moment/locale/fi';
// old style
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import { MobileDateTimePicker } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// https://www.npmjs.com/package/@mui/lab

export default function BasicInfo( { control, formState }) {
 
    const { errors, isDirty, isSubmitting, touchedFields, submitCount } = formState;

    const options = [
      {
        label: "suomi",
        value: "fi",
      },
      {
        label: "englanti",
        value: "en",
      },
    ];
    const generateSelectOptions = () => {
        return options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        });
      };
   

  return (
    <Grid container alignContent={'center'} spacing={2}  >

    <Grid item xs={12}  >
        <Controller 
          name="name" 
          defaultValue=""
          control={control} 
          render={({ field })=> (
                <TextField
                  required
                  {...field}
                  label="FriCo-vuorovaikutuspelin nimi:"
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message:""}
                  margin="dense"
                  fullWidth
              />
            )}
          />
    </Grid>

    <Grid item xs={12}  >
        <FImage 
                type="image"
                docType="frico-main-image"
                name="seoImage"
                label="Kuva"
                control={control}
            />

    </Grid>

    <Grid item xs={12}  >
        <FDate
                type="string"
                name="startsAt"
                label="Pelituokio alkaa"
                control={control}
                error={!!errors.startsAt}
                helperText={errors.startsAt ? "Valitse sekä päivämäärä että aika klikkaamalla valintaikkunasta ja klikkaa lopuksi OK.":""}
            />
    </Grid>

    <Grid item xs={12}  >
        <FDate
                type="string"
                name="endsAt"
                label="Pelituokio päättyy"
                control={control}
                error={!!errors.endsAt}
                helperText={errors.endsAt ? "Valitse sekä päivämäärä että aika klikkaamalla valintaikkunasta ja klikkaa lopuksi OK.":""}
            />
    </Grid>

    { false && <Grid item xs={12}  >
          <Controller 
            name="sessionLanguage" 
            control={control} 
            render={({ field })=> {
                if(!field.value || !field.name) return null;  
                console.log("field",field)
                return (<>
                  <TextField
                    sx={{width:"300px"}}
                    defaultValue="fi"
                    select
                    value={field.value}
                    onChange={(e)=>{
                        field.onChange(e.target.value);
                        triggerInputChange(field.name, e.target.value)
                      }}
                    label="FriCo-pelissä käytettävä kieli:"
                    variant="standard"
                    fullWidth
                  >
                    {generateSelectOptions()}
                  </TextField>
                  <TextField
                          style={{visibility:"hidden", height:0, padding:0, margin:0}}
                          id="sessionLanguage"
                          value={field.value}
                          margin="dense"
                          fullWidth
                      />
                </>)
              }
            } 
          />
      </Grid>
    }

  </Grid>             
  )
}


export function FImage( {control, errors, label, id, name, docType, xyRatio='56.25%', ...props}) {

  return (
    <Controller 
        name={name}
        defaultValue=""
        control={control} 
        render={({ field })=> { 
                  //const [url, setUrl] = React.useState(field.value);
                  if(!field.value) return null;                    
                  return (<> 
                      <ImageCard 
                          xyRatio={xyRatio}
                          img={field.value} 
                          docType={docType}
                          notext={true}
                          handleChange={(url)=>{
                              // store in react-hook-form field
                              field.onChange(url);
                              // need to trigger Input change, otherwise <form onChange> does not work
                              triggerInputChange(field.name, url)
                          }}
                          />
                      <TextField
                          style={{visibility:"hidden", height:0, padding:0, margin:0}}
                          id={field.name}
                          value={field.value}
                          margin="dense"
                          fullWidth
                      />
                  </>)
          }
        }
    />
)
}



export function FDate({control, error, helperText, type, name, label}) {
  // inputFormat = "EEEEEE dd.MM.yyyy', klo' HH:mm" 
  // moment    https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
  // API:      https://mui.com/x/api/date-pickers/desktop-date-time-picker/
  // adapters: https://mui.com/x/react-date-pickers/getting-started/
  useEffect(() => {
    moment.locale('fi')
  }, [])

  return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              name={name}
              control={control}
              defaultValue=""
              render={({
                field,
                fieldState: { error, invalid }
              }) => (
                <MobileDateTimePicker
                  cancelText="Peruuta"
                  ampm={false}
                  mask="__.__.____ klo __:__"
                  showTodayButton
                  inputFormat ="DD.MM.YYYY [klo] HH:mm"
                  label={label}
                  value={field.value}
                  onChange={(value) =>{
                        // this is timestamp, stored in DB, form values and so On
                        // field.onChange(moment(value).valueOf());  
                        console.log("= ==== == == = =MOMENT", value)
                        field.onChange(moment(value).valueOf());  
                        // this is underlaying dom element holding the actual characters that you see on screen, 
                        // it must match the formatting of {inputFormat}
                        triggerInputChange(name,moment(value).format("DD.MM.YYYY [klo] HH:mm"))
                      }
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        sx={{width:"300px"}}
                        id={name}
                        variant="standard"
                        margin="dense"
                        color="primary"
                        error={error}
                        helperText={helperText}
                        {...params}
                      />
                    )
                  }}
                />
              )}
            />
          </LocalizationProvider>
  );
}

// What is the best way to trigger onchange event in react js
// https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js#46012210

const inputTypes = [
    window.HTMLInputElement,
    window.HTMLSelectElement,
    window.HTMLTextAreaElement,
];

export const triggerInputChange = (elementId, value = '') => {
    // only process the change on elements we know have a value setter in their constructor
    const node = document.getElementById(elementId);
    if ( inputTypes.indexOf(node.__proto__.constructor) >-1 ) {
        const setValue = Object.getOwnPropertyDescriptor(node.__proto__, 'value').set;
        const event = new Event('change', { bubbles: true });
        setValue.call(node, value);
        node.dispatchEvent(event);
    } else {
        console.error("Unknown inputType, supported types Input, Select, Textarea");
    }
};

