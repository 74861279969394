// import { lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate, Outlet, Link } from 'react-router-dom';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import EmailVerification from './components/auth/EmailVerification';
import DashboardLayout from './components/dashboardLayout/DashboardLayout';
import { EditGame, GameList } from './components/game/Game';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SessionList from './components/session/SessionList';
import EditSession from './components/session/EditSession';
import { AuthProvider, useAuth } from './config/AuthProvider';
import { Editor } from './components/editor/Editor';
import PdfPlayer from './components/playGame/PdfPlayer';
import PlayGameLayout from './components/playGame/PlayGameLayout';
import GameStart from './components/playGame/GameStart';
import Lobby from './components/playGame/Lobby';
import Watch from './components/watch/Watch';
import GmSlider from './components/playGame/GmSlider';
import Library from './components/playGame/Library';
import Garden from './components/playGame/Garden';
import { FricoProvider } from './config/FricoProvider';
import Version from './config/Version';
import Enroll from './components/Enroll/Enroll';
import EditSessionTabs from './components/session/EditSessionTabs';
import { fiFI } from '@mui/material/locale';
import EditUserTabs from './components/user/EditUserTabs';
import Feedback from './components/Enroll/Feedback';
import Help from './helpdesk/Help';
import { useEffect } from 'react';
require('./App.css');

//$itu-blue:   #3498DB;  
//$itu-orange: #F7931E;  
//$itu-green:  #20BC0A;

const theme = createTheme({
  palette: {
    background: {
      default: "#f9f9f9"
    },
    primary: {
      main: '#3498DB',
    },
    secondary: {
      main: '#013C6D',
    },
  },
   typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
},fiFI)


import gardenScene_01 from "./assets/scenes/garden/garden-01.jpg";
import gardenScene_02 from "./assets/scenes/garden/garden-02.jpg";
import gardenScene_03 from "./assets/scenes/garden/garden-03.jpg";
import gardenScene_04 from "./assets/scenes/garden/garden-04.jpg";
import gardenScene_05 from "./assets/scenes/garden/garden-05.jpg";
import gardenScene_06 from "./assets/scenes/garden/garden-06.jpg";
const garden = [gardenScene_01, gardenScene_02, gardenScene_03, gardenScene_04, gardenScene_05, gardenScene_06, ];

import defaultStartImage from "./assets/bg-images/castle-1920.jpg";
import defaultLobbyImage from "./assets/bg-images/lobby-1920.jpg";
import Rocks from './components/playGame/Rocks';

// In any case, the README for the route-parser module has an example with multiple parameters (/users/:id/comments/:comment/rating/:rating) 
export default function App() {

  useEffect(async ()=>{
    window.FriCoGarden=[];
    window.FriBgs=[];
    garden.forEach((g) => {
      const img = new Image();
      img.src = g;
      window.FriCoGarden.push(img);
    });

    const imgStart = new Image();
    imgStart.src = defaultStartImage;
    window.FriBgs.push(imgStart);

    const imgLobby = new Image();
    imgLobby.src = defaultLobbyImage;
    window.FriBgs.push(imgLobby); 
    
  },[]);

  return (
    <ThemeProvider theme={theme} >
      <Router>
        <AuthProvider>
          <FricoProvider>

            <Routes>
              { /* this will stop also anonymous users                                                                               */}
              { /* they are authenticated BUT RequireAuth requires that they should be also listed in users collection (verified users)!    */}
              <Route element={<RequireAuthFull />}>

                <Route element={<DashboardLayout />}>

                  <Route path='/' element={<SessionList /> } />
                  
                  <Route path='/gamelist' element={<GameList /> } />
                  <Route path='/editgame/:gameid' element={<EditGame />}/> 
                  
                  <Route path='/sessionlist' element={<SessionList />} />
                  <Route path='/editsession/:sessionid' element={<EditSession />} /> 
                  <Route path='/editsessiontabs/:sessionid' element={<EditSessionTabs />} /> 
                  <Route path='/editusertabs/' element={<EditUserTabs />} /> 
                  <Route path='/help/' element={<Help />} /> 
                  
                  <Route path='/editor' element={<Editor/>} /> 

                </Route>
                
                <Route element={<PlayGameLayout />}>
                  <Route path='/gamestart/:sessionid' element={<GameStart/>} /> 
                  <Route path='/lobby/:sessionid' element={<Lobby />} /> 
                  <Route path='/library/:sessionid' element={<Library />} /> 
                  <Route path='/garden/:sessionid' element={<Garden />} /> 
                  <Route path='/rocks/:sessionid' element={<Rocks />} /> 
                  <Route path='/pdfplayer/:pdfid/:sessionid' element={<PdfPlayer />} /> 
                  <Route path='/gmslider/:sessionid' element={<GmSlider />} /> 
                </Route>

              </Route>

              <Route element={<RequireAuthEmailVerification />}>
                <Route path='/emailverification' element={<EmailVerification />} />
              </Route> 

              <Route path='/watch/:sessionid/:playerid/:gamelang' element={<Watch />} />
              <Route path='/watch/:sessionid/:playerid' element={<Watch />} />
              
              <Route path='/' element={<SignIn />} />
              <Route path='/signup' element={<SignUp />} />
              <Route path='/signin' element={<SignIn />} />
              <Route path='/enroll/:enrollid' element={<Enroll />} />
              <Route path='/feedback/:enrollid' element={<Feedback />} />

              <Route path='/version' element={ <Version />} />
              <Route path="*" element={<main style={{ padding: "1rem" }}><p>Täältä ei löytynyt mitään, ole hyvä ja tarkista osoite.</p> </main>} />

            </Routes>
          </FricoProvider>
        </AuthProvider>  
      </Router>
    </ThemeProvider>
  )
}

//<Link to={process.env.PUBLIC_URL + '/version.txt'} /> 
// https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6


function RequireAuthFull() {
  const {currentUser} = useAuth();
  const location = useLocation();
  if(currentUser && currentUser.emailVerified){
    return <Outlet />;
  }
  return <Navigate to="/signin" state={{ from: location }} />;
}  

function RequireAuthEmailVerification() {
  const {currentUser} = useAuth();
  return <Outlet />;
}  


  