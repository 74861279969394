import newDoc from '../../config/newDoc';
import { ref, update } from "firebase/database";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore"; 
import { RDB } from '../../config/datapaths'
import firebase from '../../config/firebase'
import defaultSession from './defaultSession';
import { getDefaultGameData } from '../game/defaultGames';

import { DATAROOT, DOCPATH } from '../../config/datapaths';

export async function newSession({gameId, gameName, players}) {

  const db=firebase.firestore();
  const collection = DATAROOT()+'/games';
  const gameRef =  db.collection( collection ).doc(gameId);
  gameState = await gameRef.get();
  console.log(">>>>>>>>>>>>>>>>>>> gameState.data()", gameState.data())
  const newSession = await newDoc(
      "sessions",
      {
        data:{
          ...defaultSession,
          seoImage: gameState.data().seoImage || defaultSession.seoImage,  // for historical reasons seo image is needed here                    
          gameState:gameState.data(),           // seo image lives here
          gameId,
          name:"Uusi pelituokio (perustuu peliin"+'"'+gameName+')"', 
          description: "Kirjoita pelin kuvaus" ,
        }
      }
    );

    //const sessionRef = ref(RDB, 'tmp/sessions/'+newSession.id);
    //update(sessionRef, {
    //  sessionid: newSession.id,
    //  sessionStart: "to be defined",
    //  watchComponent: "WaitSession",
    //})

  return  newSession;
}



export async function newSessionInstance(gameIndex=0) {

  const db=firebase.firestore();
  const gameState = getDefaultGameData(gameIndex);
  
  const current = new Date();
  //const date = `${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()}`;
  //const time = current.getHours() + ':' + current.getMinutes(); // + ':' + today.getSeconds();

  const date_string = "2012-01-18 16:03";
  const date = new Date();
  //const dateString = date.toLocaleString("fi", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}); // , second: "2-digit"
  const dateString = date.toLocaleString("fi", {month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}); // , second: "2-digit"

  const S4 = function() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };

  // firsd generate acceptable the enrollId
  let enrollId = S4()+S4()+S4();
  let enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll', enrollId);
  let counter = 0;
  let enroll = await getDoc(enrollRef);

  while (enroll.exists() && counter < 5) {
    counter = counter + 1 ;
    console.log(">>>>>>>>>>> counter: ", counter )
    let enrollId = S4()+S4();
    let enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll', enrollId);
    let enroll = await getDoc(enrollRef);
  }
  const name = gameState.name || "FriCo-mallipeli";
  const newSession = await newDoc(
      "sessions",
      {
        data:{
          ...defaultSession,
          seoImage: gameState.seoImage || defaultSession.seoImage,  // for historical reasons seo image is needed here                    
          enrollId,
          gameId: "--default game--",
          gameState,
          name:  name + " ("+dateString+")", 
          description: "Kirjoita pelin kuvaus" ,
          seoCanonicalUrl: "https://play.frico.app/Ilmoittaudu-mukaan-peliin/"+enrollId,
          modified: Date.now(), 
        }
      }
    );

    // const enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll', enrollId);
    setDoc( enrollRef, 
            { 
              sessionId: newSession.id,
              enrollId,
              userId: firebase.auth().currentUser.uid,
            }, 
            { merge: true }
            );

    // const sessionRef = ref(RDB, 'tmp/sessions/'+newSession.id);
    // update(sessionRef, {
    //   sessionid: newSession.id,
    //   sessionStart: "to be defined",
    //   watchComponent: "WaitSession",
    // })

  return  newSession;
}





