import Fade from '@mui/material/Fade';

import N01 from './../../assets/books/N-01.png'
import N02 from './../../assets/books/N-02.png'
import N03 from './../../assets/books/N-03.png'
import N04 from './../../assets/books/N-04.png'
import N05 from './../../assets/books/N-05.png'
import N06 from './../../assets/books/N-06.png'
import N07 from './../../assets/books/N-07.png'
import N08 from './../../assets/books/N-08.png'

// https://www.image-map.net/
const NUMBERS = [   N05, N01, N02, N06,
                    N07, N03, N04, N08, ];

export default function BookNumbers({openedBooks, showNumbers}){

    return (   
        NUMBERS.map( (png,i) => !openedBooks[i+1] && (  <Fade timeout={1500} key={i} in={showNumbers} >
                                                            <img src={png} style={{ width:"100vw", position:"absolute", zIndex:17 }}    />
                                                        </Fade>) )
                                                     )
}


