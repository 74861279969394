import React from 'react';
import useFirestoreCol from '../../../../config/useFirestoreCol';
import { motion } from 'framer-motion';
import { Card, CardActionArea, CardActions, CardMedia, Divider, Grid, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Delete, } from '@mui/icons-material';
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks'
import firebase from '../../../../config/firebase';
import { DATAROOT } from '../../../../config/datapaths';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { defaultBannerArray, defaultTaskImagesArray, defaultUserAvatarArray } from '../../../game/defaultGames';
import { v4 as uuidv4 } from 'uuid';

const ImageGrid = ({idState, handleChange, cardArray, setIdState, img, imgTag, handleClose, docType}) => {
  
  const { docs, loading } = useFirestoreCol('images', { docType, sortOrder:'desc' });
  var index = 0;
  const dummyId = "dummyImageId"
  let doDefaults = false;
  let defaultImages=[];
  if(docType=="frico-task"){
    defaultImages=defaultTaskImagesArray;
    doDefaults = true;
  }
  if(docType=="frico-main-image"){
    defaultImages=defaultBannerArray;
    doDefaults = true;
  }
  if(docType=="selfie"){
    defaultImages=defaultUserAvatarArray;
    doDefaults = true;
  }

  
  return (
    !loading && <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{marginTop:3}}>
      { docs.map(doc => ( 
        <ImgCard doc={doc} idState={idState} cardArray={cardArray} handleChange={handleChange} setIdState={setIdState} img={img} imgTag={imgTag} handleClose={handleClose} key={doc.id} />
      )) }
     { doDefaults && defaultImages.map(docUrl => ( 
        <ImgCardDefault docUrl={docUrl} idState={idState} cardArray={cardArray} handleChange={handleChange} setIdState={setIdState} img={img} imgTag={imgTag} handleClose={handleClose} key={dummyId+index++} />
     )) }

    </Grid>        
  )
}

export default ImageGrid;


function ImgCardDefault({docUrl, handleChange,  handleClose }) {

  return (
    <Grid item sx={{minWidth: 200}} >
      <Card >
        <CardActionArea >
            <motion.div className="img-wrap"
                  layout
                  whileHover={{ opacity: 1 }}
                  onClick={() => {
                      handleChange(docUrl);
                      handleClose();
                    }
                  }
                >
              <CardMedia sx={{ height: 0, paddingTop: '56.25%', }} image={docUrl} />
          </motion.div>                    
        </CardActionArea>
        <Divider sx={{paddingTop:'4px'}} />
      </Card>
    </Grid>        
  )
}


function ImgCard({doc, handleChange,  handleClose }) {

  const popupState = usePopupState({ variant: 'popover', popupId: 'deleteActionMenu' })

  function handleDelete(docid) {
    const id=docid;
    return async function(e) {
      const doc = await firebase.firestore().collection(DATAROOT()+'/images').doc(id).get();
      const image = doc.data()
      const storage = getStorage();
      const imageRef = ref(storage, image.storageName);
      // delete Storage doc and doc from Firestore images collection
      deleteObject(imageRef).then(() => {
        return firebase.firestore().collection(DATAROOT()+'/images').doc(id).delete();
      }).catch((error) => {
        console.error("Firebase image/storage delete failed:" + image.storageName+", image: " + id )
      });
      popupState.close();
    }
  }

  return (
    <Grid item sx={{minWidth: 200}} >
      <Card >
        <CardActionArea >
            <motion.div className="img-wrap"
                  layout
                  whileHover={{ opacity: 1 }}
                  onClick={() => {
                      handleChange(doc.url);
                      handleClose();
                    }
                  }
                >
              <CardMedia sx={{ height: 0, paddingTop: '56.25%', }} image={doc.url} />
          </motion.div>                    
        </CardActionArea>
        <Divider sx={{paddingTop:'4px'}} />
        <CardActions disableSpacing>

          <Tooltip title="Poista" placement="top" arrow >                                            
              <IconButton {...bindTrigger(popupState)} sx={{marginLeft: "auto"}}>
                  <Delete />
              </IconButton>
          </Tooltip>                    

          <Menu
              {...bindMenu(popupState)} 
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
              >
              <MenuItem onClick={handleDelete(doc.id)} >Poista kuva</MenuItem>
          </Menu>

        </CardActions>
      </Card>
    </Grid>        
  )
}
