import { onValue, ref } from '@firebase/database';
import { Container, CssBaseline, Grid, Stack, Typography,Box } from '@mui/material';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { RDB } from '../../config/datapaths'
import Slider, { SliderThumb } from '@mui/material/Slider';
import FricoSlider from './FricoSlider';
import sliderScene from "../../assets/slider/slider-scene.png"

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";


export default function WatchSlider({avatar, sliderValue, handleSliderChangeRDB, handleSliderChangeView, questionTextState, maxTextState, minTextState, gamelang}) {
    
    // const Thumb = (props) => <ThumbComponent {...props} avatar={avatar}/>
    // const refThumb = <ThumbComponent avatar={avatar}/> 
    // console.log("avatar",avatar);
    return (
        <Box sx={{
            width:"100vw", 
            height:"100vh", 
            textAlign:"center", 
            backgroundImage:'url("'+sliderScene+'")',
            backgroundRepeat: "repeat-x",
            backgroundColor:"#b8fff9",
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            }} >

            <Typography variant="h4" sx={{paddingTop: "5vh", paddingBottom: "10vh" }}> {questionTextState} </Typography>

            <Box sx={{paddingBottom:"5vh"}}>
            <Grid container sx={{width:"80vw", margin:"auto"}} >
                <Grid item xs={4} sx={{textAlign: "left"}}><Typography  component="span" variant="h5"> {minTextState} </Typography></Grid>
                <Grid item xs={4}><Typography  component="span" variant="h5"> {""} </Typography></Grid>
                <Grid item xs={4} sx={{textAlign: "right"}}><Typography  component="span" variant="h5"> {maxTextState} </Typography></Grid>
            </Grid>
            </Box>

            <div sx={{textAlign: "center", width:"100%", margin: "auto", backgroundColor:"green", }}>   
                <FricoSlider avatar={avatar} sliderValue={sliderValue} handleSliderChangeRDB={handleSliderChangeRDB} handleSliderChangeView={handleSliderChangeView} scaleFactor={1.5} gamelang={gamelang}/>
            </div>
            <Box>
            <Grid container sx={{width:"80vw", margin:"auto"}}>
                <Grid item xs={4} sx={{textAlign: "left"}}><Typography  component="span" variant="h5"> {0} </Typography></Grid>
                <Grid item xs={4}><Typography  component="span" variant="h5"> {""} </Typography></Grid>
                <Grid item xs={4} sx={{textAlign: "right"}}><Typography  component="span" variant="h5"> {100} </Typography></Grid>
            </Grid>
            </Box>

            <Typography variant="h4" sx={{ paddingTop: "5vh"}}> {sliderValue} </Typography>

        </Box>
    )
}



