import { Button, Hidden, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import ProgressBar from './ProgressBar';

const UploadForm = ({docType}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = ['image/png', 'image/jpeg'];
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError('');
    } else {
      setFile(null);
      setError('Kuvan tulee olla png tai jpg muodossa');
    }
  };

  return (
    <div style={{ paddingLeft:"15px", display: "inline-block", width: "80%"}}>
      <form style={{display: "inline-block" }}>
          <input 
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            // multiple={false}
            type="file" 
            onChange={handleChange}
          />
          <Button size="medium" variant="contained" color="primary"
              style={{ paddingLeft:"15px", display: "inline-block" }}
              onClick={(e) => {
                  e.preventDefault();
                  fileInputRef.current.click();
                }
              }
          >
            Lataa uusi kuva
          </Button>
      </form>
      { error && <Typography style={{ paddingLeft:"5%", width: "50%", display: "inline-block" }} className="error"   >{ error }</Typography> }
      { file &&  <Hidden smDown><Typography  style={{ paddingLeft:"5%", width: "30%", display: "inline-block" }} >{file.name }</Typography></Hidden> }
      { file && <Typography component="div" style={{ paddingLeft:"5%", width: "35%", display: "inline-block" }} >
                    <ProgressBar style={{ paddingLeft:"5%", width: "100%", display: "inline-block" }} file={file} setFile={setFile} docType={docType}/>
                </Typography> 
      }
    </div>
  );
}

export default UploadForm;