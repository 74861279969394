import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from  '@mui/styles'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

import fiLocale from 'date-fns/locale/fi';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { KeyboardDateTimePicker } from '@mui/lab/pickers'
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker'
import DateTimePicker from '@mui/lab/DesktopDateTimePicker'



export default function FricoTimePicker({startsAt, setStartsAt,endsAt, setEndsAt, }) {

  
  useEffect(()=>{
    setEndsAt(moment(moment(startsAt).add(3, 'hours')))
  },[startsAt]);

  return (
    <div>
        <Grid container spacing={3} sx={{paddingTop:"30px" }}  alignItems="left" direction="row" >
            <Grid item   xs={12} sx={{textAlign:"left"}}>
                <LocalizedDatePicker 
                    value={startsAt} 
                    setValue={setStartsAt}
                    label="Pelituokio alkaa:"
                    minDateTime={moment().toDate()}
                    maxDateTime={moment().add(1, 'years').toDate()}
                />
            </Grid>
            <Grid item  xs={12} sx={{textAlign:"left"}} >
                <LocalizedDatePicker 
                    value={endsAt} 
                    setValue={setEndsAt} 
                    label="Pelituokio päättyy:"
                    minDateTime={startsAt}
                    maxDateTime={moment().add(1, 'years').toDate()}
                />
            </Grid>
        </Grid>
    </div>
  );
}
  
export function LocalizedDatePicker({
                        textAreaId,
                        value, 
                        setValue, 
                        label={label}, 
                        // minDateTime=moment().subtract(100, 'years').toDate(),  
                        // maxDateTime=moment().add(100, 'years').format('MM.yyyy HH:mm').toDate(), 
                        country='fi', 
                        toggle=false,
                        error,
                        helperText
                    }) {

const [locale, setLocale] = React.useState(country);
const selectLocale = (newLocale) => {
    setLocale(newLocale);
};


const weekday=(date) => {
    const day=['su','ma','ti','ke','to','pe','la'];
    return day[moment(date.substring(0,10),["DD.MM.YYYY"] ).toDate().getDay()]
}

// Pickers....
// https://material-ui-pickers.dev/api/DateTimePicker

const localeMap = {
    en: enLocale,
    fr: frLocale,
    ru: ruLocale,
    de: deLocale,
    fi: fiLocale,
    };
    
    const maskMap = {
    fr: '__/__/____',
    en: '__/__/____',
    ru: '__.__.____',
    de: '__.__.____',
    fi: '__.__.____',
//  date picker prop
//  mask={maskMap[locale]}

    };
    
// FORMATTING
// https://date-fns.org/v2.16.1/docs/format
// Pickers
// https://material-ui-pickers.dev/api/KeyboardDateTimePicker

return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}  >
    <div>
        {toggle && (
            <ToggleButtonGroup value={locale} exclusive sx={{ mb: 2, display: 'block' }}>
                {Object.keys(localeMap).map((localeItem) => (
                    <ToggleButton
                        key={localeItem}
                        value={localeItem}
                        onClick={() => selectLocale(localeItem)}
                    >
                        {localeItem}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        )}
        <DateTimePicker
            showTodayButton
            mask=''
            value={value}
            label={label}
            onChange={(newValue) => setValue(newValue)}
            renderInput={(params) =>  (<TextField {...params} id={textAreaId} variant="standard" error={error} helperText={helperText} sx={{ minWidth:"300px"}} />)}
            inputFormat = "EEEEEE dd.MM.yyyy', klo' HH:mm" 
            
            
        />
    </div>
    </LocalizationProvider>
);
}

  
  