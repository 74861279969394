import './imagePicker.scss';
import React from 'react';
import UploadForm from './comps/UploadForm';
import ImageGrid from './comps/ImageGrid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


export default function ImagePicker( { idState, setIdState, img, handleChange, docType, } ) {

  const handleClose = () => {
    setIdState({...idState, open:false});
  };

  let docTypeFi = 'kuva';
  if(docType == 'cardBackground') { docTypeFi = 'kortin taustakuva'}
  if(docType == 'cardFront') { docTypeFi = 'kuva kortin tekstipuolelle'}
  if(docType == 'view') { docTypeFi = 'taustakuva'}
  if(docType == 'frico-main-image') { docTypeFi = 'FriCo-pelin mainoskuva'}

  return (
    <div className="image-picker">
      <Dialog open={idState.open} 
          onClose={handleClose} 
          aria-labelledby="form-dialog-title"   
          fullWidth
          maxWidth="md"
          >
        <DialogTitle id="form-dialog-title">{"Valitse tai lataa uusi " + docTypeFi +" (png, jpg):"}</DialogTitle>
        <DialogActions sx={{textAlign: 'left', paddingLeft: '25px'}} style={{ display: "block", width: "100%"}}>
          <Button style={{ display: "inline-block", width: "20%" }}
               onClick={handleClose} size="medium" variant="contained" color="primary" >
                 Peruuta
          </Button>
          <UploadForm style={{ display: "inline-block"}} docType={docType}/> 
        </DialogActions>
        <DialogContent>
          <div sx={{width:'100%'}}>
            <ImageGrid idState={idState} setIdState={setIdState} img={img}  handleChange={handleChange} handleClose={handleClose} docType={docType} sx={{width:'100%'}} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}




