import firebase from "./firebase";
import { DOCPATH } from './datapaths';
import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import DataGrid from 'react-data-grid';
import { Typography } from "@mui/material";

export const logUserBilling = async ({
                            uid=firebase.auth().currentUser.uid, 
                            email=firebase.auth().currentUser.email,                             
                            message="Ostettu käyttöoikeusaikaa", 
                            euros=0, 
                            timestamp= Date.now(),
                        }={}) => {

    const db=firebase.firestore();
    const logRef = collection(db, DOCPATH('log_billing'));
    const logRecord = {
        uid,
        email,        
        message,
        euros,
        timestamp,
    }
    await setDoc(doc(logRef, uuidv4()), logRecord);
}

export const getUserBilling = ({
                        uid=firebase.auth().currentUser.uid,  
                        setLog}
                            ) => {
    const db=firebase.firestore();
    const q = query(collection(db, DOCPATH('log_billing')), where("uid", "==", uid));
    let i=1;
    getDocs(q).then((querySnapshot)=> {
            let mdata=[];
            querySnapshot.forEach((doc) => {
                const d = doc.data();
                mdata.push({
                    ...d, 
                    date: moment(d.timestamp).format("DD.MM.YYYY [klo] HH:mm"), 
                    euros: d.euros+" €",
                    key:i++,
                });
            });
            // sort by date, most recent first
            mdata.sort((a, b) => b.timestamp - a.timestamp);
            setLog(mdata);
        }
    );
} 

export const billingLogColumns=  [
    {
        key: 'email', 
        name: 'sähköposti', 
        width: 250,
    },
    {
        key: 'date', 
        name: 'tapahtuma-aika', 
        width: 250,
    },
    {
      key: 'message',
      name: 'tapahtuma',
      width: 250,
    },
    {
      key: 'euros',
      name: 'maksu',
      width: 70,
    },
  ];


  export function LogDataGrid({rows,columns,pageSize=5,rowsPerPageOptions=5,}) {
    // https://github.com/adazzle/react-data-grid/blob/main/README.md
    // https://github.com/adazzle/react-data-grid/blob/main/README.md
    if(rows.length==0) return (<>
                    <Typography variant="h5">Sinulla ei ole vielä ostotapahtumia.</Typography>
                    <Typography variant="normal">Jatkossa voit käydä täällä tarkistamassa tekemäsi ostokset.</Typography>
                </>)
    return (<>
        <Typography variant="h5" sx={{paddingTop: "20px", paddingBottom:"20px"}}>Viimeisimmät tilisi ostotapahtumat:</Typography>            
        <DataGrid
            style={{minHeight:"200px"}}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[rowsPerPageOptions]}
        />
    </>);
}