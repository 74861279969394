import ImageMapper from 'react-image-mapper';
import emptyImage from './../../assets/books/emptyImage.png'

// https://www.image-map.net/
const MAP = {
    name: "books-image-map",
    areas: [
      { name: "1", coords:[705,183,617,395,909,405,912,215], shape:"poly"},
      { name: "2", coords:[1026,187,1068,395,1355,400,1273,207], shape:"poly"},
      { name: "3", coords:[613,417,451,848,912,848,915,449], shape:"poly"},
      { name: "4", coords:[1075,454,1126,866,1546,849,1387,448,1099,424], shape:"poly"},
      { name: "5", coords:[322,232,132,449,142,493,449,498,576,263], shape:"poly"},
      { name: "6", coords:[1334,280,1453,490,1757,488,1767,453,1590,249,1368,231], shape:"poly"},
      { name: "7", coords:[74,509,434,544,179,1026,3,1014,0,592], shape:"poly"},
      { name: "8", coords:[1506,539,1755,1032,1916,1025,1918,605,1833,522,1550,505], shape:"poly"},
    ]
  }

// https://www.npmjs.com/package/react-image-mapper
// https://coldiary.github.io/react-image-mapper/

export default function BookMapper({width, originalWidth=1920, handleClick, hideMe, setShowNumbers}){
    const imgWidth=originalWidth;
    if(hideMe){return null}
    return (
        <div _id="bookmapper" style={{overflow:"hidden", position:"absolute", zIndex:"20"}}>
            <ImageMapper 
                src={emptyImage} 
                map={MAP} 
                width={width}
                fillColor="rgba(255, 255, 255, 0.0)"
                imgWidth={imgWidth}
                onClick={area => handleClick(area)}
                onMouseEnter={area => setShowNumbers(true) }
                onMouseLeave={area => setShowNumbers(false) }
            />
        </div>                    
    )
}


