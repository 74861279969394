import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';

import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router';
import HomeIcon from '@mui/icons-material/Home';
import { FricoContext } from '../../config/FricoProvider';
import { toggleFullScreen, cancelFullScreen  } from '../../config/helpers';
import { Alert, Portal, Snackbar } from '@mui/material';
import { uiSetup } from '../../config/userProfile';

const snackMessageCopy = "Linkki on nyt kopioitu leikepöydälle, voit liittää sen haluamaasi paikkaan painamalla Ctrl-V.";

export default function LeftPlayDrawer({toggleDrawer}) {
    
    const navigate = useNavigate()
    const frico = useContext(FricoContext);       

    const protocol = process.env.REACT_APP_BASEURL.includes('localhost')?"http://":"https://";

    const [openSnack, setOpenSnack] = useState(false);
    const [snackText, setSnackText] = useState("");

    const handleCopyClose = (event, reason) => {
      event.stopPropagation();
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnack(false);
    };


    if (!frico.session) return null;
    const gameLang = frico.session.gameState.gameLang || "FI";
    const urlJoinGame = protocol+process.env.REACT_APP_BASEURL+"/watch/"+ frico.session.id+"/"+"default-player/"+gameLang; // playerid = "default-player";
    const anchorJoinGame = '<a href="'+urlJoinGame+'">Osallistu FriCo-peliin</a>';
    
    const anchor = "left";

        // compile list of available pdf docs
    let pdfIcons = [];
    const pdfs = frico.session.gameState.pdfs;
    for (const key in pdfs) {
      pdfIcons.push(
        { 
          visible: pdfs[key].name != "", // pdfs[key].name!=""
          divider: false, 
          icon: <PictureAsPdfIcon color="secondary" /> ,
          onClick: function(){return ()=>{
                      navigate('/pdfplayer/'+key+'/'+frico.session.id);
                    }}(),
          text: pdfs[key].name, 
        }
      )
    }

    // use if necessary
    // uiSetup("Application")
    const menuItems = [
      { 
        visible: true,
        divider: false, 
        text: 'Palaa asetuksiin', 
        icon: <ArrowBackIcon color="secondary" /> ,
        onClick: ()=>{
          cancelFullScreen();
          navigate('/') 
        }
      },
      { 
        visible: true,
        divider: false, 
        text: 'Pieni/suuri näyttö', 
        icon: <AspectRatioIcon color="secondary" /> ,
        onClick: ()=>{
          toggleFullScreen();        }
      },
      {        
        visible: true,
        divider: false, 
        text: 'Vastauslinkki (Teams)', 
        icon: <LinkIcon color="secondary" /> ,
        onClick: ()=>{
          setOpenSnack(true);
          setSnackText(snackMessageCopy);
          const blobInput = new Blob([anchorJoinGame], {type: 'text/html'});
          const clipboardItemInput = new ClipboardItem({
              'text/html'  : blobInput,
          });
          navigator.clipboard.write([clipboardItemInput]);
        }
      }, 
      {        
        visible: true,
        divider: true, 
        text: 'Vastauslinkki (https://...)', 
        icon: <LinkIcon color="secondary" /> ,
        onClick: ()=>{
          setOpenSnack(true);
          setSnackText(snackMessageCopy);
          navigator.clipboard.writeText(urlJoinGame);
          }
      }, 
      { 
        visible: true,
        divider: false, 
        text: 'Pelin alkunäkymä', 
        icon: <HomeIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/gamestart/'+frico.sessionId);
        }
      }, 
      { 
        visible: true,
        divider: false, 
        text: 'Aula', 
        icon: <PlayCircleFilledWhiteIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/lobby/'+frico.sessionId);
        }
      },
      {          
        visible: frico.session.gameState["cardsLibrary"] !== undefined,
        divider: false, 
        text: 'Kirjasto', 
        icon: <PlayCircleFilledWhiteIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/library/'+frico.sessionId);
        }
      },
      { 
        visible: frico.session.gameState["rocks"] !== undefined ,
        divider: false, 
        text: 'Salainen polku', 
        icon: <PlayCircleFilledWhiteIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/rocks/'+frico.sessionId);
        }
      },
      { 
        visible: true,
        divider: false, 
        text: 'Puutarha', 
        icon: <PlayCircleFilledWhiteIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/garden/'+frico.sessionId);
        }
      },
      ...pdfIcons,
      { 
        visible: true,
        divider: true, 
        text: 'Liukuasteikko', 
        icon: <PlayCircleFilledWhiteIcon color="secondary" /> ,
        onClick: ()=>{
          navigate('/gmslider/'+frico.sessionId);
        }
      },

    ]

    return frico.session && (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 240 }}
        style={{ pointerEvents:"all"}}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >

      <Portal>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          autoHideDuration={3000}
          onClose={(e) => {setOpenSnack(false)}}
          open={openSnack}
        >
          <Alert onClose={handleCopyClose} severity="success" variant="filled" sx={{ width: '100%' }}>{snackText}</Alert>
        </Snackbar>
      </Portal>

        <List>
            {menuItems.map((item) => (
                item.visible && <ListItem divider={item.divider}
                    button 
                    key={item.text} 
                    onClick={item.onClick}
                    className={location.pathname == item.path ? classes.active : null}
                >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                </ListItem>
            ))}
        </List>
        <Divider />
      </Box>
      )
}
