import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { RDB } from '../../config/datapaths'
import { set, ref, onValue, update, remove, connectDatabaseEmulator } from "firebase/database";
import SelectAvatar from './SelectAvatar';
import WatchSlider from './WatchSlider'
import WatchTextbox from './WatchTextbox'
import { useAuth } from '../../config/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import logo from './pix/FriCo-logo-app-colormatched-vertical.svg'
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';

// The way to delete anonymous users
// https://stackoverflow.com/questions/39640574/how-to-bulk-delete-firebase-anonymous-users

// Local storage for anonymous users
// https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/

export default function Watch() {

    // ================================================
    // this can be viewed as an anonymous user
    // we just need to login anonymously
    // ================================================
    const {sessionid, playerid, gamelang = "FI"} = useParams();
    const [avatar, setAvatar]=useState('');
    const {currentUser, anonymousLogin, loading} = useAuth();
    const playerRef=useRef(null);

    const [loginReady, setLoginReady] = useState(false);

    useEffect(()=>{
        if(!currentUser) {
            console.debug("Anonymous login...");
            anonymousLogin();
        }
    },[currentUser]);
    // ================================================

    const sessionRef = ref(RDB, 'tmp/sessions/'+sessionid);      
    const [watchComponent, setWatchComponent] = useState('WaitSession');
    const [textboxValues, setTextboxValues] = useState({});

    // Slider ==========================
    const [sliderValue, setSliderValue] = useState(0);
    const [questionTextState, setQuestionTextState] = useState('');
    const [minTextState, setMinTextState] = useState('');
    const [maxTextState, setMaxTextState] = useState('');

    // ==============================================================================================
    // if we have default anonymous player, make a unique identifier to allow multiple anonymous participants
    // Keep these together
    // ==============================================================================================
    if(!playerRef.current){
        playerRef.current = playerid=="default-player" ?playerid+"-"+uuidv4():playerid;
    }
    const textboxRef =          ref(RDB, 'tmp/sessions/'+sessionid+'/users/'+playerRef.current);  // < simplify
    const textboxValuesRef =    ref(RDB, 'tmp/sessions/'+sessionid+'/users/'+playerRef.current+'/textboxValues');  // < simplify
    const sliderRef =           ref(RDB, 'tmp/sessions/'+sessionid+'/users/'+playerRef.current);  // < simplify
    const sliderValueRef =      ref(RDB, 'tmp/sessions/'+sessionid+'/users/'+playerRef.current+'/sliderValue');  // < simplify
    // ==============================================================================================

    // ======================================================
    // force page refresh while waiting what to watch, just to overcome possible issues ...
    // ======================================================
    const [toggle, setToggle] = useState(true); // dummy toggle to refresh page
    useEffect(()=>{
        const timeoutId = setTimeout( (watchComponent) => {
             if(watchComponent=="WaitSession"){
                 // window.location.reload(false);
                 setToggle(!toggle);
                 console.log("Watch wait reload");
             }
            }, 2000,watchComponent);
            return ()=>{clearTimeout(timeoutId)}
     },[toggle, watchComponent]);

    // ======================================================
    // Watch component: Select what we are watching...
    // ======================================================    
    useEffect(()=>{
        if(!currentUser) return;

        // ===========================================
        // SECURITY FIX
        // "auth != null && auth.provider == 'anonymous'" allow only anonymous
        // https://medium.com/@skytreasure/easy-way-to-secure-firebase-realtime-database-with-rules-when-you-have-anonymous-sign-in-or-already-e8ff1ddfbfc9
        // ===========================================
        console.log("################### Writing anonymous id ", currentUser)
        console.log("################### RDB ", RDB)
        const useridRef = ref(RDB, '/itupelianonymousids');
        console.log("################### useridRef ", useridRef)
        update(useridRef, {[currentUser.uid]: true})
            .then(() => {
                console.log('Update successful');
                setLoginReady(true);
            })
            .catch((error) => {
                console.error('Update failed:', error);
            });
        // ===========================================
        
        const unsubscribe = onValue(sessionRef, (snapshot) => {
            const data = snapshot.val();
            if(data && data.watchComponent){
                setWatchComponent(data.watchComponent);
            }
        });
        return unsubscribe;
    },[currentUser]);

    // =====================================================================
    // Cleanup on unmount
    // remove avatar from db on exit
    // This will make a notification on exit on player browser!!!
    // =====================================================================
    const warningClose = (e) => {
        e.preventDefault()
        if(watchComponent!="WaitSession" ){
            e.returnValue = 'CloseWarning';
            return "CloseWarning"
        };
    }
    const handleClose = (e) => {
        e.preventDefault();
        remove(sliderValueRef)
    }
   
    useEffect(() => {
        window.addEventListener('beforeunload', warningClose)
        window.addEventListener('unload', handleClose)
        return () => {
          window.removeEventListener('beforeunload', warningClose)
          window.removeEventListener('unload', handleClose)          
        }
      }, [watchComponent])
    // =====================================================================

    // ======================================================
    // Slider setup and action
    // ======================================================
    useEffect(()=>{
        if(!currentUser) return;
        const unsubscribe = onValue(sessionRef, (snapshot) => {
            const data = snapshot.val();
            if(data && data.watchComponent){
                setQuestionTextState(data.questionText);
                setMinTextState(data.minText);
                setMaxTextState(data.maxText);
                setWatchComponent(data.watchComponent);
            }
        });
        return unsubscribe;
    },[currentUser]);

    useEffect(()=>{
        if(!currentUser) return;
        const unsubscribe = onValue(sliderRef, (snapshot) => {
            const data = snapshot.val();
            if(data && data.sliderValue){
                setSliderValue(data.sliderValue);
            }
        });
        return unsubscribe;
    },[currentUser]);

    const handleSliderChangeRDB = throttleFn( (x) => {
        setSliderValue(x);
        update(sliderRef, {sliderValue: x, avatar});
        // console.log("Function DB Update done!");
      },250); // << =============================== DELAY HERE!!!

      const handleSliderChangeView = (x) => {
        setSliderValue(x);
        // console.log("Function View Update done!");
      };


    // ======================================================
    // Textbox
    // ======================================================
    useEffect(()=>{
        if(!currentUser) return;
        const unsubscribe = onValue(textboxRef, (snapshot) => {
            const data = snapshot.val();
            if(data && data.textboxValues){
                setTextboxValues(data.textboxValues);
            }
        });
        return unsubscribe;
    },[currentUser]);

    const handleTextboxValuesSend = (e, textLocal) => {
      //setTextboxValues(x);
      update(textboxRef, {avatar});
      const timestamp = Date.now();
      update(textboxValuesRef, { [uuidv4()]: {text: textLocal, avatar, timestamp}} );
    }

    // ======================================================
    // Will throttle slider 
    // ======================================================   
    function throttleFn(fn, delay) {
        var timeout;
        var startTime = new Date();
        
        var throttledFn = function(x) {
            // console.log(">>>>>>> e :",x);
            var timeNow = new Date();
            if(timeNow-startTime>delay){
                startTime=timeNow;
                fn(x);
            }
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(fn, delay, x);
        }

        return throttledFn;
    }

    //console.log(gamelang);
    // https://github.com/bagongkia/react-image-picker
    // shuffle images
    // <p>{currentUser.uid}</p>

    if (!currentUser) return null;

    return   (<div>   
                {watchComponent=="WaitSession" && <Logo gamelang={gamelang} currentUser={currentUser} loginReady={loginReady}/>}
                {!avatar && watchComponent!="WaitSession" && (<Container><SelectAvatar  setAvatar={setAvatar} gamelang={gamelang}/></Container> )}
                {avatar &&  watchComponent=="Slider" && <WatchSlider {...{sessionid, avatar, sliderValue, handleSliderChangeRDB, handleSliderChangeView, questionTextState, minTextState, maxTextState, gamelang}} />}
                {avatar &&  watchComponent=="Textbox" && <WatchTextbox {...{sessionid, avatar, textboxValues, handleTextboxValuesSend, gamelang}} />}
            </div>)
}

const Logo = ({gamelang, currentUser, loginReady})=>{

    return  <Container sx={{width: "300px", paddingTop: "5vh", textAlign: "center"}} >
        <img style={{width: "200px"}} src={logo} />
        {
            (gamelang == "FI") && <>
                <Typography variant="h7" sx={{textAlign: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                <p>
                    Tämä on FriCo-pelin vastausikkuna, jossa voit vastata pelinohjaajan asettamiin kysymyksiin ja tehtäviin.<br/>
                    <b>Seuraa pelin kulkua kuitenkin edelleen Teams-ikkunan kautta.<br/></b>
                </p>
                </Typography>
                <Button 
                    onClick={() => window.location.reload(false)}
                    variant="contained" 
                    endIcon={<CachedIcon />} 
                    style={{margin: '0 auto', display: "flex", marginTop: "20px"}}
                    disabled={!loginReady}
                >
                    {loginReady?"Liity peliin":"Odotan yhteyttä"}    
                </Button>        
            </>
        }
                {
            (gamelang == "EN") && <>
                <Typography variant="h7" sx={{textAlign: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                <p>
                This is the answer window of the FriCo-game, where you can answer the questions and tasks set by the gamemaster.<br/>
                    <b>However, you should still follow the game through the Teams window.<br/></b>
                </p>
                </Typography>
                <Button 
                    onClick={() => window.location.reload(false)}
                    variant="contained" 
                    endIcon={<CachedIcon />} 
                    style={{margin: '0 auto', display: "flex", marginTop: "20px"}}
                    disabled={!loginReady}
                >
                    {loginReady?"Join the Game":"Waiting for connection..."}        
                </Button>        
            </>
        }

    </Container>
}

// Will throttle whatever
export const throttleFn = (fn, delay) => {
        var timeout;
        var startTime = new Date();
        
        var throttledFn = function(x) {
            // console.log(">>>>>>> e :",x);
            var timeNow = new Date();
            if(timeNow-startTime>delay){
                startTime=timeNow;
                //fn(x); // comment out to eliminate first run
            }
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(fn, delay, x);
        }

        return throttledFn;
    }

