// GODADDY dmomains
// https://dcc.godaddy.com/manage/AHAAVISION.COM/dns?plid=1
//
// SMTP service
// https://app.sendgrid.com/
// OLD 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
// YES: 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
//
// sendgrid authenticated domains
// https://app.sendgrid.com/settings/sender_auth
//
// TUTORIAL 
// Firebase doc
// https://firebase.google.com/docs/extensions/official/firestore-send-email
// https://medium.com/firebase-developers/firebase-extension-trigger-email-5802800bb9ea
// 
// POST INSTALL
// https://github.com/firebase/extensions/blob/master/firestore-send-email/POSTINSTALL.md
//
// Manage Firebase extension
// https://console.firebase.google.com/project/itupeli/extensions/instances/firestore-send-email?tab=details
//
// login SMTP service provider
// smtps://apikey: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@smtp.sendgrid.net:465
// email collections
// _sendgrid_email
// _sendgrid_email_users_collection
// _sendgrid_email_templates_collection
// 

// main fields:
// https://firebase.google.com/docs/extensions/official/firestore-send-email#sender_and_recipient_fields
//
// message fields
// https://firebase.google.com/docs/extensions/official/firestore-send-email#message_field

import { v4 as uuidv4 } from 'uuid';
import { projectFirestore } from './firebase';

const emailCollection = "_sendgrid_email";

export function sendEmail({to='', subject='', text='', html='', attachments=[] }){

    // just for testing purposes
    to = (to=='sulismies@gmail.com')?'markku.lahtinen@ahaavision.com':to;

    // https://nodemailer.com/message/attachments/
    const id = uuidv4();
    const email =  {
        to: to || ['markku.lahtinen@ahaavision.com'],
        message: {
            subject: subject|| 'FriCo–peli tiedottaa',
            text: text || 'Tämä sähköposti on tarkoitettu luettavaksi html-muodossa.',
            html: html || 'This is the <code>HTML</code> section of the email body (default)',
            attachments
        }
    }
    return projectFirestore.collection(emailCollection).doc(id).set(email)
}

