import { Fragment, useContext, useEffect, useRef } from 'react';
import {  Grid, IconButton, Slider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import { useParams } from 'react-router';
import { Box } from '@mui/system';
import { getWindowDimensions, useWindowDimensions } from '../../config/helpers';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';

// https://github.com/wojtekmaj/react-pdf
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { FricoContext } from '../../config/FricoProvider';

// https://www.codexworld.com/convert-html-to-pdf-using-javascript-jspdf/
// https://www.codexworld.com/embed-pdf-document-file-in-html-web-page/
// https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Ftmp%2Fa-test.pdf?alt=media&token=2cc10e2f-a2a8-4102-890b-55f9d9f68546

export default function PdfPlayer() {
    
    const { sessionid, pdfid} = useParams()
    const frico = useContext(FricoContext);    
    const {height, width} = useWindowDimensions();

    const useStyles = makeStyles( (theme) => {
        return {
            gameRoot: {
                spacing: 0,
                justify: 'space-around',
                backgroundColor: "#f9f9f9",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundSize: "cover",
                overflow: "hidden"
            },
        }
    })
    const classes = useStyles()

    // constrols zoom and position of Page component
    const [dWindow, setDWindow] = useState({x:0,y:0,scale:1});
    
    // controls zoom state
    const [zWindow, setZWindow] = useState({left:0,top:0,width:50,height:50, state:"hidden", updateToggle: false});

    // hides / shows the PDF page navigation
    const [showPageControl, setShowPageControl]= useState(true)

    const [cursorPos, setCursorPos]=useState({x:0, y:0});

    const handleMouseMove = (e) => {
        // e.pageX, top: e.pageY
        setCursorPos({x:e.pageX, y:e.pageY})
        if((width*9/16-e.pageY)<200){
            setShowPageControl(true)
        } else {
            setShowPageControl(false)
        }
    }

    const zoomReady = (zW) => {
        if(zW.width>10){
            const scale = zW.docWidth/zW.width;
            if(scale<10){
                const x = (1-scale)*zW.docWidth/2+scale*(zW.docWidth/2-(zW.width+2*zW.left)/2);
                const y = (1-scale)*zW.docWidth*9/16/2 +scale*(zW.docWidth*9/16/2-(zW.height+2*zW.top)/2);
                setDWindow({...dWindow, x, y, scale})
            }
        }
    }

    // 
    if (!frico.session) return null;
    return (
        <Fragment>
            { zWindow.state!="hidden" && <ZoomRectangle setZWindow={setZWindow} zoomReady={zoomReady} zWindow={zWindow} cursorPos={cursorPos}/> }    
            <Box className={classes.gameRoot} onMouseMove={ handleMouseMove }>  
               <ViewPdf pdfFile={frico.session.gameState.pdfs[pdfid].url} classes={classes} showPageControl={showPageControl}  dWindow={dWindow} setDWindow={setDWindow} zWindow={zWindow} setZWindow={setZWindow}/>                    
            </Box>
        </Fragment>
    )
}

// https://github.com/wojtekmaj/react-pdf#page
function ViewPdf({pdfFile, classes, showPageControl, dWindow, setDWindow, zWindow, setZWindow}) {

    const {height, width} = useWindowDimensions();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }

    return (
      <div >
        { zWindow.state=="hidden" && (<Box 
            className={`${showPageControl ? classes.visible : classes.hidden}`}
            sx={{
                align: "center", 
                position: "absolute", 
                zIndex: 10, 
                left: (width-400) / 2 ,              
                top: (width*9/16-100),  
                width: 400, 
                backgroundColor: "rgba(230, 230, 230, 0.8)",
                borderRadius: 3,
                }}
            >    
            <Grid container spacing={1}  alignItems="center"  justifyContent="center" >
                <Grid item xs={2}>
                    <IconButton aria-label="delete"  color="primary"  onClick={()=>setPageNumber(1)}>
                        <FastRewindIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={2}>
                    <IconButton aria-label="delete"  color="primary"  onClick={()=>setPageNumber(pageNumber>1?pageNumber-1:1)}>
                        <PlayArrowIcon style={{transform: "scaleX(-1)"}} />
                    </IconButton>
                </Grid>
                <Grid item xs={3} >
                    <Typography  color="primary" style={{width: "100%", textAlign: "center"}} > {pageNumber} / {numPages}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton aria-label="delete"  color="primary" onClick={()=>setPageNumber(pageNumber<numPages?pageNumber+1:numPages)}>
                        <PlayArrowIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1} >
                    <IconButton aria-label="delete"  color="primary"  onClick={()=>setPageNumber(numPages)}>
                        <FastForwardIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={2}  sx={{textAlign: "right"}} >
                    <IconButton aria-label="delete" color="primary" onClick={(e)=>{e.stopPropagation();setZWindow({...zWindow, state:"zoom"})}}   >
                        <ZoomIn />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={1}  alignItems="center"  justifyContent="center" >
                <Grid item xs={1} justifyContent="flex-end" >
                </Grid>
                <Grid item xs={9}>
                    <Slider
                        aria-label="Sivunumero"
                        size="small"
                        valueLabelDisplay="auto"
                        value={pageNumber}
                        step={1}
                        marks
                        min={1}
                        max={numPages}
                        onChange={(e) => {
                            setPageNumber(e.target.value);
                        }}
             
                    />
                </Grid>
                <Grid item xs={2} sx={{textAlign: "right"}} >
                    <IconButton color="primary" onClick={()=>setDWindow({...dWindow, scale:1,x:0,y:0})}>
                        <ZoomOutMap />
                    </IconButton>
                </Grid>
            </Grid>

        </Box>)}

         <Document 
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
         >

            <Box sx={{
                    position: "absolute",
                    left: dWindow.x,
                    top: dWindow.y,
                }}
                
            >   
                {
                <Page 
                    pageNumber={pageNumber}  
                    width={width}   
                    scale={dWindow.scale}
                    renderAnnotationLayer={false} 
                />
                }

            </Box>                
         </Document >
      </div>
    );
    
}

// zState:  firstCorner | secondCorner | done
export function ZoomRectangle({setZWindow,zoomReady,zWindow, cursorPos}){

    const firstCorner = useRef(true);
    const dragStarted = useRef(false);
    // Cursor pos required for the first mousedown event!
    const zW = useRef({ left:cursorPos.x, top:cursorPos.y, width:50, height:50})

    const handleOnMouseMove = (e)=>{
        setZWindow({...zWindow, updateToggle:!zWindow.updateToggle})
        if(firstCorner.current){
            zW.current={...zW.current, left:e.pageX, top:e.pageY};
        } else {
            zW.current={...zW.current, width:e.pageX-zW.current.left, height:e.pageY-zW.current.top};
        }
    }

    const handleMouseUp = (e)=>{
        dragStarted.current = false;
        firstCorner.current=true;
        const {width:docWidth,height:docHeight}=getWindowDimensions();        
        setZWindow({...zW.current, state:"hidden"});
        zoomReady({...zW.current, state:"hidden",docWidth, docHeight})
    }


    const handleMouseDown = (e)=>{
        firstCorner.current=false;
        const {width:docWidth,height:docHeight}=getWindowDimensions();        
        setZWindow({...zW.current, state:"zoom"});
        zoomReady({...zW.current, state:"zoom",docWidth, docHeight})
        zW.current={left:e.pageX,top:e.pageY,width:50,height:50};
    }

    useEffect(() => {
        window.addEventListener('mousemove', handleOnMouseMove);
        window.addEventListener('mouseup', handleMouseUp);        
        window.addEventListener('mousedown', handleMouseDown);        
        return () => {
          window.removeEventListener('mousemove', handleOnMouseMove);
          window.removeEventListener('mouseup', handleMouseUp);
          window.removeEventListener('mousedown', handleMouseDown);
        };
      }, []);

    return (
        <Box 
            sx={{
                backgroundColor: "transparent",
                position:"absolute",
                zIndex: 50,
                left: zW.current.left,
                width: zW.current.width,
                top: zW.current.top,                
                height: zW.current.height,
                border: "6px dotted #3498db",
            }}
        />
    )   
}

  