import { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import { Toolbar } from '@mui/material';
import LeftPlayDrawer from './LeftPlayDrawer';
import RightPlayDrawer from './RightPlayDrawer';
import { Outlet } from 'react-router';
import  Hamburger  from 'hamburger-react';

export default function PlayGameLayout( ) {

  const [stateDrawer, setStateDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStateDrawer({ ...stateDrawer, [anchor]: open });
    if(anchor=="left"){
      setHLOpen(!isHLOpen)
    }
    if(anchor=="right"){
      setHLOpen(!isHROpen)
    }

    //setStateDrawer({ ...stateDrawer, ...{left: false, right:false, }})
  };

  // https://hamburger-react.netlify.app/
  const [isHLOpen, setHLOpen] = useState(false)
  const [isHROpen, setHROpen] = useState(false)

  return (
      <div style={{overflow:"hidden"}} > 
      
      <Toolbar  style={{position:"absolute", backgroundColor:"transparent)"}}   >
            <div style={{position:"fixed", zIndex: 2000, left:isHLOpen?"270px":"20px", backgroundColor:"rgba(255,255,255,0.8)", borderRadius:"5px"}} >
              <Hamburger 
                toggled={isHLOpen} 
                toggle={setHLOpen} 
                onToggle={toggled => {
                  if (toggled) {
                      setStateDrawer({ ...stateDrawer, ...{left: true }})
                  } else {
                      setStateDrawer({ ...stateDrawer, ...{left: false }})
                  }
                }}
              />
            </div>
            <div style={{position:"fixed", zIndex: 2000, right:isHROpen?"270px":"20px", backgroundColor:"rgba(255,255,255,0.8)", borderRadius:"5px"}} >
              <Hamburger 
                toggled={isHROpen} 
                toggle={setHROpen} 
                onToggle={toggled => {
                  if (toggled) {
                      setStateDrawer({ ...stateDrawer, ...{right: true }})
                  } else {
                      setStateDrawer({ ...stateDrawer, ...{right: false }})
                  }
                }}
              />
            </div>

            <Drawer
              style={{ pointerEvents:"none"}}
              variant="temporary"
              ModalProps={{
                keepMounted: true,
              }}
              
              PaperProps={{
                style:{
                  bodrder:0,
                  backgroundColor:"white",
                  opacity:0.85,
                }
              }}                        
              
              elevation={5}
              anchor={"left"}
              open={stateDrawer.left}
              onClose={toggleDrawer("left", false)}

              hideBackdrop              
              BackdropProps={{
                invisible: true, 
                style:{
                  backgroundColor:"white", 
                  opacity:0.2,
                  pointerEvents:"none",
                  zIndex:-1,
                }
              }}

            >
              <LeftPlayDrawer toggleDrawer={toggleDrawer}/>
            </Drawer>

            <Drawer
              style={{ pointerEvents:"none"}}
              variant="temporary"
              ModalProps={{
                keepMounted: true,
              }}

              PaperProps={{
                style:{
                  bodrder:0,
                  backgroundColor:"white",
                  opacity:0.9,
                }
              }}                        

              hideBackdrop
              
              elevation={5}
              anchor={"right"}
              open={stateDrawer.right}
              onClose={toggleDrawer("right", false)}
            >
              <RightPlayDrawer toggleDrawer={toggleDrawer}/>
            </Drawer>

      </Toolbar>

      <Outlet/>

    </div>
  );
}

