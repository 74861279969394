import { Badge, Container, Grid, Typography } from '@mui/material';
import useFirestoreCol from '../../config/useFirestoreCol';
import { isEmpty, UserSelector } from '../../config/helpers';
import SessionCard from './SessionCard';
import { useAuth } from '../../config/AuthProvider';
import { uiSetup } from '../../config/userProfile';

const SessionList = () => {
    
    const { userData, setUserData } = useAuth();  
    const { docs:sessions, loading:loadingSession, error:errorSession, deleteDoc:deleteSession, duplicateDoc:duplicateSession } = useFirestoreCol("sessions");
      
    return (
      uiSetup('Application') && <>
          {errorSession && (<Typography variant="h6" >Error: {JSON.stringify(error)}</Typography>)}
          {!loadingSession && (
              <Container sx={{width:"100%"}}>
                <Grid container direction="column" spacing={2} alignItems="center" >
                    <Grid item><UserSelector userData={userData} setUserData={setUserData} /></Grid> 
                    <Grid item >
                        <Typography variant="h5" align="center">
                        { !isEmpty(userData) &&  userData.guid?  (<span>Ryhmän jäsenen pelit:</span>) : sessions.length?(<span>Sinun pelisi</span>):(<><p>Klikkaa</p><p>+ Luo uusi peli</p><p> sivun vasemmasta laidasta.</p></>) }
                        </Typography >                        
                    </Grid>                    
                    {sessions.sort(orderByCreatedAt).map((doc) => (
                        <Grid item key={doc.id} style={{ width: '100%', marginBottom:"30px" }} >
                            <SessionCard session={doc} deleteSession={deleteSession} duplicateSession={duplicateSession}  userData={userData} />
                        </Grid>                                                    
                    ))}
                </Grid>
              </Container>
          )}
      </>
    )
  }

//  <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} badgeContent={"9/2"} color="primary" >
//  </Badge>

export default SessionList;

function orderByCreatedAt( a, b ) {
    if ( a.modified < b.modified ){
      return 1;
    }
    if ( a.modified > b.modified ){
      return -1;
    }
    return 0;
  }


