import { useEffect, useState, useRef } from 'react'
import firebase from '../../config/firebase'; 
import { useParams } from 'react-router';
import { Alert, AlertTitle, Container, Grid, Paper, Slider, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../../config/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, getDoc, deleteDoc, updateDoc } from "firebase/firestore"; 
import Button from "@mui/material//Button";
import Box from "@mui/material/Box";
import moment from "moment";
// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import {useForm, Controller} from "react-hook-form";
import * as yup from 'yup';
import sendBasicEmail from '../../config/sendBasicEmail';
import { UserMediaCard } from '../user/Tabs/BasicInfo';

// The way to delete anonymous users
// https://stackoverflow.com/questions/39640574/how-to-bulk-delete-firebase-anonymous-users

// Local storage for anonymous users
// https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/

export default function Enroll() {

  const MAX_PLAYERS = 3;

    // ================================================
    // this can be viewed as an anonymous user
    // we just need to login anonymously
    // ================================================
    const [session, setSession] = useState(null);
    const {currentUser, anonymousLogin} = useAuth();
    const {enrollid} = useParams();

    const [showMessageOK, setShowMessageOK] = useState(false);    
    const [showMessageNOK, setShowMessageNOK] = useState(false);    
    const [showMessageInUse, setShowMessageInUse] = useState(false);    
    const [showMessageFull, setShowMessageFull] = useState(false);
    const [showMessageUpdate, setShowMessageUpdate] = useState(false);
    const [showMessageCancel, setShowMessageCancel] = useState(false);
    const [playerId, setPlayerId] = useState(null);
    const [denyButtons, setDenyButtons] = useState(false);
    const [noEnrollDoc, setNoEnrollDoc] = useState(false);

  // ================================================
  // Form stuff
  // https://react-hook-form.com/get-started
  // 
  // https://github.com/react-hook-form/react-hook-form
  // https://github.com/bluebill1049/little-state-machine
  // https://www.youtube.com/watch?v=CeAkxVwsyMU
  // 
  // ================================================
  const sessionRef = useRef(null);

  const onUpdate = (data) => {
    const players={...session.players, [playerId]:data};
    updateDoc(sessionRef.current,{players} ).then(()=>setShowMessageUpdate(true));
    console.log("Updating ....");
  }
  const onSubmit = (data) => {
      if(Object.keys(session.players).length>=MAX_PLAYERS && !playerId)  {
        setShowMessageFull(true) 
        setShowMessageInUse(false);  
        setShowMessageOK(false);
        setShowMessageNOK(false);
        return false;
      }
      data.playerId=uuidv4();
      data.timestamp=Date.now();
      data.emailVerified=false;
      console.log("ON SUBMIT: >>>>>>>>>>>>>>>>>>>> ", data);
      console.log("ON SUBMIT: >>>>>>>>>>>>>>>>>>>> players", Object.values(session.players));
      if(Object.values(session.players).find(p => p.email === data.email) ){
        console.log("Error, mail already in use");
        setShowMessageInUse(true);  
        setShowMessageOK(false);
        setShowMessageNOK(false);
        return false;
      }
      const players={...session.players, [data.playerId]:data};

      // sendBasicEmail({
      //   to="markku.lahtinen@ahaavision.com", 
      //   greeting= "Hei,",
      //   subject='FriCo-peli lähetti sinulle viestin', 
      //   html='<p>FriCo-peli lähetti sinulle viestin. Tämä on tyhjä vakioviesti.</p>', 
      //   link= 'http://www.frico.app/',
      //   linkText= 'www.frico.app',
      //   htmlTemplate=defaultEmailTemplate,
      //   attachments=[],
      // })

      const link="https://"+process.env.REACT_APP_BASEURL+"/enroll/"+enrollid+"?playerId="+data.playerId; 
      const linkText="Muokkaa ilmoittautumistietojasi";
      const html="olet ilmoittautunut mukaan FriCo-vuorovaikutuspeliin. Vahvista osoitteesi oikeellisuus klikkaamalla alla olevaa linkkiä. Linkistä avautuvalla sivulla näet myös tilaisuuden tiedot ja voit muuttaa omia tietojasi.";
      sendBasicEmail({ to:data.email, html, link, linkText, });

      updateDoc(sessionRef.current,{players} );
      setSession({...session, players })
      setShowMessageOK(true);
      setShowMessageNOK(false);
      setShowMessageInUse(false);  
    };

  const onError = (errors) => {
    console.log("ON ERROR: >>>>>>>>>>>>>>>>>>>> ", errors)
    setShowMessageNOK(true);
    setShowMessageOK(false);
    setShowMessageInUse(false);  
  };

  const handleCancel = (playerId) => {
    const players={...session.players};
    const html="ilmoittautumisesi FriCo-vuorovaikutuspeliin on peruttu. Tervetuloa osallistumaan jonain muuna ajankohtana.";
    sendBasicEmail({ to:players[playerId].email, html, link:'', linkText:'', });
    delete players[playerId];
    updateDoc(sessionRef.current,{players} );
    setSession({...session, players });
    //setPlayerId(null);
    reset({firstname:'', lastname:'', email:'', mobile:''});
    setShowMessageCancel(true);
    setTimeout(()=>{
        window.location.href="/enroll/"+enrollid+'/';
        setShowMessageCancel(false);
      },5000)
  }

  const  schema = yup.object().shape({
    firstname:  yup.string().min(2).max(100).required(),
    lastname:   yup.string().min(2).max(100).required(),
    email:      yup.string().email().required(),
    mobile:     yup.string().matches(/^([0-9-\s\+]){6,20}$/, {
                      message: "Puhelinnumero ei kelpaa",
                      excludeEmptyString: false,
                }),
  });
  
  const {
      register,
      reset,
      control,
      handleSubmit,
      watch,
      formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
    } = useForm({ 
                    mode: "onChange",
                    resolver: yupResolver(schema),
                    shouldFocusError: true,
                    criteriaMode: "all",
                    reValidateMode: "onChange"
                  });
  

    const db=firebase.firestore();
    // update player info
    useEffect( async ()=>{
        if(!currentUser) {
            anonymousLogin();
            return
        };

        const search = window.location.search;
        if(search.split("=")[1]){
          setPlayerId(search.split("=")[1]);
        }
    
        const enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll/'+ enrollid);
        const endrollSnap = await getDoc(enrollRef);

        // NO ENROLL RECORD
        if(!endrollSnap.exists()) {
          setNoEnrollDoc(true);
          console.error("NO ENROLL DOCUMENT");
          return;
        }
        const tmpEnroll = endrollSnap.data();
        sessionRef.current = doc(db, '/itu/testing-ahaa/userdata/'+tmpEnroll.userId+'/sessions/'+tmpEnroll.sessionId);
        console.log("sessionRef-01",sessionRef.current);

        // NO SESSION
        const sessionSnap = await getDoc(sessionRef.current);
        if(!sessionSnap.exists()) {
          setNoEnrollDoc(true);
          console.error("NO SESSION DOCUMENT");
          return;
        }

        const tmpSession = sessionSnap.data();
        // NO PLAYER
        if(!tmpSession.players[playerId] && playerId) {
          setDenyButtons(true);
          console.error("NO PLAYER ENTRY");
        }

        setSession(tmpSession);
        console.log(tmpSession);
        console.log(playerId?"we have a player":"no player defined");

        if(Object.keys(tmpSession.players).length>=MAX_PLAYERS && !playerId) {
          setShowMessageFull(true)
        }
    
    },[playerId]);
    
    useEffect(()=>{
      if(!session) return;
      if(session.players[playerId]){
        const players = session.players;
        players[playerId].emailVerified = true;
        updateDoc(sessionRef.current,{players} );
        const playerData = session.players[playerId];
        reset(playerData);
      }
    },[playerId,session]);

    console.log("errors: ", errors);
      
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    if(noEnrollDoc && !showMessageCancel) return ( //!showMessageCancel
      <Container  >
        <Grid container justifyContent="center" alignContent={'center'} spacing={0} sx={{padding:"30px"}} >
          <Alert severity="error">Tietoja ei löydy1.</Alert>
        </Grid>
      </Container>
      );
    if(!session) return null;
    return (  (
      <form >
      <Box sx={{ maxWidth: "600px", textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
        <Grid container alignContent={'center'} spacing={0} sx={{padding:"30px"}} >
          <Grid 
            item
            sm={12}
            xl={12}  
            >
              <img src={session.seoImage} style={{ width: "100%", height: "auto",  }} />
          </Grid>

          <Grid item xs={12} md={12} >
            <Typography sx={{textAlign: "left"}}>
              <div dangerouslySetInnerHTML={ { __html: session.enrollText}} />
            </Typography>              
            <Typography variant="h7" sx={{textAlign: "left", fontWeight:"bold"}} component="div">
              Tilaisuus alkaa: {moment(session.startsAt).format("DD.MM.YYYY [klo] HH:mm")}
            </Typography>                            
            <Typography variant="h7" sx={{textAlign: "left", fontWeight:"bold"}} component="div">              
              Tilaisuus päättyy: {moment(session.endsAt).format("DD.MM.YYYY [klo] HH:mm")}
            </Typography>              
            <Typography variant="h7" sx={{textAlign: "left", fontWeight:"bold"}} component="div">
              Toteutustapa: {"Etäyhteys"}
            </Typography>              
            <Typography sx={{textAlign: "center", paddingTop:"5px",paddingBottom:"5px"}} variant="h5">
               Pelinohjaaja
            </Typography>              
            <UserMediaCard uid={session.uid}/>
          </Grid>
          {
            // =====================================================================
            // ENROLL BLOCK
            // =====================================================================
          }
          <Grid item xs={12} md={12} >
              <MyAlertFull 
                        title="Tapahtumaan ei voida enää ottaa lisää osallistujia" 
                        text={"Ole yhteydessä tapahtuman järjestäjään." }
                        open={showMessageFull} 
                        setOpen={setShowMessageFull}
                        />
          </Grid>
          <Grid item>
            <Typography sx={{textAlign: "left", paddingTop:"5px",paddingBottom:"0px"}} variant="h5">
                Ilmoittaudu mukaan
            </Typography>              
          </Grid>
          <Grid item xs={12} md={12} >
              <Controller 
                name="firstname" 
                defaultValue=""
                control={control} 
                render={({ field })=> (
                    <TextField 
                      required
                      {...field}
                      label="Etunimi"
                      variant="standard"
                      error={!!errors.firstname}
                      helperText={errors.firstname ? "error":""}
                      margin="dense"
                      fullWidth
                    />
                  )}
                />
          </Grid>

          <Grid item xs={12} md={12} >
              <Controller 
                name="lastname" 
                defaultValue=""
                control={control} 
                render={({ field })=> (
                      <TextField
                        required
                        {...field}
                        label="Sukunimi"
                        variant="standard"
                        error={!!errors.lastname}
                        helperText={errors.lastname ? "error":""}
                        margin="dense"
                        fullWidth
                    />
                  )}
                />
          </Grid>

          <Grid item xs={12} md={12} >
            <Controller 
              name="email" 
              defaultValue=""
              control={control} 
              render={({ field })=> (
                    <TextField
                      required
                      {...field}
                      label="Sähköposti"
                      variant="standard"
                      error={!!errors.email}
                      helperText={errors.email ? "error":""}
                      margin="dense"
                      fullWidth
                  />
                )}
              />
          </Grid>

          <Grid item xs={12} md={12} >
              <Controller 
                name="mobile" 
                defaultValue=""
                control={control} 
                render={({ field })=> (
                      <TextField
                        required
                        {...field}
                        label="Puhelinnumero"
                        variant="standard"
                        error={!!errors.mobile}
                        helperText={errors.mobile ? "error":""}
                        margin="dense"
                        fullWidth
                    />
                  )}
                />
          </Grid>

          <Grid item xs={12} md={12} sx={{paddingTop: "20px",paddingBottom: "20px",  }}>
              {!denyButtons &&  (
                <Stack direction="row" spacing={2} justifyContent={'center'}>
                  {!showMessageFull && (<Button
                      type="submit" 
                      color="primary" 
                      variant="contained"
                      onClick={handleSubmit(playerId?onUpdate:onSubmit, onError)}
                  >
                    {playerId?"Päivitä tiedot":"Ilmoittaudu"} 
                  </Button>)}
                  {playerId && (<Button 
                      color="primary"
                      variant="contained"
                      onClick={()=>handleCancel(playerId)}
                      >
                    Peru ilmoittautumisesi
                  </Button>)}
                </Stack>)
              }
              {denyButtons && !showMessageCancel && (
                <Alert severity="error">Tietoja ei löydy.</Alert>
                )
              }
            </Grid>

          <Grid item xs={12} md={12} >

              <MyAlertOK 
                  title="Vahvistusviesti on lähetetty antamaasi sähköpostiosoitteeseen." 
                  text="Ole hyvä ja klikkaa viestin sisältämää linkkiä. Näin tulet vahvistaneeksi sähköpostiosoitteesi oikeellisuuden ja tiedämme, että kaikki on osaltasi kunnossa. Ellei viestiä näy. tarkista myös roskapostikansio." 
                  open={showMessageOK} 
                  setOpen={setShowMessageOK}
                  />
              <MyAlertNOK 
                  title="Ole hyvä ja tarkista tiedot." 
                  text="Kaikki kentät ovat pakollisa." 
                  open={showMessageNOK} 
                  setOpen={setShowMessageNOK}
                  />
              <MyAlertInUse 
                  title="Tästä sähköpostiosoitteesta on jo ilmoittauduttu." 
                  text="Etsi sähköpostistasi vahvistusviesti, jonka kautta voit muokata ilmoittautumistasi. Ellet löydä viestiä, ole yhteydessä suoraan tilaisuuden järjestäjään." 
                  open={showMessageInUse} 
                  setOpen={setShowMessageInUse}
                  />
              <MyAlertUpdate 
                  title="Tiedot on nyt päivitetty onnistuneesti" 
                  text="Tervetuloa tilaisuuteen." 
                  open={showMessageUpdate} 
                  setOpen={setShowMessageUpdate}
                  />
              <MyAlertCancel
                  title="Ilmoittautumisesi on nyt peruttu" 
                  text="Tervetuloa osallistumaan jonain muuna ajankohtana." 
                  open={showMessageCancel} 
                  setOpen={setShowMessageCancel}
                  />


          </Grid>
    
        </Grid>             
      </Box>
      </form>
    ))
    
}

const MyAlertOK= ({text, title, open, setOpen})=>{
    if(!open){return null}
    return (
        <Alert 
            sx ={{marginTop: "20px", marginBottom: "20px"}}
            severity="success"
            onClose={() => setOpen(false)}
            >
                <AlertTitle>{title}</AlertTitle>    
                <p>{text}</p>
        </Alert>
    )
}


const MyAlertNOK= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px"}}
          severity="error"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}

const MyAlertInUse= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px"}}
          severity="error"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}

const MyAlertFull= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px"}}
          severity="error"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}

const MyAlertUpdate= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px", textAlign:"left", marginRight:"auto"}}
          severity="success"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}

const MyAlertCancel= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px", textAlign:"left", marginRight:"auto"}}
          severity="success"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}



