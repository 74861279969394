import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { RecoilRoot } from 'recoil';
import registerServiceWorker from './registerServiceWorker';

import { CssBaseline } from '@mui/material';

ReactDOM.render(
  <RecoilRoot>
    <CssBaseline />
    <App />
  </RecoilRoot>,
  document.getElementById('root')
);

// https://www.npmjs.com/package/react-service-worker
// serviceWorker is created by React automagically
// registerServiceWorker();
