import { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { projectFirestore } from './firebase';
import { ADMINCOLLECTIONS, DATAROOT, DATADB, DATATMP, } from './datapaths';

const useFirestoreDoc =  (collection, id='"dummy-doc-never-match"', options={}) => {

  const { userData } = useAuth();  

  // choose the right collection, this is the basic case of typical user
  let docCollection = DATAROOT()+ '/' + collection;
  
  // if user userData.guid is set, it overrides the default location
  if(userData!==null && userData.guid){
    docCollection = DATADB + '/userdata/'+userData.guid+ '/' + collection;
  }
  
  // special case of data in FireStore outside auth protection (for creating a new user)
  if( collection==DATATMP ){
    docCollection = DATATMP; 
  }
  if( ADMINCOLLECTIONS.indexOf(collection)>-1 ) {
    docCollection = DATADB + '/'+collection;
  }

  // special case of data in FireStore outside auth protection (for creating a new user)
  if( collection==DATATMP ){
    docCollection = DATATMP; 
  }

  const [doc, setStateDoc] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const setDoc = (data)=>{
    setLoading(true);
    setError(false);
    projectFirestore.collection(docCollection).doc(id).set(data)
        .then(()=>{setLoading(false)})
        .catch((error) => {
          setLoading(false);
          setError(error);
          console.error("useFirestoreDoc: Error setting document data:", error);
    });
  }

  const updateDoc = (data)=>{
    setLoading(true);
    setError(false);
    projectFirestore.collection(docCollection).doc(id).update(data)
        .then(()=>{setLoading(false)})
        .catch((error) => {
          setLoading(false);
          setError(error);
          console.error("useFirestoreDoc: Error merging document data:", error);
    });
  }

  useEffect(() => {
    setLoading(true);
    const unsub = projectFirestore.collection(docCollection)
      .doc(id)
      .onSnapshot(fdoc => {
            let doc = fdoc.data();
            if(doc){
              setStateDoc( {...fdoc.data(), id} );
              if(options.success){
                options.success(fdoc.data())
              }
            }else{
              setStateDoc( null )
              console.warn("useFirestoreDoc: Data loading notice, did not find: "+id+" in "+docCollection);
            }
            setLoading(false);
        },
      (error) => {
          setError(error);
          setLoading(false);
          console.error("Data loading: ERROR: "+id+"----"+docCollection,fdoc.data(), error);
        }
      );

    return () => unsub(); // when component is unmounted! <<<<<
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, [collection, id]);

  return { doc, loading, error, setDoc, updateDoc };
}

export default useFirestoreDoc;