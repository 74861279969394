import { useState, useEffect } from 'react';
import { projectFirestore, serverTimestamp } from './firebase';
import { ADMINCOLLECTIONS, DATADB, DATAROOT, DATATMP } from "./datapaths";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from './AuthProvider';

const useFirestoreCol = (collection, options={}) => {
  
  const docType = options.docType || 'never-match';
  const orderBy = options.orderBy || 'createdAt';
  const sortOrder = options.sortOrder || 'desc' ;

  const {userData} = useAuth();

  // choose the right collection, this is the basic case of typical user
  let docCollection = DATAROOT()+ '/' + collection;
  
  // if user userData.guid is set, it overrides the default location
  if(userData!==null && userData.guid){
    docCollection = DATADB + '/userdata/'+userData.guid+ '/' + collection;
  }
  
  // special case of data in FireStore outside auth protection (for creating a new user)
  if( collection==DATATMP ){
    docCollection = DATATMP; 
  }
  if( ADMINCOLLECTIONS.indexOf(collection)>-1 ) {
    docCollection = DATADB + '/'+collection;
  }
  
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const operator = (docType == 'never-match') ? '!=' : '==';
  const sortOrderMultiplier = sortOrder == 'asc' ? -1 : 1;

  const deleteDoc = (id)=>{
    setError(false);
    projectFirestore.collection(docCollection).doc(id).delete()
      .catch((error) => {
          setError(error);
          console.error("Error removing document: ", error);
    });
  }

  const duplicateDoc = async (id)=>{
    try {
      const oldDocRef = await projectFirestore.collection(docCollection).doc(id).get();
      const newDoc = oldDocRef.data(); 
      newDoc.id = uuidv4();
      newDoc.createdAt = serverTimestamp();
      newDoc.name = "KOPIO: "+newDoc.name || "KOPIO: "
      await projectFirestore.collection(docCollection).doc(uuidv4()).set(newDoc);   
      return newDoc;
    }
    catch(error) {
      console.error("Error in duplicating document:", error);
      setError(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    setError(false);
    const unsub = projectFirestore.collection(docCollection)
      .where("docType",operator,docType)
      .onSnapshot(snap => {
          let documents = [];
          snap.forEach(doc => {
            documents.push({...doc.data(), id: doc.id});
          });
          if(orderBy!=""){
            documents.sort(
              (a, b) => {
                if(!a.hasOwnProperty(orderBy) || !b.hasOwnProperty(orderBy) ){
                   setError("Inconsisten sorting of Firestore data: member "+orderBy+" missing.")
                   console.error("Inconsisten sorting of Firestore data: member "+orderBy+" missing.");
                };
                if(a[orderBy]==b[orderBy]){return 0;}
                return a[orderBy] > b[orderBy] ? -sortOrderMultiplier : sortOrderMultiplier; // largest first
              });
          } 
          setDocs(documents);
          setLoading(false);
        },
        (error) => {
          setError(error)
          setLoading(false);
        }
      );

    return () => unsub(); // when component is unmounted! <<<<<
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, [collection, userData]);

  return { docs, loading, error, deleteDoc, duplicateDoc};
}

export default useFirestoreCol;