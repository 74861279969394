import { Container, Grid, Slider, Typography, unstable_useEnhancedEffect } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react'
import { RDB } from '../../config/datapaths'
import { ref, onValue, update, remove } from "firebase/database";
import { Box  } from '@mui/system';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import sliderScene from "../../assets/slider/slider-scene.png"
import { makeStyles } from '@mui/styles'

import { useNavigate } from 'react-router-dom';
import { FricoContext } from '../../config/FricoProvider';

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";

// export default function GmSlider({questionText='Mitä mieltä olet väittämästä?' ,maxText='täysin samaa mieltä', minText='täysin eri mieltä'} ) {
export default function GmSlider({questionText='Mitä mieltä olet väittämästä?' ,maxText='täysin samaa mieltä', minText='täysin eri mieltä'} ) {

    const frico = useContext(FricoContext);   
    const navigate = useNavigate();
    useEffect(()=>{
        !frico.session && navigate('/');
    },[]);


    const useStyles = makeStyles( (theme) => {
        return {
            fontSize: {
                fontSize: "100%",
            },
            fontSizeCentered: {
                fontSize: "100%",
                textAlign: "center",
            },

        }
    })

    const classes = useStyles()
    const [questionTextState, setQuestionTextState] = useState(frico.session && frico.session.gameState.slider.questionText);
    const [minTextState, setMinTextState] = useState(frico.session && frico.session.gameState.slider.minText);
    const [maxTextState, setMaxTextState] = useState(frico.session && frico.session.gameState.slider.maxText);
    const [sliderValues, setSliderValues] = useState({});
    const [sliderAverage, setSliderAverage] = useState('-');
    const [playerCount, setPlayerCount] = useState(0);
    const [avatarScale, setAvatarScale] = useState(frico.session && frico.session.gameState.slider.avatarScale);

    // Show slider to players
    const sessionRef = ref(RDB, 'tmp/sessions/'+frico.sessionId);
    const usersRef = ref(RDB, 'tmp/sessions/'+frico.sessionId+'/users');
    useEffect(()=>{
        if(!frico.session) return;
        update(sessionRef, {
            watchComponent: "Slider",
            questionText: frico.session.gameState.slider.questionText,
            minText: frico.session.gameState.slider.minText,
            maxText: frico.session.gameState.slider.maxText,
          })
        return ()=> update(sessionRef, { watchComponent: "WaitSession", }) // this will be update on component unMount

    },[frico.session]);

    // =====================================================================
    // Cleanup on unmound
    // remove avatar from db on exit
    function moveAwayFromWindow(e){
        e.preventDefault();
        update(sessionRef, { watchComponent: "WaitSession", });
    }
    useEffect(() => {
        window.addEventListener('beforeunload', moveAwayFromWindow)
        return () => {
          window.removeEventListener('beforeunload', moveAwayFromWindow)
        }
      }, [])
    // =====================================================================

    // THROTTLE SAVING
    let filterTimeout = null;
    const doDelayedSave = (players,average) => {
        clearTimeout(filterTimeout)
        filterTimeout = setTimeout(() => {
          frico.session.gameState.slider.average = average;
          frico.session.gameState.slider.players = players;
          frico.sessionSave();
        }, 500)
      }

    useEffect(()=>{
        const unsub = onValue(usersRef, (snapshot) => {
            const players=snapshot.val();
            if(players){
                const arrAll = Object.keys(players);
                const existingPlayers ={};
                arrAll.map((p)=>{
                    if(players[p]["sliderValue"] !== undefined) {
                        existingPlayers[p]=players[p]
                    }
                })

                setSliderValues(existingPlayers)
                //https://gist.github.com/jrrio/55f93cdfc70209f1a7ba0a8d3bb762a2#using-objectkeys
                const arr = Object.keys(existingPlayers);
                const getSliderValue = key => existingPlayers[key]["sliderValue"];
                let avg = 0
                let N = 0
                avg = arr.reduce((a,c) => a + getSliderValue(c), 0) / arr.length;
                setSliderAverage(Number.isNaN(avg)?'-':avg);
                setPlayerCount(arr.length);
                doDelayedSave(players,Number.isNaN(avg)?0:avg);
            } else {
                setSliderValues({})
                setSliderAverage('-');
                setPlayerCount(0)
            }
        });
        return () => {
            unsub();
        };
    },[]);


    const saveToRDB = () => {
        frico.session.gameState.slider.questionText = questionTextState;
        frico.session.gameState.slider.minText = minTextState;
        frico.session.gameState.slider.maxText = maxTextState;
        frico.sessionSave();
    }

    const handleChangeQuestion = (value)=>{
        setQuestionTextState(value);
        console.log(value);
        update(sessionRef,{
            questionText:value
        })
    }

    const handleChangeMinText = (value)=>{
        setMinTextState(value);
        update(sessionRef,{
            minText:value
        })
        console.log(value);
    }

    const handleChangeMaxText = (value)=>{
        setMaxTextState(value);
        update(sessionRef,{
            maxText:value
        })
        console.log(value);
    }

    function roundedToFixed(input, digits){
        var rounded = Math.pow(10, digits);
        return (Math.round(input * rounded) / rounded).toFixed(digits);
    }

    if(frico.session ) {
        frico.session.gameState.started = true;
        frico.sessionSave();
    } 

    // https://github.com/bymi15/react-edit-text

    return (
        <Box sx={{
            width:"100vw", 
            height:"100vh", 
            textAlign:"center", 
            backgroundImage:'url("'+sliderScene+'")',
            backgroundRepeat: "repeat-x",
            backgroundColor:"#b8fff9",
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            }} >

            <Typography variant="h4" sx={{paddingTop: "5vh" }}> <EditText className={classes.fontSizeCentered} value={questionTextState} onChange={handleChangeQuestion} onBlur={saveToRDB}/> </Typography>
 
            <Grid container sx={{width:"80vw", margin:"auto"}} >
                <Grid item xs={4} sx={{textAlign: "left"}}><Typography  component="span" variant="h5"> <EditText className={classes.fontSize} value={minTextState} onChange={handleChangeMinText}/> </Typography></Grid>
                <Grid item xs={4}><Typography  component="span" variant="h5"> {""} </Typography></Grid>
                <Grid item xs={4} sx={{textAlign: "right"}}><Typography  component="span" variant="h5"> <EditText className={classes.fontSize} value={maxTextState} onChange={handleChangeMaxText}/> </Typography></Grid>
            </Grid>
 
            <div sx={{textAlign: "center", width:"100%", margin: "auto", backgroundColor:"green" }}>   
            {sliderValues &&  <Box sx={{backgroundColor:"#3498db", borderRadius: "3px", minHeight:"6px", width:"80%", margin:"auto",marginTop:"10vh",marginBottom:"10vh", }}> 

                { Object.entries(sliderValues).map(([key, value]) =>{
                                const xDelta = 80*value.sliderValue/100+10+"vw";
                                const avatarReferenceSize = 20;
                                return (
                                    <Box 
                                        key={uuidv4()}
                                        sx={{position:"absolute", 
                                            width: avatarScale/100*avatarReferenceSize+"vw", 
                                            height:avatarScale/100*avatarReferenceSize+"vw",
                                            margin:"0", 
                                            marginLeft:"calc(-0.5*"+avatarScale/100*avatarReferenceSize+"vw)", 
                                            left:xDelta,  
                                            filter: svgFilter,
                                        }}
                                    >
                                            <img 
                                                src={value.avatar}
                                                style={{
                                                    marginTop:"calc(-0.5*"+avatarScale/100*avatarReferenceSize+"vw)",
                                                 }} 
                                                
                                            />
                                    </Box>
                                )
                            
                            } 
                        ) 
                }
                    
                </Box>}
            </div>
            <Box style={{paddingTop:"10vh"}}>
                <Grid container sx={{width:"80vw", margin:"auto"}}>
                    <Grid item xs={4} sx={{textAlign: "left"}}><Typography  component="span" variant="h5"> {0} </Typography></Grid>
                    {frico.session && frico.session.gameState.gameLang===undefined && <Grid item xs={4}><Typography  component="span" variant="h5"> { sliderAverage=='-'?"" : "keskiarvo: " + roundedToFixed(sliderAverage, 1) } (N={playerCount}) </Typography></Grid>}
                    {frico.session && frico.session.gameState.gameLang=="FI" && <Grid item xs={4}><Typography  component="span" variant="h5"> { sliderAverage=='-'?"" : "keskiarvo: " + roundedToFixed(sliderAverage, 1) } (N={playerCount}) </Typography></Grid>}
                    {frico.session && frico.session.gameState.gameLang=="EN" && <Grid item xs={4}><Typography  component="span" variant="h5"> { sliderAverage=='-'?"" : "average: " + roundedToFixed(sliderAverage, 1) } (N={playerCount}) </Typography></Grid>}
                    <Grid item xs={4} sx={{textAlign: "right"}}><Typography  component="span" variant="h5"> {100} </Typography></Grid>
                    <Grid item xs={12} align="center" >
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} justifyContent="center">
                            <ZoomOut  color="primary" onClick={()=>setAvatarScale(avatarScale*0.9)} style={{cursor:"pointer", position:"static", zIndex:"1000" }}  />
                            <SizeSlider avatarScale={avatarScale} setAvatarScale={setAvatarScale} frico={frico} />
                            <ZoomIn  color="primary" onClick={()=>setAvatarScale(avatarScale*1.1)} style={{cursor:"pointer", position:"static", zIndex:"1000"}}/>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}

const SizeSlider = ({avatarScale, setAvatarScale, frico})=>{
    
    const handleChange = (event, newValue) => {
        setAvatarScale(newValue);
    }
    const handleChangeCommitted = () =>{
        frico.session.gameState.slider.avatarScale = avatarScale;
        frico.sessionSave();
    }
    //  //Math.log(Math.log(value))+10
    return (
          <Box width={200} >
            <Slider
              size="small"
              aria-label="Small"
              valueLabelDisplay="off"
              min={5}
              max={200}
              value={avatarScale} 
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
            />
          </Box>
        );
    

}