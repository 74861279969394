import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated, } from "react-spring";
import { FricoContext } from '../../../config/FricoProvider';

import "./FricoCard.css";
import cardBack from "../../../assets/cards/card-key.svg"
import cardFront from "../../../assets/cards/card-text.svg"

// Berkshire Swash
const offsetX=["30vw","15vw","0vw","-25vw","30vw","15vw","0vw","-25vw",]
const offsetY=["30vh","30vh","30vh","30vh","5vh","5vh","5vh","5vh",]

export const FricoCard = ({ 
      imageFront=cardFront, 
      imageBack=cardBack, 
      imageText="Kysymysteksti", 
      cardNumber="1",
      home,
      zArray,
      setZArray
          }) => {

  const frico = useContext(FricoContext);       
  //const {width, height}=useWindowDimensions();        

  // Hold state for selection and rating
  const [selected, setSelected] = useState(true);
  const [showBig, setShowBig] = useState(false);
  const [rotated, setRotated] = useState(frico.session && frico.session.gameState.cardsLobby[cardNumber].done);
  
  const moveTo1 = { transform:  "scale(3.5)", marginLeft:  offsetX[cardNumber-1], marginTop:  offsetY[cardNumber-1]};
  const moveTo2 = { transform:  "scale(1)", marginLeft: "0vw",                  marginTop:  "0vh"                };

  const myMove1 = showBig?[moveTo1]:[moveTo2];
  
  const goFrontStyle = useSpring({
    to:   myMove1 
  })

  // Flipping
  const { opacity, transform } = useSpring({
    config: {
      friction: 10,
      tension: 20
    },
    opacity: selected ? 1 : 0,
    transform: `rotateY(${!rotated ? 180 : 0}deg)`,
  });

  // https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
  const handleClick = (e) => {
    if (e.detail===1) {
        setSelected(!selected);
        setShowBig(!showBig);
        setRotated(true);
        //console.log("Handle click",cardNumber);
        zArray.splice(cardNumber-1, 1, selected?100:0);
        const timeout = showBig?1500:0;
        setTimeout(()=>setZArray([...zArray]), timeout);
        frico.session.gameState.cardsLobby[cardNumber].done=true;
        frico.sessionSave();
    }
  };
  if (!frico.session) return null;        
  return (
    // Card container
    <animated.div
      className="RatingsCard"
      onClick={(e) =>handleClick(e) }

      style={{ 
          ...goFrontStyle,
       }}
    >
      {/* Front */}
      <animated.div
        className="RatingsCard__front"
        style={{
          backgroundImage: `url(${imageFront})`,
          transition: "all 0s",
          //opacity: opacity.interpolate(inverseOpacity),
          backfaceVisibility: "hidden",
          transform,
        }}
      > 
        <p 
        style={{
            fontSize: (1.5- ((imageText.length<50)?0:Math.min((imageText.length-50)*0.007,0.9) )) +"vw", 
            padding:"1vw",
            paddingBottom:"2vw",
            userSelect: "none",
            pointerEvents: "none",
            }}
            dangerouslySetInnerHTML={{__html: imageText}}
        />
            
      </animated.div>
      
      {/* Back */}
      <animated.div
        className="RatingsCard__back"
        style={{
          backgroundImage: `url(${imageBack})`,
          backfaceVisibility: "hidden",
          transition: "all 0s",
          transform: transform.interpolate(inverseTransform), // rotates the card
        }}
      >
      <p 
        style={{
            fontSize:"4vw", 
            fontWeight:"800",
            color:"white",
            paddingTop:"4vw",
            paddingLeft:"2vw",
            userSelect: "none",
            pointerEvents: "none",
            transition: "all 0s",
            fontFamily: 'Roboto, Helvetica, sans-serif',
        }}
        >
            {cardNumber}
      </p>

      </animated.div>
    </animated.div>
  );
};

// Functions that interpolate the values for the flipping animation
const inverseTransform = t => `${t} rotateY(180deg)`;

export default FricoCard;
