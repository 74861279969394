import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

/* eslint react/prop-types: 0 */
import { Container, Grid } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0),
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

// #3498DB;
const StyledToggleButtonFirst = styled(ToggleButton)`
  &.MuiToggleButton-root[aria-pressed="false"] {
    background-color: lightgrey;
    color: white;
    border-radius: 0.75em 0 0 0.75em!important;
    border-width: 0;
  },
  &.MuiToggleButton-root[aria-pressed="true"] {
    background-color: #3498DB;
    color: white;
    border-radius: 0.75em 0 0 0.75em!important;
    border-width: 0;
  }

`;

const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root[aria-pressed="false"] {
    background-color: lightgrey;
    color: white;
    border-radius: 0 0 0 0!important;
    border-width: 0;
  },
  &.MuiToggleButton-root[aria-pressed="true"] {
    background-color: #3498DB;
    color: white;
    border-radius: 0 0 0 0!important;
    border-width: 0;
  }

`;

const StyledToggleButtonLast = styled(ToggleButton)`
  &.MuiToggleButton-root[aria-pressed="false"] {
    background-color: lightgrey;
    color: white;
    border-radius: 0 0.75em 0.75em 0!important;
    border-width: 0;
  },
  &.MuiToggleButton-root[aria-pressed="true"] {
    background-color: #3498DB;
    color: white;
    border-radius: 0 0.75em 0.75em 0!important;
    border-width: 0;
  }

`;

const Nav = (props) => {

    return (
        <Container fixed={true} maxWidth="md">
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                <Grid item align="center" style={{ width: '80%', paddingTop: '45px', paddingBottom: '25px' }}>
                    <StyledToggleButtonGroup exclusive size="small"  >
                        <StyledToggleButtonFirst value="1" selected={props.currentStep==1} onClick={() => props.goToStep(1)}  >Aloitus</StyledToggleButtonFirst>
                        <StyledToggleButton value="2" selected={props.currentStep==2} onClick={() => props.goToStep(2)} >Aula</StyledToggleButton>
                        <StyledToggleButton value="3" selected={props.currentStep==3} onClick={() => props.goToStep(3)} >Kivikko</StyledToggleButton>
                        <StyledToggleButtonLast value="4" selected={props.currentStep==4} onClick={() => props.goToStep(4)} >Puutarha</StyledToggleButtonLast>
                    </StyledToggleButtonGroup>
                </Grid >
            </Grid >                    
        </Container>
    );
};

export default Nav;
