// BACKUP: 
// https://console.cloud.google.com/storage/browser/ahaa-itupeli-backup;tab=objects?project=itupeli&prefix=&forceOnObjectsSortingFiltering=false
// https://console.cloud.google.com/firestore/data/itu/testing-ahaa?project=itupeli
//
// BACKUP HOW-TO
// https://levelup.gitconnected.com/how-to-back-up-firestore-easily-and-automatically-eab6bf0d7e1f
//
// RESTORE HOW-TO
// 

// One of your dependencies, babel-preset-react-app, is importing the
// "@babel/plugin-proposal-private-property-in-object" package without
// declaring it in its dependencies. This is currently working because
// "@babel/plugin-proposal-private-property-in-object" is already in your
// node_modules folder for unrelated reasons, but it may break at any time.

// babel-preset-react-app is part of the create-react-app project, which
// is not maintianed anymore. It is thus unlikely that this bug will
// ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
// your devDependencies to work around this error. This will make this message
// go away.

const firebaseConfigJSON  = {
  apiKey: "AIzaSyAc9coDj6Hreeb7Y7Um7lhqXnhKyMLF--E",
  authDomain: "itupeli.firebaseapp.com",
  projectId: "itupeli",
  storageBucket: "itupeli.appspot.com",
  messagingSenderId: "1011194807173",
  appId: "1:1011194807173:web:3fc43b00649683f57a3aec",
  databaseURL: "https://itupeli-default-rtdb.europe-west1.firebasedatabase.app/",
};

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/app-check';

// this must be becore initializeApp !!!
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN === "1";
export const fb=firebase.initializeApp(firebaseConfigJSON);

// for checking stuff
console.log("baseurl: " + process.env.REACT_APP_BASEURL)
// console.log("baseurl: " + `${process.env.REACT_APP_BASEURL_CORS}` )
// console.log("self.FIREBASE_APPCHECK_DEBUG_TOKEN", `${process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN}`)  

// ==========================================================================
// APPCHECK - needs fixing
// https://firebase.google.com/docs/app-check/web/debug-provider?authuser=0
// window.location.hostname === 'localhost'
// ==========================================================================
// key for paly.frico.app

const appCheck = firebase.appCheck();


// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
 appCheck.activate(
  `${process.env.REACT_APP_APPCHECK_KEY}`,
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
   true
 );

// ==========================================================================
// ==========================================================================

firebase.firestore().settings({ 
  timestampsInSnapshots: true, 
  merge: true, 
  ignoreUndefinedProperties: true,
});

firebase.auth().languageCode = 'fi';
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL); // allows closing browser, still logged in when reopening
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION); // allows refresh
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);  // logout after refresh

export const projectStorage = firebase.storage();
export const projectFirestore = firebase.firestore();
export const functions =  firebase.functions();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const auth = fb.auth()
export const fs = fb.firestore() 

export default firebase 