import React, {useState, useEffect} from 'react'
import {useUpdateEffect} from 'react-use';

import { DOCPATH } from '../../config/datapaths';
import axios from "axios"
import { getDefaultTemplate } from './emailTemplates/getDefaultTemplate';

import Button from '@mui/material/Button';
import { Snackbar, Tooltip, Typography } from "@mui/material";
import firebase from '../../config/firebase'
import {  sendEmail } from '../../config/helpersEmail';

// dialogue
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import LinkIcon from '@mui/icons-material/Link';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import MuiAlert from '@mui/material/Alert';

const snackMessageCopy = "Linkki on nyt kopioitu leikepöydälle, voit liittää sen haluamaasi paikkaan painamalla Ctrl-V.";
const snackMessageEmail = "Viesti on matkalla sähköpostilaatikkoosi, matka kestää muutaman minuutin. Ellei viestiä näy, tarkista myös roskapostikansio.";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// https://github.com/nygardk/react-share#readme
// remember ending slash for LinkedIn???
// LinkedIn shows "og:title"

export default function SimpleDialogSessionLink({session, urlJoinGame, anchorJoinGame}) {

    const [open, setOpen] = React.useState(false);
    const handleDialogOpen = () => {
      setOpen(true);
    };
    const handleDialogClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Tooltip 
            title="Jaa osallistumislinkki pelaajille" 
            placement="top" 
            arrow 
            >
            <Button size="small" variant="contained" endIcon={<LinkIcon />} onClick={handleDialogOpen}>
                Vastauslinkki
            </Button>
        </Tooltip>
        <SimpleDialog
          session={session}
          open={open}
          handleDialogClose={handleDialogClose}
          urlJoinGame={urlJoinGame} 
          anchorJoinGame={anchorJoinGame}
        />
      </div>
    );
  }

const SimpleDialog = (props) => {

    const { session, open, handleDialogClose, urlJoinGame, anchorJoinGame} = props;

    const [openSnack, setOpenSnack] = useState(false);
    const [snackText, setSnackText] = useState("");
    
    const handleCopyRichText = () => {
        setOpenSnack(true);
        setSnackText(snackMessageCopy);
        const blobInput = new Blob([anchorJoinGame], {type: 'text/html'});
        const clipboardItemInput = new ClipboardItem({
            'text/html'  : blobInput,
        });
        navigator.clipboard.write([clipboardItemInput]);
      };
    
      const handleCopyUrl = () => {
        setOpenSnack(true);
        setSnackText(snackMessageCopy);
        navigator.clipboard.writeText(urlJoinGame);
      };

      const handleCopyClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnack(false);
      };
  

      // ======================================================================================
      const [ready, setReady] = useState(false);
      const [htmlTemplate, setHtmlTemplate] = useState('');
      const [attachmentContent, setAttachmentContent] = useState();
  
      useEffect(() => {
          // loads just the saved email template
          const db=firebase.firestore();
          db.collection( DOCPATH('emailtemplates') ).doc(session.id).get()
          .then( (snapshot) => {
              if(snapshot.exists){
                  const data =snapshot.data();
                  setHtmlTemplate(data.html);
              } else {
                  setHtmlTemplate(getDefaultTemplate().html )
                  console.log("Setting DEFAULT template: ", getDefaultTemplate().html)
              }
              console.log("LOADED htmlTemplate", htmlTemplate);
              setReady(true)
          });
      }, []);
  
      useUpdateEffect(()=>{
          // handles images as content-ids if necessary 
          // loads the image (where ever it might be) to attachmentContent state variable
          const hrefImg=process.env.REACT_APP_BASEURL_CORS+"/images/frico-logo-600px.png"
          axios.get(hrefImg, { responseType: 'arraybuffer' })
          .then((response)=>{
              const cont = response.data; 
              const content = firebase.firestore.Blob.fromUint8Array(new Uint8Array(cont));
              setAttachmentContent( content );
          });
      },[htmlTemplate]);
  

      const sendEmailCid = () => {
        
        setOpenSnack(true);
        setSnackText(snackMessageEmail);

        console.log("htmlTemplate: ",htmlTemplate);
        const subject ='FriCo–viesti sinulle: tule mukaan pelituokioon!';
        // const text = "Liity peliin linkistä: \n\n"+process.env.REACT_APP_BASEURL+'/watch/'+ session.id+'/'+'default-player'+"\n\n";
        const text = "Liity peliin linkistä: \n\n" + urlJoinGame +"\n\n";
        const filename ='frico-logo.png'
    
        // apply content-ids  if needed
        const cid = "frico-logo.png"
        const regexImg = new RegExp( /<img.*?src="(.*?)"[^>]+>/, 'g');
        const imgUrlArray = regexImg.exec(htmlTemplate);
        let htmlWithCid = '';
        if(imgUrlArray){
            const cidUrl ='cid:frico-logo.png';
            htmlWithCid = htmlTemplate.replace(imgUrlArray[1], cidUrl);
        }else{
            htmlWithCid = htmlTemplate;
        }

        const html = htmlWithCid.replace('XXXXXX',urlJoinGame);

        // sendEmail({to='', subject='', text='', html='', attachments=[] })
        sendEmail({
                to:firebase.auth().currentUser.email,
                subject,
                text,
                html, 
                attachments: [ // array of objects
                    {   
                        filename: filename,
                        content: attachmentContent,
                        cid ,
                    }
                ]
            });
    }

    // ==================================================================

    return (
    <>
      <Dialog open={open} onClick={handleDialogClose}>
        <DialogTitle>
            Osallista pelaajia välittämällä heille vastauslinkki
            <Typography>Linkin kautta pelaaja voi vastata liukuasteikko ja tekstikenttäkysymyksiin omalla tietokoneellaan tai puhelimellaan.</Typography>
        </DialogTitle>
        
        <List sx={{ pt: 0 }}>

            <ListItem sx={{paddingTop:"0px",paddingBottom:"0px"}} onClick={handleCopyRichText} button={true}>
                <ListItemIcon>
                    <FileCopyOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Kopioi weblinkki Teams-chattiin"
                    secondary="valitse tämä, kun haluat siirtää weblinkin esimerkiksi Teams-chattiin tai Word-dokumenttiin"
                />
            </ListItem>

            <ListItem sx={{paddingTop:"0px",paddingBottom:"0px"}} onClick={handleCopyUrl} button={true}>
                <ListItemIcon>
                    <LinkIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Kopioi pelkkä verkko-osoite (https://...)"
                    secondary="valitse tämä, kun haluat siirtää osoitteen suoraan web-selaimen osoitekenttään"
                />
            </ListItem>

            <ListItem sx={{paddingTop:"0px",paddingBottom:"0px"}} onClick={sendEmailCid} button={true}>
                <ListItemIcon>
                    <ForwardToInboxIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Lähetä itsellesi weblinkin sisältävä sähköposti"
                    secondary="valitse tämä, kun haluat omatoimisesti välittää linkin sähköpostilla osallistujille"
                />
            </ListItem>

        </List>
      </Dialog>
      <Snackbar
            anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
            autoHideDuration={8000}
            onClose={() => setOpenSnack(false)}
            open={openSnack}
        >
            <Alert onClose={handleCopyClose} severity="success" sx={{ width: '100%' }}>{snackText}</Alert>
        </Snackbar>
    </>
    );
  }
  
 





