import { Container, Grid, Typography } from '@mui/material';
import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'
import { useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { shuffle } from '../../config/helpers';

//import images from local
import avatarPaths from "../../assets/avatars";
shuffle(avatarPaths);

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";

function SelectAvatar({setAvatar, gamelang}) {

    // Hacky stuff, image picker is not one of the best comps
    const theme = useTheme();
    const useStyles = makeStyles( (theme) => {
        return {
            scroll: {
                overflow: "auto",
                height:"100%",
            },
            avatar: {
                "& .selected": {
                    opacity:"1!important",
                    border:"none!important",
                    zIndex:"1000!important"
                },
                "& .selected:hover": {
                    boxShadow:"none!important",
                },
                "& .thumbnail": {
                    border:"none!important",
                },
                "& .responsive .thumbnail:hover": {
                    boxShadow:"0 0 4px 3px rgb(0 140 186 / 70%)",
                },
                "& div": {
                    "& div": {
                        height:"150px",
                        width:"150px",
                        "& img": {
                            objectFit: "contain!important",
                            filter: svgFilter,
                            transform: "scale(0.9)",  
                        },
                        "& img:hover": {
                            boxShadow: "0 0 13px 12px rgb(0 140 186 / 70%)",  
                        },

                    }
                }
            },
        }
    })
    const classes = useStyles()

    const onPick = (image) => {
        //setImage({image})
        const avatar=document.querySelector("[src='"+image.src+"']");
        document.querySelector("[src='"+image.src+"']").style.transform = "scale(1)";
        document.querySelector("[src='"+image.src+"']").style.backgroundColor = "transparent";
        document.querySelector("[src='"+image.src+"']").style.transitionDuration = "0.5s";
        document.querySelector("[src='"+image.src+"']").style.transitionTimingFunction = "ease";
        document.querySelector("[src='"+image.src+"']").style.filter= "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.9))";
        setAvatar(image.src);
       // setTimeout(()=>{setAvatar(image.src)},2000)
    }

    // https://github.com/bagongkia/react-image-picker
    // shuffle images
    return (
        <Container className={classes.scroll} >
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{paddingTop: "20px"}}>
                <Grid item  >
                    {(gamelang=="FI") && <Typography variant="h4">Ole hyvä ja valitse itsellesi ensin pelihahmo:</Typography>}
                    {(gamelang=="EN") && <Typography variant="h4">Please choose your avatar first:</Typography>}
                </Grid>
                <Grid item  >
                    <div className={classes.avatar}>
                        <ImagePicker 
                            images={avatarPaths.map((path, i) => ({src: path, value: i}))}
                            onPick={(props)=>{onPick(props)}}
                            gamelang={gamelang}
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SelectAvatar