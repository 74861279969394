import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import firebase from '../../config/firebase'
import SummarizeIcon from '@mui/icons-material/Summarize';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {

  const {collection, handleTemplateChange} = props;
  const [templates, setTemplates] = React.useState([]);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    const db=firebase.firestore();
    const temps=[]
    db.collection( collection ).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        temps.push(doc.id);
      })
      setTemplates(temps);
      setReady(true)
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  }, [collection]); 


  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    handleTemplateChange(value);
    onClose(value);
  };

  return ready && (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Valitse viestipohjamalli:</DialogTitle>
      <List sx={{ pt: 0 }}>
        {templates.map((template) => (
          <ListItem button onClick={() => handleListItemClick(template)} key={template}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <SummarizeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={template} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function TemplateDialog( {collection, handleTemplateChange}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <IconButton 
        color="primary" 
        component="span"                
        sx={{margin: "7px", alignSelf: 'flex-start'}}
        size="large"
        onClick={handleClickOpen}
    >
        <SettingsIcon/>
    </IconButton>
    <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        collection={collection}
        handleTemplateChange={handleTemplateChange}
      />
    </div>
  );
}