import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { billingLogColumns, getUserBilling, LogDataGrid } from '../../../config/logging';

import firebase from "../../../config/firebase";
import { Controller } from 'react-hook-form';
import { Divider } from 'react-materialize';


// billingAddress:'',
// billingVATNumber:'',
// billingMethod:'',
// billingMessage:'',


function Billing({userDetails, control, errors, }) {
  const [log, setLog] = useState([]);  

  useEffect(() => {
    getUserBilling({uid:firebase.auth().currentUser.uid,setLog:setLog});
    }, [])

  return (<>
    <Grid container alignContent={'center'} spacing={2} >

        <Grid item xs={8} > 
            <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="h6">Valitse haluamasi laskutustapa</Typography></FormLabel>
                <Controller
                    rules={{ required: true }}
                    defaultValue='email'
                    control={control}
                    name="billingMethod"
                    render={({ field }) => (
                        <RadioGroup {...field}>
                            <FormControlLabel
                                value="email"
                                control={<Radio />}
                                label="Sähköposti"
                            />
                            <FormControlLabel
                                value="verkkolasku"
                                control={<Radio />}
                                label="Verkkolasku"
                            />
                        </RadioGroup>
                    )}
                />
            </FormControl>
        </Grid >

        <Grid item xs={8} >
            <Controller
            name="billingName" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        {...field}
                        label="Maksajan nimi"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>

        <Grid item xs={8} >
            <Controller
            name="billingEmail" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        {...field}
                        label="Maksajan sähköpostiosoite"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>

        <Grid item xs={8} >
            <Controller
            name="billingMobile" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        {...field}
                        label="Maksajan puhelinnumero"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>

        <Grid item xs={8} >
            <Controller
            name="billingVATNumber" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        {...field}
                        label="Maksajan Y-tunnus"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>

        <Grid item xs={8} >
            <Controller
            name="billingAddress" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        multiline={true}
                        minRows={3}
                        {...field}
                        label="Maksajan kirjepostiosoite"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>
        <Grid item xs={8} >
            <Controller
            name="billingNetPayInfo" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        multiline={true}
                        minRows={2}
                        {...field}
                        label="Verkkolaskutustiedot, kun laskutustavaksi on valittu verkkolasku"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>
        <Grid item xs={8} >
            <Controller
            name="billingInfo" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                        multiline={true}
                        minRows={3}
                        {...field}
                        label="Lisätietoa laskutukseen liittyen"
                        variant="standard"
                        margin="dense"
                        sx={{width:"75%"}}
                    />
                )}
            />
        </Grid>

    </Grid>

    <Box sx={{paddingTop:"30px"}}> 
        <Divider sx={{marginTop:"20px",marginBottom:"20px"}}></Divider>
    </Box>
    <Box sx={{paddingTop:"30px"}}> 
        <LogDataGrid rows={log} columns={billingLogColumns} />
    </Box>
    </>)
}

export default Billing