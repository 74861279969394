import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from "react-hook-form";
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { FricoContext } from '../../config/FricoProvider';
import BasicInfo from './Tabs/BasicInfo';
import Enroll from './Tabs/Enroll';
import Library from './Tabs/Library';
import Settings from './Tabs/Settings';
import Some from './Tabs/Some';
import { Alert, Backdrop, Button, FormControl, Grid, IconButton } from '@mui/material';
import { throttleFn } from '../watch/Watch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const  schema = yup.object().shape({
    name:           yup.string().min(2).max(100).required(),
    description:    yup.string().min(2).max(100).required(),
    startsAt:       yup.number().min(1).required(), // refers to timestamp value
    endsAt:         yup.number().min(1).required(), // refers to timestamp value
    email:          yup.string().email().required(),
    mobile:         yup.string().matches(/^([0-9-\s\+]){6,20}$/, {
                          message: "Puhelinnumero ei kelpaa",
                          excludeEmptyString: false,
                }),
  });

export default function EditSessionTabs() {

  const SAVE_THROTTLE = 500; // limit saving 
  
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0);
  const [fSaving, setFSaving] = useState(false);
  
  const {sessionid} = useParams();
  const frico = useContext(FricoContext);  
  frico.setSessionId && frico.setSessionId(sessionid)

  useEffect(()=>{
    if(!frico.session) return;
    frico.setSessionId(sessionid);
  },[frico.sessionReady,sessionid]);

  useEffect(()=>{
    // this loads the form data
    reset(frico.session);
    // console.log("frico.session", frico.session);
    return ()=>{
        reset({});
        frico && frico.setSessionId && frico.setSessionId(null)
    }
  },[frico.sessionReady]);

  // force save under unmount
  
  var {
      reset,
      control,
      handleSubmit,
      formState,
      trigger,
      register
    } = useForm({ 
                    mode: "onBlur",
                    resolver: yupResolver(schema),
                    shouldFocusError: true,
                    criteriaMode: "onBlur",
                    reValidateMode: "onBlur"
                  });

  const {errors, isDirty, isSubmitting, isSubmitSuccessful, touchedFields, submitCount, isValid, isSubmitted} = formState;
  
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleSave = useCallback( throttleFn( (data) => {
    setFSaving(true);
    setTimeout(()=>{setFSaving(false)},2000)
    frico.sessionSaveData({...data, modified:Date.now()});
    console.log("=======>>>>>>>>>>>>>>> SAVING")
    //console.log(data);
  },SAVE_THROTTLE),[frico.sessionSaveData]); // needs to be recalculated once we have frico.sessionSaveData!


  //const handleSave = useCallback( throttleFn( (data) => {
  //  console.log("Saving... ",data);
  //  setFSaving(true);
  //  setTimeout(()=>{setFSaving(false)},2000)
  //  frico.sessionSaveData({...data, modified:Date.now()});
  //  console.log("=======>>>>>>>>>>>>>>> SAVING")
  //},SAVE_THROTTLE),[frico.sessionSaveData]); // needs to be recalculated once we have frico.sessionSaveData!


  const handleError = (err) => {
    console.error("FriCo: Error in Tab form",err);
  }

  if(!frico.sessionReady) return null;  
  return (
    <>
        <Box sx={{ width: '100%',paddingLeft:"50px",paddingRight:"50px" }}>
            <Box display="flex" justifyContent="center" width="100%" sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs 
                    value={tabValue} 
                    onChange={handleChange} 
                    aria-label="basic tabs example"  
                    variant="scrollable" 
                    scrollButtons="auto" 
                    sx={{ alignItems: "center"}}
                    >
                    <Tab label="Perustiedot" {...a11yProps(0)} />
                    <Tab label="Kirjasto" {...a11yProps(1)} />
                    <Tab label="Ilmoittautuminen" {...a11yProps(2)} />
                    <Tab label="SOME" {...a11yProps(3)} />
                    {false && <Tab label="Asetukset" {...a11yProps(4)} />}
                </Tabs>
            </Box>
            <FormControl >
            <form onChange={ handleSubmit(handleSave, handleError)}>
                <TabPanel value={tabValue} index={0} >
                    <BasicInfo control={control} formState={formState} register={register}/>
                </TabPanel>
                {frico.session.gameState["cardsLibrary"] !== undefined && <TabPanel value={tabValue} index={1}>
                    <Library control={control} formState={formState} trigger={trigger} handleSubmit={handleSubmit(handleSave, handleError)}/>
                </TabPanel>}
                <TabPanel value={tabValue} index={2}>
                    <Enroll control={control} formState={formState} trigger={trigger} handleSubmit={handleSubmit(handleSave, handleError)}/>                
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Some control={control} formState={formState} trigger={trigger} handleSubmit={handleSubmit(handleSave, handleError)} seoCanonicalUrl={frico.session.seoCanonicalUrl}/>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <Settings control={control} formState={formState} trigger={trigger} handleSubmit={handleSubmit(handleSave, handleError)}/>
                </TabPanel>
                <Grid container justifyContent="left" sx={{paddingLeft:"25px"}}>
                    <Box>
                        <Button 
                            sx={{marginRight:"15px"}}
                            variant="contained"
                            size="small"
                            onClick={()=>{
                                    navigate('/sessionlist')
                                    handleSubmit(handleSave, handleError)
                                } } 
                            >
                            <ArrowBackIcon sx={{marginRight:"10px"}}/> Palaa
                        </Button> 

                    </Box>
                    <Box>
                        {!isValid && <Alert severity="error">Lomakkeen välilehdellä on virheitä, ei voida tallentaa</Alert>}
                        { isSubmitSuccessful && fSaving && <Alert severity="success">Lomake tallentui onnistuneesti</Alert>}
                        { isSubmitted && !isSubmitSuccessful && <Alert severity="error">Lomakkeen tallennus ei onnistu</Alert>}
                    </Box>                        
                </Grid>
            </form>
            </FormControl>          
        </Box>
    </>
  );
}


// =====================================================================================
//  Tab helpers
// =====================================================================================

//import { StateMachineProvider, createStore, useStateMachine } from "little-state-machine";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  


