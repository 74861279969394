import { onValue, ref } from '@firebase/database';
import { Container, CssBaseline, Grid, Stack, Typography,Box, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { RDB } from '../../config/datapaths'
import sliderScene from "../../assets/slider/slider-scene.png"
import { v4 as uuidv4 } from 'uuid';
import { AutoFixHighSharp } from '@mui/icons-material';
import axios from 'axios';
import { SendServerMessage } from '../../config/helpers'

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";

export default function WatchTextbox({sessionid, avatar, textboxValue="", handleTextboxValuesSend, gamelang}) {

    //console.log("... in WatchTextbox");
    const [textboxLocal, setTextboxLocal] = useState(textboxValue);
    const [comments, setComments] = useState([]);
    //const Thumb = (props) => <ThumbComponent {...props} avatar={avatar}/>
    // const refThumb = <ThumbComponent avatar={avatar}/> 

    //backgroundImage: "url("+avatar+")",
    return (
        <Box sx={{
            width:"100vw", 
            height:"100vh", 
            textAlign:"center", 
            backgroundImage:'url("'+sliderScene+'")',
            backgroundRepeat: "repeat-x",
            backgroundColor:"#b8fff9",
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            }} >
            <img src={avatar} alt="Avatar" style={{ paddingTop: "3vh", width: '200px', height: '200px' }} />
            {(gamelang=="FI") && <Typography variant="h4" sx={{paddingTop: "1vh", paddingBottom: "5vh" }}>Matkalla tekemiäni havaintoja: </Typography>}
            {(gamelang=="EN") && <Typography variant="h4" sx={{paddingTop: "1vh", paddingBottom: "5vh" }}>Observations I made in the Castle: </Typography>}


            <div sx={{textAlign: "center", width:"100%", margin: "auto", backgroundColor:"green", }}>   
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                    <Grid item align="center" style={{ width: '80%' }}>
                        <TextField 
                            multiline 
                            maxRows={2} 
                            minRows={2} 
                            InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                },
                            }}
                            sx={{ input: { backgroundColor: 'white' }, paddingBottom: "3vh", }}
                            type="text" 
                            placeholder={(gamelang=="EN")?"Your observations":"Listaa havaintojasi..."}
                            variant="outlined" 
                            fullWidth 
                            onChange={(e)=>setTextboxLocal(e.target.value)} 
                            value={textboxLocal}
                            onKeyPress= {(e) => {
                                if (e.key === 'Enter') {
                                    handleTextboxValuesSend(e,textboxLocal);
                                    setComments([...comments, textboxLocal]);
                                    setTextboxLocal("")
                                }
                        }}
                        />
                    </Grid >
                    <Grid item sx={{width:"80%", textAlign:"center"}}>
                        {(gamelang=="FI") && <Button 
                            type="submit"
                            sx={{paddingTo:"5vh"}} 
                            size="medium" 
                            variant="contained" 
                            color="primary" 
                            onClick={(e)=>{
                                    console.log("Button click in WatchTexbox")
                                    handleTextboxValuesSend(e,textboxLocal);
                                    SendServerMessage({message: textboxLocal, category: "frico-textbox"});
                                    setComments([...comments, textboxLocal]);
                                    setTextboxLocal("")
                                }}
                            >
                            Lähetä
                        </Button>}
                        {(gamelang=="EN") && <Button 
                            type="submit"
                            sx={{paddingTo:"5vh"}} 
                            size="medium" 
                            variant="contained" 
                            color="primary" 
                            onClick={(e)=>{
                                    handleTextboxValuesSend(e,textboxLocal);
                                    SendServerMessage({message: textboxLocal, category: "frico-textbox"});
                                    setComments([...comments, textboxLocal]);
                                    setTextboxLocal("")
                                }}
                            >
                            Send
                        </Button>}

                    </Grid >
                    
                    <Grid item sx={{width:"80%", textAlign:"left"}}>
                        {comments.length!=0 && gamelang=="FI" && <Typography variant="h5" sx={{paddingTop: "0vh", paddingBottom: "0vh" }}> Lähettämäsi viestit: </Typography>} 
                        {comments.length!=0 && gamelang=="EN" && <Typography variant="h5" sx={{paddingTop: "0vh", paddingBottom: "0vh" }}> Your messages: </Typography>} 
                        {comments.map(c=><p key={uuidv4()}>{"> "+c}</p>)}
                    </Grid >                    
            </Grid >                        
        </div>

            <Typography variant="h5" sx={{ paddingTop: "5vh"}}></Typography>

        </Box>
    )
}


