import { projectFirestore, serverTimestamp } from './firebase';
import { DATAROOT, DATATMP,DOCPATH } from '../config/datapaths';
import { v4 as uuidv4 } from 'uuid';
import { getUserFileDetails } from './userProfile';

const newDoc =  async (collection, options={}) => {

    const id = options.id || uuidv4();
    const data = options.data || {};
    const docType = options.docType || "data-docType-not-set";
    
    let docCollection = DOCPATH(collection);

    if( collection==DATATMP ){
        docCollection = DATATMP; // special case of data in FireStore outside auth protection (for creating a new user)
    }
    
    let doc = null;
    try {
        const uData = collection==DATATMP ? {} : await getUserFileDetails(); // no userdata available for a temp user
        const newDocData = {...data, ...uData, id, docType, createdAt: serverTimestamp() }
        // console.log(newDocData);
        await projectFirestore.collection(docCollection).doc(id).set(newDocData);
        doc = await projectFirestore.collection(docCollection).doc(id).get();
    }
    catch(err) {
        console.error(err);
    }
    return doc.data();
}

export default newDoc;