import React, { useState, useEffect } from 'react';
import Nav from './nav';
import transitions from './transitions.module.scss';
import firebase from '../../config/firebase'
import { RDB } from '../../config/datapaths'
import StepWizard  from 'react-step-wizard'
import ImageCard from '../imageCard/imageCard'
import TextField from '@mui/material/TextField';
import { Button, Container, Grid, Paper, Slider, Typography } from '@mui/material';
import PrintState from '../../config/PrintState';
import { ref, onValue } from "firebase/database";
import SettingsArray from './SettingsArray';
import { useUnmount } from 'react-use';
import { unflattenObject, useObject } from '../../config/helpers'
import { getDefaultGameData } from '../game/defaultGames';
import useFirestoreDoc from '../../config/useFirestoreDoc';

// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//useFirestoreDoc("games",gameid, {succes: game=>setState({form:{...state.form, ...game}, ready: true}) } )

//  xs, extra-small: 0px
//  sm, small: 600px
//  md, medium: 900px
//  lg, large: 1200px
//  xl, extra-large: 1536px

/**
 * A basic demonstration of how to use the step wizard
 */
const GameWizard = ({gameid, collection}) => {

    const [state, setState ] = useObject({
        form: getDefaultGameData(),
        ready: false,
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        }
    });

    //https://github.com/CSFrequency/react-firebase-hooks/tree/master/firestore#usedocumentdata
/*     const [sliderValue, sliderLoading, error] =  useDocumentData(
        firebase.firestore().doc(DATAROOT()+'/tmpView/mySlider'),
        {
          snapshotListenOptions: { includeMetadataChanges: true },
        }
      );
 */
    const [sliderValue, setSliderValue] = useState(0);

    const sliderRef = ref(RDB, 'tmp/slider');      
    useEffect(()=>{
        return onValue(sliderRef, (snapshot) => {
            const data = snapshot.val();
            setSliderValue(data);
    });
    },[]);

    useEffect(() => {
        const db=firebase.firestore();
        db.collection( collection ).doc(gameid).get()
        .then( (snapshot) => {
            let newForm = {...state.form};
            setState({
                ...state,
                form : Object.assign(newForm, snapshot.data()),
                ready: true
            });
        });
    }, []);

    // save state on unmount
    // https://github.com/streamich/react-use
    useUnmount(() => {
            const { form } = state;
            const db=firebase.firestore();
            db.collection(collection).doc(gameid).set(form)
            .catch((error) => {
                console.error("Error saving document: ", error);
            });
        });

    // Do something on step change
    const onStepChange = (stats) => {
        const { form } = state;
        const db=firebase.firestore();
        db.collection(collection).doc(gameid).set(form)
        .catch((error) => {
            console.error("Error saving document: ", error);
        });
    };

    const handleChange = (objUpdate) => {
        setState(Object.assign(state,  {form: unflattenObject(objUpdate)} ));
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h5" align="center">    
                FriCo-pelin määrittelyvelho
            </Typography>
            <StepWizard
                onStepChange={onStepChange}
                isHashEnabled
                transitions={state.transitions} // comment out for default transitions
                nav={<Nav state={state} />}
            >
                <First state={state} handleChange={handleChange} />

                <Second 
                    state={state}
                    cardArray='cardsLobby'
                    handleChange={handleChange} 
                    title="Määrittele tässä aulanäkymän kortit:"
                    settings={<SettingsArray state={state} setState={setState} cardArray='cardsLobby' />} 
                />
                
                <Second 
                    state={state} 
                    cardArray='cardsRockyPath' 
                    handleChange={handleChange} 
                    title="Määrittele tässä kivikon kortit:"
                    settings={<SettingsArray state={state} setState={setState} cardArray='cardsRockyPath' />} 
                />

                <Final />

            </StepWizard>
            <Grid item sx={{ width: '50%' }} >
                <Grid item sx={{ width: '50%' }} >
                      {sliderValue && <Slider aria-label="Volume" value={sliderValue} />}
                      <PrintState state={sliderValue}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GameWizard;


/** Steps */
const First = (props) => {
    
    const {state, handleChange} = props;

    return (
        <Container fixed={true} maxWidth="md">
            <Paper>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                    <Grid item align="left" style={{ width: '80%', paddingTop: '25px', paddingBottom: '25px' }}>
                        <Typography variant="h5" align="center">Anna pelille nimi ja lyhyt kuvaus:</Typography>
                    </Grid >
                    <Grid item align="center" style={{ width: '80%' }}>
                        <TextField 
                            type="text" 
                            label="Pelin nimi" 
                            variant="outlined" 
                            fullWidth 
                            onChange={(e)=>{handleChange({"name":e.target.value})}} 
                            value={state.form.name }
                        />
                    </Grid >
                    <Grid item align="center" style={{ width: '80%' }}>
                        <TextField 
                            type="text" 
                            label="Pelin kuvaus" 
                            variant="outlined" 
                            fullWidth 
                            onChange={(e)=>{handleChange({"description":e.target.value})}} 
                            value={state.form.description} 
                            multiline
                            maxRows={4}
                            minRows={4}
                        />
                    </Grid >
                    <Grid item align="left" style={{ width: '80%', paddingTop: '25px', paddingBottom: '25px' }}>
                        <Typography variant="h5" align="center">Valitse pelin aloitusnäkymä:</Typography>
                    </Grid >
                    <Grid item align="center" style={{ width: '100%' }}>
                        <ImageCard 
                                img={state.form.startImage}
                                notext={true}
                                docType={"view"}
                                handleChange={(url)=>handleChange({"startImage":url})}
                            />
                    </Grid >

                    <Grid item align="center" style={{ width: '80%', paddingTop: '25px', paddingBottom: '25px' }} ><Stats step={1} {...props} /></Grid >

                </Grid >
            </Paper>
        </Container>
    );
};

const Second = props => {

    const {state, cardArray, handleChange, title, settings} = props;
    
    return (
        <Grid container spacing={3}>

            <Grid item sx={{ width: "100%" }}>
                <Typography variant="h5" align="center">
                    <> { settings } </> {title}
                </Typography >
            </Grid>

            {
            [1,2,3,4,5,6,7,8].slice(0, state.form[cardArray]["imageCount"]).map((i)=>
                        <ImageCard 
                            state={state}
                            key={i}
                            img={state.form[cardArray][i].img}
                            text={state.form[cardArray][i].text}
                            handleChange={(img,text)=>handleChange( { [cardArray+"."+i+".text"]: text, [cardArray+"."+i+".img"]:img})   }
                            docType="cardFront"    
                        />) 
            }
            <Grid item sx={{ width: "100%" }} align="center"><Stats step={2} {...props} /></Grid>                    

        </Grid >

    );
};

const Final = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };
    return (
        <Grid container spacing={3}>
            <Grid item sx={{ width: "100%" }}>
                <Typography variant="h5" align="center">
                    Tässä viimeisessä vaiheesssa ei ole tällä hetkellä mitään käyttäjän muokattavissa olevia asetuksia.
                </Typography >
            </Grid>

            <Grid item sx={{ width: "100%" }} align="center"><Stats step={3} {...props} /></Grid>                    

        </Grid >
    );
};



/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
 const Stats = ({ nextStep, previousStep, totalSteps, step }) => (
    <div>
        { step > 1 && <Button size="medium" variant="contained" color="primary" onClick={previousStep}>Palaa edelliseen</Button> }
        { step < totalSteps-1 ?
            <Button size="medium" variant="contained" color="primary" onClick={nextStep} sx={{marginLeft: "10px"}} >Jatka seuraavaan </Button> :
            false && <Button size="medium" variant="contained" color="primary" onClick={nextStep} sx={{marginLeft: "10px"}} >Tallenna</Button>
        }
    </div>
);
