import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ImageText({ idState, setIdState, text, handleChange}) {
  const handleClose = () => {
    setIdState({...idState, textOpen:false});
  };

  return (
      <Dialog  open={idState.textOpen} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="form-dialog-title">Anna kortin kysymysteksti:</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="text"
            label="Kortin kysymysteksti"
            type="text"
            fullWidth
            value = {text}
            onChange={(e)=>handleChange(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key == "Enter"||e.keyCode == 13  ){
                handleClose(); 
              }
            }
          }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}
