var start  = new Date();
start.setDate(start.getDate() + 1)
start.setHours(9);
start.setMinutes(0);
start.setMilliseconds(0);

var end  = new Date();
end.setDate(end.getDate() + 1)
end.setHours(11);
end.setMinutes(15);
end.setMilliseconds(0);

//end.getTime()

const defaultSession = {
    sessionLanguage:        "fi",
    startsAt:               start.getTime(),
    endsAt:                 end.getTime(),
    duration:               '2 h 15 min',
    maxParticipants:        8,
    verifyEmail:            {
                                    design:{}, 
                                    html:"Hei, tervetuloa osallistumaan FriCo-pelituokioon (email default text)", 
                                    title: "Tervetuloa osallistumaan FriCo-pelituokioon (email default title)" 
                            }, 
    players:                {
                            },
                            // "Ilmoittaudu-mukaan-peliin" is defined in firebase.json, TAG can be enything unique
                            //"https://play.frico.app/Ilmoittaudu-mukaan-peliin/TAG",
    seoEnrollTag:           "seoEnrollDataId-default",
    enrollText:             "<H2>FriCo-vuorovaikutuspeli</H2><p>Tutustu työelämän ammatillisiin vuorovaikutustaitoihin pelillisen seikkailun kautta.</p>",
    seoImage:               "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2FPeopleBanner-01.jpg?alt=media&token=4f033313-b01f-4a5c-8b50-884f2d2146c3",
    seoTitle:               "Tule mukaan FriCo-vuorovaikutuspeliin",
    seoDescription:         "Tutustu työelämän ammatillisiin vuorovaikutustaitoihin pelillisen seikkailun kautta.",
    seoCanonicalUrl:        "https://play.frico.app/Ilmoittaudu-mukaan-peliin/ERROR-seoCanonicalUrl-default",

}                            

export default defaultSession;

