import { Box, Grid, TextField, Typography, Tooltip, Button } from '@mui/material'
import { React, useState } from 'react'
import { FImage, triggerInputChange } from './BasicInfo';
import { Controller, useController } from 'react-hook-form'
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import TextEditor from './TextEditor/TextEditor';

import { throttleFn } from '../../watch/Watch';
import { connectDatabaseEmulator } from 'firebase/database';
import draftToHtml from 'draftjs-to-html';
import { Edit } from '@mui/icons-material';

export default function Library( { control, formState, handleSubmit}) {
  const cards=[1,2,3,4,5,6,7,8];
  return cards.map((i)=> <LibraryCard key = {i} number={i} name={"gameState.cardsLibrary.books["+i+"]"} control={control}/>);
}

export function LibraryCard( {name, control, number }) {

  return (

    <Grid container alignContent={'left'} spacing={2}  >
    
    <Grid item xs={12}>
      <Typography variant="h5" align="left" sx={{fontWeight:"bold", color:"222222", backgroundColor:"#CCCCCC", paddingLeft:"20px"}}>
        Muokkaa tekstiä/kuvaa kirjassa numero {number} :
      </Typography>
    </Grid>
    
    <Grid item xl={6}>
      <Controller 
            name={name+".question"} 
            defaultValue=""
            control={control} 
            render={({ field })=> (
              <>
                <Box sx={{width:"400px", textAlign:"right"}}>
                  <TextEditor 
                    defaultValue={field.value} 
                    onChange={
                      (html)=>{
                          field.onChange(html);
                        }
                      }
                    onBlur={(event, editorState) => {
                           //console.log("========= On blur ============ library",draftToHtml(convertToRaw(editorState.getCurrentContent())));
                           triggerInputChange(field.name, draftToHtml(convertToRaw(editorState.getCurrentContent())))
                        }}
                  />

                  {false && <Tooltip title="muokkaa kuvaa" placement="top" arrow >
                    <Button size="small" variant="contained" endIcon={<Edit />} onClick={()=>{}} sx={{textAlign:"right"}}>
                            Muokkaa tekstiä
                    </Button>
                  </Tooltip>
                  }
                </Box>

                <TextField  style={{visibility:"hidden", height:0, padding:0, margin:0}} id={field.name} margin="dense" />
              </>
            )}
      />
      </Grid>

      <Grid item xl={6}  >
        <FImage 
              type="image"
              docType="frico-task"
              name={name+".task"}
              label="Kuva"
              control={control}
          />
      </Grid>


    </Grid>             

  )
}


