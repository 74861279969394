import { Container, Grid, Slider, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { DATAROOT, RDB } from '../../config/datapaths';
import GameWizard from '../gameWizard/GameWizard';
import GameCard from './GameCard';
import { set, ref } from "firebase/database";
import useFirestoreCol from '../../config/useFirestoreCol';
import newDoc from '../../config/newDoc';
import { uiSetup } from '../../config/userProfile';
import PrintState from '../../config/PrintState';
import { isEmpty, UserSelector } from '../../config/helpers';
import { useAuth } from '../../config/AuthProvider';
import { getDefaultGameData } from './defaultGames';


export function newGame() {
  const newGame = newDoc(
      "games",
      {
        data:getDefaultGameData()
      }
    );
  return  newGame;
}

export function EditGame() {
    const { gameid } = useParams();
    return (
        <GameWizard gameid={gameid} collection={DATAROOT()+'/games'}/>
    )
}

export const GameList = () => {
    
    const { docs, loading, error } = useFirestoreCol("games");
    const {userData:authInfo} = useAuth();

    return (
      uiSetup('Application') && <div>
          {error && <Typography variant="h6" >Error: {JSON.stringify(error)}</Typography>}
          {loading && <span> </span>}
          {docs && (
            <Container fixed={true} maxWidth="xs"  >
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
                  <Grid item><UserSelector  /></Grid> 
                  <Grid item >
                    <Typography variant="h5" >
                      {!isEmpty(authInfo) && authInfo.guid ? <span>Ryhmän jäsenen pelit:</span> : <span>Sinun pelisi:</span> }
                    </Typography >                        
                  </Grid>                    
                  {docs.map((doc) => (
                    <Grid item key={doc.id} style={{ width: '100%' }} >
                        <div><GameCard doc={doc}  /></div>
                    </Grid>                                                    
                  ))}
              </Grid>
          </Container>                
        )}
      </div>
    );
};

