import React, { useContext, useState, useEffect } from "react"
import firebase, { auth, fs,  } from "./firebase"
import {DATADB, DATATMP} from './datapaths'
import { useNavigate } from "react-router"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

//https://github.com/WebDevSimplified/React-Firebase-Auth/blob/master/src/contexts/AuthContext.js
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()  

  console.debug("Authprovider: >>>>>>>>>> STARTING");

  let authReload = false;

  function anonymousLogin() {
    setLoading(true);
    console.log("AuthProvider: Setting loading true");
    return auth.signInAnonymously()
  }
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  // update user if we have user
  useEffect( () => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setCurrentUser(user);
        if(!user){    
          console.debug("auth provider: user non-existent");
          setUserData(null)
          setCurrentUser(null)
          setLoading(false); // allow router to show stuff...
          return;
        }

        console.debug("auth state changed: user logged in");

        // we have a anonymous user
        if(user.isAnonymous){
          console.debug("auth provider: we have anonymous user:", user.uid );
          setLoading(false); // allow router to show stuff...
          return;
        } 

        // we have a logged in user, whose email needs possibly verification
        const userDocRef = fs.collection('/itu/testing-ahaa/users').doc(user.uid);
        userDocRef.get().then((userDoc)=>{
          if(userDoc.exists){
            setUserData(userDoc.data());
            console.debug("auth provider: user has email");
            setLoading(false); // allow router to show stuff...
          } else {
            console.debug("auth provider: user has to do email verification");
            navigate("/emailverification");
          }
        })
      });

    return unsubscribe

  }, [authReload])

  const forceAuthReload = (uid)=>{
    // user email has been verified but user data has not yet been copied to users collections
    const copyFrom = firebase.firestore().collection(DATATMP).doc(uid);
    const copyTo = firebase.firestore().collection(DATADB+'/users').doc(uid);
    copyFrom.get().then((value) => {
      copyTo.set(value.data()).then(()=>{
        setUserData(value.data())
        setLoading(false);
        copyFrom.delete();
      })
    });
    authReload = !authReload;
  }

  const value = {
    currentUser,
    login,
    anonymousLogin, 
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    userData,
    setUserData,
    forceAuthReload,
    loading
  }

  return (
    <AuthContext.Provider value={value} >
      {!loading && children}
    </AuthContext.Provider>
  )
}