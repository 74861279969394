import { v4 as uuidv4 } from 'uuid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import { Box, } from '@mui/system'
import React, { useState, Component, useContext, useEffect } from 'react'
import { Typography } from '@mui/material';
import {makeStyles} from '@mui/styles'

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { FricoContext } from '../../config/FricoProvider';

// https://github.com/felippenardi/lottie-react-web
import Lottie from 'lottie-react-web'
import animationData from "../../assets/lottie/hourglass.json"

// https://github.com/react-dnd/react-dnd
// https://medium.com/nmc-techblog/easy-drag-and-drop-in-react-22778b30ba37

import { DndProvider } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

const svgFilter = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"naturalShadow\" x=\"0\" y=\"0\" width=\"2\" height=\"2\"><feOffset in=\"SourceGraphic\" dx=\"6\" dy=\"6\" /><feGaussianBlur stdDeviation=\"5\" result=\"blur\" /><feMerge><feMergeNode in=\"blur\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></svg>#naturalShadow');";

// Smileys 
import smiley1 from "../../assets/smileys/smiley-01.png";
import smiley2 from "../../assets/smileys/smiley-02.png";
import smiley3 from "../../assets/smileys/smiley-03.png";
import smiley4 from "../../assets/smileys/smiley-04.png";
import smiley5 from "../../assets/smileys/smiley-05.png";
import smiley6 from "../../assets/smileys/smiley-06.png";
import smiley7 from "../../assets/smileys/smiley-07.png";
import smiley8 from "../../assets/smileys/smiley-08.png";

const PETS = [
  { id: 1, name: 'smiley1', url: smiley1 },
  //{ id: 2, name: 'smiley2', url: smiley2 },
  //{ id: 3, name: 'smiley3', url: smiley3 }, 
  //{ id: 4, name: 'smiley4', url: smiley4 },
  { id: 5, name: 'smiley5', url: smiley5 },
  { id: 6, name: 'smiley6', url: smiley6 },
  //{ id: 7, name: 'smiley7', url: smiley7 },
  //{ id: 8, name: 'smiley7', url: smiley8 },
];

const smileyInCSS = {
  background: "linear-gradient(to bottom, hsl(0, 0%, 91%) -10%,hsl(0, 0%, 91%) 33%,#59da5c 33%,#59da5c 110%)",
  backgroundColor : "rgb(148, 148, 148)",
  height: "300px",
  padding: "10px",
  borderRadius: "15px",
  border:"none",
  border:  "5px dotted rgb(9, 80, 24)",
  borderWidth:"5px",
  display: "flex",  
  alignContent: "start", 
  flexWrap: "wrap", 
  width:"230px", 
  margin:"auto",  
  marginTop: "10px", 
  height:"220px"
}

const smileyOutCSS = {
  backgroundColor: "rgb(250, 250, 250)",
  padding: "10px",
  borderRadius: "15px",
  borderStyle: "solid",
  borderColor: "rgb(58, 58, 58)",
  borderWidth: "3px",
  display: "flex",  
  flexWrap: "wrap", 
  width:"230px", 
  margin:"auto",
}

export default function RighPlayDrawer({toggleDrawer}) {
    const anchor = "right";
    const [lottie, setLottie] = useState(0);
    const [secs, setSecs] = React.useState(0);

    const handleSecs = (e, newSecs) => {
      e.stopPropagation();
      newSecs!=null && setSecs(newSecs);
      newSecs!=null && setLottie(newSecs);
    };
  
    const useStyles = makeStyles((theme) => ({
      selected: {
        "&&": {
          backgroundColor: "#3498db!important",
          color: "white!important"
        }
      }
    }));
    const classes = useStyles();

    return (<div>
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 240 }}
        style={{ pointerEvents:"all"}}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
            <Typography variant="h5" sx={{textAlign:"center"}}>Tiimalasi</Typography>
            <Box sx={{textAlign:"center", paddingTop:"10px"}}>
              <ToggleButtonGroup value={secs} color="success" exclusive onChange={handleSecs}  >
                <ToggleButton size="small" classes={{ selected: classes.selected }} style={{textTransform: 'none'}} value={0} ><RestartAltIcon/></ToggleButton>
                <ToggleButton size="small" classes={{ selected: classes.selected }} style={{textTransform: 'none'}} value={1} >30s</ToggleButton>
                <ToggleButton size="small" classes={{ selected: classes.selected }} style={{textTransform: 'none'}} value={2} >60s</ToggleButton>
                <ToggleButton size="small" classes={{ selected: classes.selected }} style={{textTransform: 'none'}} value={3} >90s</ToggleButton>
                <ToggleButton size="small" classes={{ selected: classes.selected }} style={{textTransform: 'none'}} value={4} >120s</ToggleButton>
              </ToggleButtonGroup>      
              { lottie == 0 && <Lottie0/>}
              { lottie == 1 && <Lottie1/>}
              { lottie == 2 && <Lottie2/>}
              { lottie == 3 && <Lottie3/>}
              { lottie == 4 && <Lottie4/>}       
            </Box>
        </List>
      </Box>
      <Divider sx={{marginBottom: "20px"}}/>
      <DndProvider backend={HTML5Backend}>
        <Box style={{pointerEvents:"auto"}}>
          <Basket toggleDrawer={toggleDrawer}/>
        </Box>          
      </DndProvider>

    </div>)
}

export class Lottie0 extends Component {render() { return ( <Lottie options={{ animationData: animationData, loop: false, autoplay: false,}} isPaused={true} speed={0} /> ) } }
export class Lottie1 extends Component {render() { return ( <Lottie options={{ animationData: animationData, loop: false, autoplay: true, }} speed={1} /> ) } }
export class Lottie2 extends Component {render() { return ( <Lottie options={{ animationData: animationData, loop: false, autoplay: true, }} speed={0.5} /> ) } }
export class Lottie3 extends Component {render() { return ( <Lottie options={{ animationData: animationData, loop: false, autoplay: true, }} speed={0.25} /> ) } }
export class Lottie4 extends Component {render() { return ( <Lottie options={{ animationData: animationData, loop: false, autoplay: true, }} speed={0.2} /> ) } }

export const PetCard = ({ id, name, url }) => {
  const [{ isDragging }, dragRef] = useDrag({
      type: 'pet',
      item: { id, name, url },
      collect: (monitor) => ({
          isDragging: monitor.isDragging()
      })
  })
  return (
      <div className='pet-card' ref={dragRef} style={{ margin: "5px", cursor:"grab" }}>
          <img src={url} style={{width:"50px",height:"50px", }}/>
          { /* isDragging && 'icon'          <img src={url} style={{width:"50px"}}/>        <p>{name}</p>             */ }
      </div>
  )
}

export const Basket = ({toggleDrawer}) => {
  const frico = useContext(FricoContext);       
  const [basket, setBasket] = useState([])
  const [{ isOver }, dropRef] = useDrop({
      accept: 'pet',
      drop: (item) => setBasket((basket) => {
                                              console.log("Dropping: ",item);
                                              frico.session && frico.session.gameState.smileys.push(item);
                                              frico.sessionSave();
                                              return (!basket.includes(item) ? [...basket, item] : basket);
                                            }),
      collect: (monitor) => ({
          isOver: monitor.isOver()
      })
  })

  useEffect(()=>{
    frico.session && setBasket(frico.session.gameState.smileys);
    frico.session && console.log("given smileys:",frico.session.gameState.smileys);
  },[frico.session, toggleDrawer]);

  return (
      <React.Fragment>
          <div className='pets' style={smileyOutCSS}>
              {PETS.map(pet => <PetCard draggable id={pet.id} key={uuidv4()} name={pet.name} url={pet.url}/>)}
          </div>
          <div className='basket' ref={dropRef} style={smileyInCSS}>
              {basket.map((pet) => {
                  console.log("rendering:",pet)
                  return <PetCard id={pet.id} key={uuidv4()} name={pet.name} url={pet.url} />
                })
              }
          </div>
          {isOver && <div style={{paddingTop: "0px", width:"100%" }}><p style={{textAlign: "center", }}>Raahaa hymiö tänne.</p></div>}
      </React.Fragment>
  )
}



