import { Divider, Grid, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { FImage } from '../../session/Tabs/BasicInfo';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { MyAvatar } from '../../dashboardLayout/AccountMenu';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

import firebase from "../../../config/firebase";

function BasicInfo({userDetails, control, errors, }) {
  
  return (
    <Grid container alignContent={'center'} spacing={2} >

    <Grid item xs={6} >
        <Controller
        name="email" 
        defaultValue=""
        control={control} 
        render={({ field })=> (
                <TextField
                disabled={true}
                required
                {...field}
                label="Sähköposti"
                variant="standard"
                margin="dense"
            />
            )}
        />
    </Grid>
    <Grid item xs={6} >
        <Controller
        name="mobile" 
        defaultValue=""
        control={control} 
        render={({ field })=> (
                <TextField
                required
                {...field}
                label="Puhelinnumero"
                variant="standard"
                margin="dense"
            />
            )}
        />
    </Grid>

    <Grid item xs={12} >
    <Stack direction="row" spacing={0}>        
        <Controller
            name="firstName" 
            defaultValue=""
            control={control} 
            render={({ field })=> (
                    <TextField
                    required
                    {...field}
                    label="Etunimi"
                    variant="standard"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors.name.message:""}
                    margin="dense"
                    fullWidth
                    sx={{paddingRight:"15px"}}
                />
                )}
            />
        <Controller 
          name="lastName" 
          defaultValue=""
          control={control} 
          render={({ field })=> (
                <TextField
                  required
                  {...field}
                  label="Sukunimi"
                  variant="standard"
                  error={!!errors[name]}
                  helperText={errors[name] ? errors.name.message:""}
                  margin="dense"
                  fullWidth
              />
            )}
          />
        </Stack>
    </Grid>

    <Grid item xs={4}  >

    </Grid>

    <Grid item xs={12}  >
        <Typography variant="h7" component="div">Avatar<br/><br/></Typography>
                <FImage 
                type="image"
                docType="selfie"
                name="userImage"
                label="Kuva"
                control={control}
                xyRatio="100%"
            />
    </Grid>

    <Grid item xs={12} >
        <Controller
        name="userTitle" 
        defaultValue=""
        control={control} 
        render={({ field })=> (
                <TextField
                {...field}
                label="Pelinohjaajan titteli(t)"
                variant="standard"
                margin="dense"
                fullWidth
            />
            )}
        />
    </Grid>

    <Grid item xs={12} >
        <Controller
        name="userWebsite" 
        defaultValue=""
        control={control} 
        render={({ field })=> (
                <TextField
                {...field}
                label="Pelinohjaajan verkko-osoite muodossa www.frico.app "
                variant="standard"
                margin="dense"
                fullWidth
            />
            )}
        />
    </Grid>

    <Grid item xs={12} >
        <Controller
        name="userDescription" 
        defaultValue=""
        control={control} 
        render={({ field })=> (
                <TextField
                {...field}
                label="Pelinohjaajan taustatiedot"
                variant="standard"
                margin="dense"
                multiline={true}
                minRows={2}
                maxRows={10}
                fullWidth
            />
            )}
        />
    </Grid>

    <Grid item xs={12} >
        <Typography component="div" variant="h5" sx={{marginTop:"20px"}}>
            Pelinohjaajan tiedot, jotka näkyvät esimerkiksi pelituokion ilmoittautumissivulla:
        </Typography>
        <Typography component="div" variant="normal"  sx={{paddingBottom:"40px"}}>
            (luodaan automaattisesti yllä annettujen tietojen pohjalta)
        </Typography>

        <UserMediaCard />
    </Grid>


  </Grid>             
  )
}

export default BasicInfo

export function UserMediaCard({uid=firebase.auth().currentUser.uid, showDescription=false}) {

  const theme = useTheme();
  const [user,setUser]=React.useState('');
  useEffect( async ()=>{
    const db=firebase.firestore();
    const docRef = doc(db, "/itu/testing-ahaa/users/", uid);
    getDoc(docRef).then((mdoc)=>{
        const user = mdoc.data();
        setUser(user);
    })
    return  onSnapshot(
                doc(db, "/itu/testing-ahaa/users/", uid),
                 (doc) => {
                    setUser(doc.data())
                    });
  },[]);

  if(!uid) return null;
    return (showDescription?<>
    {
        //
        // Show Long version
        //
    }
    <Paper sx={{ marginTop:"0px", marginBottom:"20px", maxWidth: "800px" }} >
        <Grid container >
            <Grid item sm={12} md={12} lg={3}>
                <CardMedia sx={{padding:"10px"}}>
                    <MyAvatar uid={uid} height="100px" width="100px"/>
                </CardMedia>
            </Grid>
            <Grid item sm={12} md={12} lg={4}>
                <Box sx={{ marginRight:"30px", textAlign: "left"}}>
                    <Typography component="div" variant="normal" sx={{fontWeight:"bold"}} >
                        {user.firstName} {user.lastName} 
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div" sx={{fontWeight:"bold"}} >
                        {user.userTitle}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        {user.email}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        {user.mobile}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        <Link href={"https://"+user.userWebsite}>{user.userWebsite}</Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={12} md={12} lg={5}>
                <Box sx={{ textAlign: "left"}}>
                    <Typography variant="normal" color="text.secondary" component="div">
                        {user.userDescription}
                    </Typography>
                </Box>
            </Grid>        
        </Grid>
    </Paper>
    </>:<>
    {
        //
        // Show short version
        //
    }
    <Paper sx={{ marginTop:"20px", marginBottom:"20px", maxWidth: "800px", paddingBottom:"15px" }} elevation={3}>
        <Grid container spacing={3}>
            <Grid item xs={6}  align="right">
                <CardMedia sx={{padding:"10px"}}>
                    <MyAvatar uid={uid} height="100px" width="100px"/>
                </CardMedia>
            </Grid>
            <Grid item xs={6} >
                <Box sx={{ marginRight:"30px", textAlign: "left"}}>
                    <Typography component="div" variant="normal" sx={{fontWeight:"bold"}} >
                        {user.firstName} {user.lastName} 
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div" sx={{fontWeight:"bold"}} >
                        {user.userTitle}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        {user.email}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        {user.mobile}
                    </Typography>
                    <Typography variant="normal" color="text.secondary" component="div">
                        <Link href={"https://"+user.userWebsite}>{user.userWebsite}</Link>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    </Paper>
    </>);
}