import { useEffect, useState, useRef } from 'react'
import firebase from '../../config/firebase'; 
import { useParams } from 'react-router';
import { Alert, AlertTitle, Container, Grid, Paper, Rating, Slider, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../../config/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, getDoc, deleteDoc, updateDoc } from "firebase/firestore"; 
import Button from "@mui/material//Button";
import Box from "@mui/material/Box";
import moment from "moment";
// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import {useForm, Controller} from "react-hook-form";
import * as yup from 'yup';
import sendBasicEmail from '../../config/sendBasicEmail';
import { UserMediaCard } from '../user/Tabs/BasicInfo';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { userProfileErrorState } from '../../config/userProfile';
import { WindowSharp } from '@mui/icons-material';

// The way to delete anonymous users
// https://stackoverflow.com/questions/39640574/how-to-bulk-delete-firebase-anonymous-users

// Local storage for anonymous users
// https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/

const feedbackDefaults ={
    feedbackGamemaster:0,
    feedbackContent:0,
    feedbackOverall:0,
    feedbackOpen:'',
}

const  schema = yup.object().shape({
    feedbackGamemaster: yup.number().min(1).max(5).required(),
    feedbackContent:    yup.number().min(1).max(5).required(),
    feedbackOverall:    yup.number().min(1).max(5).required(),
    feedbackOpen:       yup.string(),
  });


export default function Feedback() {

    // ================================================
    // this can be viewed as an anonymous user
    // we just need to login anonymously
    // ================================================
    const [session, setSession] = useState(null);
    const {currentUser, anonymousLogin} = useAuth();
    const {enrollid} = useParams();

    const [showMessageOK, setShowMessageOK] = useState(false);    
    const [showMessageNOK, setShowMessageNOK] = useState(false);    
    const [openSnack, setOpenSnack] = useState(false);    

    const [noEnrollDoc, setNoEnrollDoc] = useState(false);
    
    const db=firebase.firestore();

  // ================================================
  // Form stuff
  // https://react-hook-form.com/get-started
  // 
  // https://github.com/react-hook-form/react-hook-form
  // https://github.com/bluebill1049/little-state-machine
  // https://www.youtube.com/watch?v=CeAkxVwsyMU
  // 
  // ================================================
  const sessionRef = useRef(null);

  const onUpdate = (data) => {
    updateDoc(sessionRef.current,{feedback} );
    console.log("Updating ....");
  }
  const onSubmit = (data) => {
      console.log("onsubmit:", data);
      setOpenSnack(true);
      setTimeout(()=>{
          reset(feedbackDefaults);
          //window.location.href="https://frico.app";
        },5000)
  
        console.log("==============> ref: "+'/itu/testing-ahaa/userdata/'+session.uid+'/sessions/'+session.id) ;
        const sessionRef = doc(db, '/itu/testing-ahaa/userdata/'+session.uid+'/sessions/'+session.id);
        console.log("data", data)
        setDoc(sessionRef, { feedbacks: {[uuidv4()]:{timestamp:Date.now(), fbdata: data}} }, { merge: true });
                
    };

  const onError = (errors) => {
    console.log("ON ERROR: >>>>>>>>>>>>>>>>>>>> ", errors)
    setShowMessageNOK(true);
    setShowMessageOK(false);
    setShowMessageInUse(false);  
  };

  const {
      register,
      reset,
      control,
      handleSubmit,
      watch,
      trigger,
      setValue,
      formState: { isValid,  },
    } = useForm({ 
                    mode: "onChange",
                    resolver: yupResolver(schema),
                    shouldFocusError: true,
                    criteriaMode: "all",
                    reValidateMode: "onSubmit"
                  });
  

    // update player info
    useEffect( async ()=>{
        if(!currentUser) {
            anonymousLogin();
            return
        };

        const enrollRef = doc(db, '/itu/testing-ahaa/settings/shortLinks/enroll/'+ enrollid);
        const endrollSnap = await getDoc(enrollRef);

        // NO ENROLL RECORD
        if(!endrollSnap.exists()) {
          setNoEnrollDoc(true);
          console.error("NO ENROLL DOCUMENT");
          return;
        }
        const tmpEnroll = endrollSnap.data();
        sessionRef.current = doc(db, '/itu/testing-ahaa/userdata/'+tmpEnroll.userId+'/sessions/'+tmpEnroll.sessionId);
        console.log("sessionRef-01",sessionRef.current);

        // NO SESSION
        const sessionSnap = await getDoc(sessionRef.current);
        if(!sessionSnap.exists()) {
          setNoEnrollDoc(true);
          console.error("NO SESSION DOCUMENT");
          return;
        }

        const tmpSession = sessionSnap.data();
        setSession(tmpSession);
        console.log(tmpSession);

        reset(feedbackDefaults)

    },[]);
    
    
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    if(noEnrollDoc) return (
      <Container  >
        <Grid container justifyContent="center" alignContent={'center'} spacing={0} sx={{padding:"30px"}} >
          <Alert severity="error">Tietoja ei löydy1.</Alert>
        </Grid>
      </Container>
      );
    if(!session) return null;
    return (  (
      <form >
      <Box sx={{ maxWidth: "600px", textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
        <Grid container alignContent={'center'} spacing={0} sx={{padding:"30px"}} >
          <Grid 
            item
            sm={12}
            xl={12}  
            >
              <img src={session.seoImage} style={{ width: "100%", height: "auto",  }} />
          </Grid>

          <Grid item xs={12} md={12} >
            <Typography variant="h5" sx={{textAlign: "center"}}>
              FriCo-vuorovaikutuspelin palautelomake
            </Typography>              
            <Typography sx={{textAlign: "center", fontWeight:"100%", paddingTop:"0px"}} component="div" variant="h6" >
              Tilaisuuden ajankohta: {moment(session.startsAt).format("DD.MM.YYYY [klo] HH:mm")}
            </Typography>                            
            <Typography sx={{textAlign: "center", paddingTop:"0px",paddingBottom:"15px"}} variant="h6">
               Pelinohjaajana toimi
            </Typography>              
            <UserMediaCard uid={session.uid}/>
          </Grid>
          {
            // =====================================================================
            // FEEDBACK BLOCK
            // https://stackoverflow.com/questions/63205951/how-is-material-uis-react-rating-component-used-in-a-form
            // =====================================================================
          }
          <Grid item xs={12}>
            <Typography sx={{textAlign: "center", paddingTop:"5px",paddingBottom:"0px", width:"100%"}} variant="h5">
                Ole hyvä, anna palautetta tilaisuudesta
            </Typography>              
            <Typography sx={{textAlign: "left", paddingTop:"5px",}} variant="normal">
                palautteesi on nimetön, tähtiarviot ovat pakollisia
            </Typography>              
          </Grid>
          <Grid item xs={12} md={12} >
              <Controller 
                name="feedbackGamemaster" 
                control={control} 
                defaultValue={0}
                render={({ field })=> (
                        <Stack direction="row" sx={{paddingTop:"25px"}} >
                            <Typography variant="h6" sx={{ width:"50%", textAlign:"right", paddingRight:"20px"}}><b>Pelinohjaaja</b></Typography>
                            <Rating 
                                value={field.value} 
                                precision={1}
                                size="large"
                                onChange={(_, value) => {
                                    field.onChange(value)
                                    trigger();

                                }}
                            /> 
                        </Stack>
                    )}

                />
              <Controller 
                name="feedbackContent" 
                control={control} 
                defaultValue={0}
                render={({ field })=> (
                        <Stack direction="row" sx={{paddingTop:"25px"}} >
                            <Typography variant="h6" sx={{ width:"50%", textAlign:"right", paddingRight:"20px"}}><b>Oma aktiivisuuteni</b></Typography>
                            <Rating 
                                value={field.value} 
                                precision={1}
                                size="large"
                                onChange={(_, value) => {
                                    field.onChange(value)
                                    trigger();
                                }}
                            /> 
                        </Stack>
                    )}
                />
              <Controller 
                name="feedbackOverall" 
                control={control} 
                defaultValue={0}
                render={({ field })=> {
                        console.log("field",field);
                        return (
                        <Stack direction="row" sx={{paddingTop:"25px"}} >
                            <Typography variant="h6" sx={{ width:"50%", textAlign:"right", paddingRight:"20px"}}><b>Kokonaisuus</b></Typography>
                            <Rating 
                                value={field.value} 
                                precision={1}
                                size="large"
                                onChange={(_, value) => {
                                    field.onChange(value)
                                    trigger();
                                }}
                            /> 
                        </Stack>
                        )
                    }}
                />


          </Grid>

          <Grid item xs={12} md={12} >
              <Controller 
                name="feedbackOpen" 
                defaultValue=""
                control={control} 
                render={({ field })=> (
                    <Stack sx={{paddingTop:"40px"}}>
                      <Typography variant="h6" sx={{ width:"100%", textAlign:"center", paddingRight:"20px"}}><b>Mikä sujui hyvin, mihin toivoisit muutosta?</b></Typography>
                      <TextField
                        {...field}
                        multiline={true}
                        minRows={3}
                        label="Anna avointa palautetta"
                        variant="standard"
                        margin="dense"
                        fullWidth
                      />
                    </Stack>
                    )}
                />
          </Grid>

          <Grid item xs={12} md={12} sx={{paddingTop: "30px",paddingBottom: "20px",  }}>
                  <Button
                      disabled={!isValid}
                      type="submit" 
                      color="primary" 
                      variant="contained"
                      size="large"
                      onClick={handleSubmit(onSubmit, onError)}
                  >
                    Lähetä palautteesi
                    <ForwardToInboxIcon sx={{marginLeft:"15px"}}/>
                  </Button>
                  <Snackbar
                        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                        autoHideDuration={8000}
                        onClose={() => setOpenSnack(false)}
                        open={openSnack}
                    >
                        <Alert onClose={() => setOpenSnack(false)} severity="success" variant="filled" sx={{ width: '100%' }}>Kiitos, palautteesi on tallennettu</Alert>
                    </Snackbar>

        </Grid>

          <Grid item xs={12} md={12} >

              <MyAlertOK 
                  title="Vahvistusviesti on lähetetty antamaasi sähköpostiosoitteeseen." 
                  text="Ole hyvä ja klikkaa viestin sisältämää linkkiä. Näin tulet vahvistaneeksi sähköpostiosoitteesi oikeellisuuden ja tiedämme, että kaikki on osaltasi kunnossa. Ellei viestiä näy. tarkista myös roskapostikansio." 
                  open={showMessageOK} 
                  setOpen={setShowMessageOK}
                  />
              <MyAlertNOK 
                  title="Palautetta ei voida vielä lähettää." 
                  text="Kaikki tähtikentät ovat pakollisa." 
                  open={showMessageNOK} 
                  setOpen={setShowMessageNOK}
                  />

          </Grid>
    
        </Grid>             
      </Box>
      </form>
    ))
    
}

const MyAlertOK= ({text, title, open, setOpen})=>{
    if(!open){return null}
    return (
        <Alert 
            sx ={{marginTop: "20px", marginBottom: "20px"}}
            severity="success"
            onClose={() => setOpen(false)}
            >
                <AlertTitle>{title}</AlertTitle>    
                <p>{text}</p>
        </Alert>
    )
}


const MyAlertNOK= ({text, title, open, setOpen})=>{
  if(!open){return null}
  return (
      <Alert 
          sx ={{marginTop: "20px", marginBottom: "20px"}}
          severity="error"
          onClose={() => setOpen(false)}
          >
              <AlertTitle>{title}</AlertTitle>    
              {text}
      </Alert>
  )
}


