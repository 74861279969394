import React, { useContext, useEffect, useState} from 'react'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system';
import { FricoContext } from '../../config/FricoProvider';

import { RDB } from '../../config/datapaths'
import { ref, remove } from "firebase/database";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import defaultStartImage from "./../../assets/bg-images/castle-1920.jpg";

// https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
export default function GameStart() {

    const frico = useContext(FricoContext);       
    const [open, setOpen] = useState(false);

    const useStyles = makeStyles( (theme) => {
        return {
            gameRoot: {
                spacing: 0,
                justify: 'space-around',
                overflow: "hidden"
            },
        }
    })
    const classes = useStyles()
    
    useEffect(()=>{
        if(frico.session && frico.session.gameState.started) {
            setOpen(true);
          } 
    },[frico.session]);

    const handleCloseContinue = () => {
        setOpen(false);
        frico.session.gameState.started = false;
        frico.sessionSave();
    }

    const handleCloseReset = () => {
        setOpen(false);
        frico.session.gameState.started = false;

        // clear stuff
        frico.session.gameState.smileys=[];
        frico.session.gameState.userComments1=[];
        frico.session.gameState.userComments2=[];

        frico.session.gameState.cardsLobby[1].done=false;
        frico.session.gameState.cardsLobby[2].done=false;
        frico.session.gameState.cardsLobby[3].done=false;
        frico.session.gameState.cardsLobby[4].done=false;
        frico.session.gameState.cardsLobby[5].done=false;
        frico.session.gameState.cardsLobby[6].done=false;
        frico.session.gameState.cardsLobby[7].done=false;
        frico.session.gameState.cardsLobby[8].done=false;

        //console.log('frico.session.gameState["cardsLibrary"]',frico.session.gameState["cardsLibrary"]);
        
        if(frico.session.gameState["cardsLibrary"] !== undefined){
            frico.session.gameState.cardsLibrary.books[0].done=false;
            frico.session.gameState.cardsLibrary.books[1].done=false;
            frico.session.gameState.cardsLibrary.books[2].done=false;
            frico.session.gameState.cardsLibrary.books[3].done=false;
            frico.session.gameState.cardsLibrary.books[4].done=false;
            frico.session.gameState.cardsLibrary.books[5].done=false;
            frico.session.gameState.cardsLibrary.books[6].done=false;
            frico.session.gameState.cardsLibrary.books[7].done=false;
            frico.session.gameState.cardsLibrary.books[8].done=false;
            frico.session.gameState.cardsLibrary.openedBooks={ "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, }
        }
        
        if(frico.session.gameState["rocks"] !== undefined){
            frico.session.gameState.rocks.openedRocks=[false, false, false, false, false, false, false, false, false, ];
        }
        
        frico.session.gameState.smileys=[];

        //frico.session.gameState.slider.questionText = "Mitä mieltä olet väittämästä:";
        frico.session.gameState.slider.minText = "täysin eri mieltä";
        frico.session.gameState.slider.maxText = "täysin samaa mieltä";
        frico.session.gameState.slider.players = [];
        frico.session.gameState.slider.average = 0;
        frico.session.gameState.slider.avatarScale = 40;

        frico.sessionSave();

        const sessionUsersRef = ref(RDB, 'tmp/sessions/'+frico.sessionId+'/users');
        remove(sessionUsersRef);          

        }

    if(!frico.session) return null;

    // onClick={()=>{console.log("Success: click through!")}}
    return (<>       
             <img 
                style={{
                    position:"absolute", 
                    width: "100vw",  
                    height: "100vh", 
                    objectFit: "cover",
                }}
                src={defaultStartImage}
                />
            <Box className={classes.gameRoot} style={{width:"100vw",height:"100vh", overflow:"hidden"}}   >
                {frico.session.gameState.started && <Dialog
                    open={open}
                    onClose={handleCloseContinue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle sx={{textAlign:"center"}} id="alert-dialog-title">
                        Tämä peli on jo käynnistetty.<br/> Mitä haluat nyt tehdä?
                    </DialogTitle>
                    <DialogActions sx={{padding:"40px"}}>
                    <Stack spacing={2}>
                        <Button variant="contained" onClick={handleCloseContinue} >Jatka jo aloitettua peliä</Button>
                        <Button variant="contained" onClick={handleCloseReset} >Aloita uusi peli</Button>
                    </Stack>
                    </DialogActions>
                </Dialog>}
            </Box>
        </>)
}
