import React from 'react'
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';

const SignedOutLinks = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item>
        <Button variant="contained" color="primary" size="medium" onClick={()=>navigate('/signup')} >Luo tili</Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" size="medium" onClick={()=>navigate('/signin')}>Kirjaudu sisään</Button>
      </Grid>
    </Grid>
  )
}
export default SignedOutLinks