import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import firebase, { serverTimestamp } from './firebase';
import { DATADB, DATATMP, DOCPATH } from './datapaths';
import newDoc from './newDoc';



export const authInfoState = atom({
  key: uuidv4(), // unique ID (with respect to other atoms/selectors)
  default: { ready: false,  uid: '', guid: '', email: '', emailVerified: '', claims: '' } // default value (aka initial value), details will be appended after authentication
});

// contains user error state, if any
export const userProfileErrorState = atom({
  key: uuidv4(), // unique ID (with respect to other atoms/selectors)
  default: '' // default value (aka initial value)
});

export const currentProjectState = atom({
  key: uuidv4(), // unique ID (with respect to other atoms/selectors)
  default: '' // default value (aka initial value)
});


// ================================================================
//  Sign up flow:
//  1) user leaves email, pwd, real name in Signup.js
//  2) verification email sent 
//  3) details stored in public writable firestore collection /tmp
//  4) navigate to EmailVerifivation.js component
//  5) watch for firebase.auth().currentUser.emailVerified by reloading user every second
//  6) save userProfile permanently in DATAROOT/user collection, remove temporary value
// ================================================================

export const saveUserProfileStateTemp= (user,loginState) => {
  return newDoc(DATATMP,{
    id: user.uid,
    data: {
      uid:          user.uid,
      email:        user.email,
      guid:         '',
      role:         'user',
      firstName:    loginState.firstName,
      lastName:     loginState.lastName,
      initials:     loginState.firstName[0] + loginState.lastName[0],
      emailVerified: user.emailVerified || false,                  
      createdAt:     serverTimestamp(),
    }
  });
}

// returns the user data for to be stored in any data record
export const getUserFileDetails =  async () => {
  const user = await firebase.firestore().collection(DOCPATH('users') ).doc(firebase.auth().currentUser.uid).get();
  const { uid, email, guid, role, firstName, lastName, initials} = user.data();
  return { uid, email, guid, role, firstName, lastName, initials };
}

// uiSetup("verification-pending")
// returns true when
//     1) tag is the name of an allowed UI component in current login-state
//     2) tag is the current login-state
//     3) empty tag means login state change

export const uiSetup = (tag) => {

  // "component allowed in state", array can have multiple states where component is visible
  // anonymous, loggedin, verification-pending, admin
  const uiAllowWhenState = {
    Application:        ['uistate-loggedin', 'uistate-admin', "uistate-ahaauser"],
    SignedOutLinks:     ['uistate-anonymous'],
    SignIn:             ['uistate-anonymous'],
    SignUp:             ['uistate-anonymous'],
    EmailVerification:  ['uistate-verification-pending'],
    SignedInLinks:      ['uistate-loggedin', 'uistate-admin', "uistate-ahaauser"],
    LeftDrawer:         ['uistate-loggedin', 'uistate-admin' , "uistate-ahaauser"],
    AdminLinks:         ['uistate-admin'],
    GroupSelector:      ['uistate-admin', "uistate-ahaauser"],
    AhaaUser:           ['uistate-ahaauser'],
  }

  const user=firebase.auth().currentUser;
  // ================================================================================
  // HANDLE UISTATE RESET
  // ready: true / false is not in firestore, it is added to the state 
  // when firebase userdata is available!
  // 'uistate-reset' signals change in state
  // ================================================================================
  // ================================================================================
    

  // ================================================================================
  // COMPUTE USER STATUS
  // anonymous -> verification-pending -> loggedin -> admin
  // ================================================================================
  var uiState = 'uistate-anonymous';
  
  if(user) {
    uiState = 'uistate-verification-pending';
  } else {
    uiState = 'uistate-anonymous';
  }
  if(user && user.emailVerified) {
    uiState = 'uistate-loggedin';
  }
  if(user && user.admin) {
    uiState = 'uistate-admin';
  } 
  if(user && (user.email.includes("@ahaavision.com") || user.email.includes("sulismies@gmail.com") ) ) {
    uiState = 'uistate-ahaauser';
  } 

  // ================================================================================

  // ================================================================================
  // COMPUTE IF A COMPONENT CAN BE SHOWN
  // ================================================================================

  const keys = Object.keys(uiAllowWhenState);
  const isComponent = keys.includes(tag);
  if(!isComponent) {
    console.error(" >>>> Unknown component tag in uiSetup: " + tag);
    return false;
  }
  return uiAllowWhenState[tag].includes(uiState);

}

  // https://firebase.google.com/docs/auth/admin/custom-claims
  // let isA = false;
  // firebase.auth().currentUser.getIdTokenResult()
  // .then((idTokenResult) => {
  // Confirm the user is an Admin.
  //   if (!!idTokenResult.claims.admin) {
  //     isA = true
  //   }
  // })
  // .catch((error) => {
  // console.log(error);
  //   });
