import { Typography } from '@mui/material'
import React from 'react'

export default function Help() {
  return (
    
    <Typography sx={{width:"500px", paddingTop:"100px", margin:"auto"}}>
        Jos FriCo-alustan kanssa on teknisiä pulmia, 
        soita <br/> 040-4140618/Markku tai lähetä viesti markku.lahtinen@ahaavision.com.
        <br/><br/>
        Pyrin vastaamaan sinulle mahdollisimman pian.<br/>
        -Markku <br/>
    </Typography>
  
  )
  
}
